const trans_tw = {
    title: 'ESCSGO 交易幫助',
    content: {
        title1: '1. 什麼是Steam交易限制：',
        span1: '為了保護玩家的帳戶安全， Steam官方會因為用戶的一些操作而限制其帳戶進行交易平臺遵循Steam官方市場交易準則交易。交易限制期間的用戶將無法取回物品',
        title2: '2. 如何查看我的帳號交易限制詳情：',
        span2_1: '點擊此網站：',
        span2_2: '（若您無法打開此網頁，請使用UU加速器加速“Steam社區/商店”）並登陸您的Steam帳號即可查看帳號目前的交易限制詳情',
        title3: '3. 如何解除帳號交易限制：',
        span3: 'Steam交易限制是無法解除的，只能等待限制時間結束，Steam客服不會修改交易或 Steam 社區市場中的限制，無論您的帳戶出於何種原因受限我們同樣無權解除用戶帳戶的限制，有交易限制的用戶等限制解除之後即可交易',
        title4: '4. 什麼情況下會產生Steam交易限制：',
        title4_1: '4.1 Steam 令牌未啟用',
        span4_1: 'Steam手機令牌超過15天，如果您啟用Steam令牌不到15 天，您將無法交易，您可以 通過手機直接下載Steam Mobile, 開通Steam令牌，等待15天的冷卻時間便可解除限制。 綁定Steam令牌教程',
        title4_2: '4.2 最近修改過賬號密碼',
        span4_2: '如果您的帳戶近期更改過Steam登錄密碼，同樣也會產生7天的交易限制，參與活動時請謹慎使用',
        title4_3: '4.3 重置Steam令牌',
        span4_3: '如果您最近移除或重置過Steam手機令牌，也會產生7天交易限制時間。更換手機重新安裝手機令牌或者卸載重裝均會觸發此限制，重置令牌時請謹慎',
        title4_4: '4.4 Steam消費限制',
        span4_4_1: '若您的帳號兩個月內在Steam未產生任何消費，這將會使您的帳戶進入交易限制，此時需要在Steam商店購買任意一款任意金額的遊戲然後等待七天的交易冷卻期即可；',
        span4_4_2: '若您的帳號是新註冊的Steam帳號，則需要在Steam商店購買一款不低於5美元的遊戲，然後等待15天的交易限制時間即可',
        span4_5: '請注意，Steam為保障用戶的交易安全會設置諸多繁瑣的限制來保證您的帳戶處於安全的交易環境，參與活動前我們建議您將帳號中的所有交易限制完全解除後再參與活動，以免造成不必要的損失，感謝您的理解和配合，祝您遊戲愉快',
        title5: '5.Steam帳號庫存隱私設置：',
        span5_1: 'Steam交易過程中需要將庫存隱私公開，這樣方便我們的派獎機器人能夠及時準確的將您獲得的獎品發放到您的帳戶，設置方法可參照下圖所示：',
        title6: '6. Steam無法訪問解決方案：',
        span6: '我們推薦您使用網易UU加速器加速“Steam社區/商店”來登錄Steam，手機端可免費 下載網易UU加速器移動版加速Steam社區/商店，因部分流覽器尚不支持Steam的一些相關頁面，我們推薦您使用穀歌流覽器訪問Steam，感謝您的理解與支持'
    }
}
export default trans_tw
    