
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath, sortOnPrice } from "@/assets/js/filter";
export default {
  name: "Bag",
  components: {},
  data() {
    return {
      fromatCDNPath,
      sortOnPrice,
      formatDate,
      couponList: [],
      pageCoupon: {
        page_no: 1,
        page_size: 12,
        totalPage: 1,
      },
      loading: false,
      activeIndex: 1,
    };
  },
  computed: {
    ...mapGetters(["getIsIdCard", "getIsLogin"]),
  },
  mounted() {
    this.getUserCoupon();
  },
  beforeDestroy() {},
  methods: {
    // 获取我的卡券
    getUserCoupon() {
      if (!this.getIsLogin) {
        this.$store.commit("SET_VISIBLE_LOGIN", true);
        return;
      }
      this.loading = true;
      let _sendData = {
        page_no: this.pageCoupon.page_no,
        page_size: this.pageCoupon.page_size,
      };
      this.$getCardCoupon(_sendData)
        .then((res) => {
          if (res.code == 200 && res.data.infos) {
            let totalPage = parseInt(
              res.data.page_result.total_count / this.pageCoupon.page_size
            );
            if (
              res.data.page_result.total_count % this.pageCoupon.page_size >
              0
            ) {
              totalPage = totalPage + 1;
            }
            if (!totalPage) {
              totalPage = 1;
            }
            this.pageCoupon.totalPage = totalPage;
            this.couponList = [...this.couponList, ...(res.data.infos || [])];
            this.coupon_total_count = res.data.page_result.total_count;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeTab(val) {
      this.pageCoupon.page_no = 1;
      this.couponList = [];
      this.activeIndex = val;
    },
    handleScroll() {
      if (this.pageCoupon.page_no >= this.pageCoupon.totalPage) return;
      const scrollContainer = this.$refs.listBox;
      //确保只有在有数据时滚动才会触发
      if (
        scrollContainer.scrollHeight - scrollContainer.scrollTop ===
          scrollContainer.clientHeight &&
        this.couponList.length
      ) {
        // 触发翻页函数
        this.pageCoupon.page_no++;
        this.getUserCoupon();
      }
    },
    // 立即使用卡券
    immediateUse(item) {
      if (!this.getIsIdCard) {
        this.$store.commit("SET_VISIBLE_ID_CARD", true);
      } else {
        // this.$router.push({
        //   path: "/goodRecharge",
        // });
        this.$store.commit("SET_IS_SHOW_RECHARGE_DIALOG", true);
      }
    },
    getTypeText(item) {
      //award_type 1 + 加赠 2 是满减
      //award_mode 1 + 比例 2 是固定值
      if (item.info_value.award_type == 1) {
        // + 赠
        if (item.award_mode == 1) {
          return "加赠" + (item.info_value?.award_value ?? 0) / 10000 + "%";
        } else {
          return "加赠" + (item.info_value?.award_value ?? 0) / 10000;
        }
      } else if (item.info_value.award_type == 2) {
        if (item.info_value.award_mode == 1) {
          return "满减" + (item.info_value?.award_value ?? 0) / 10000 + "%";
        } else {
          return "满减" + (item.info_value?.award_value ?? 0) / 10000;
        }
      }
    },
  },
};
