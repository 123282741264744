import Vue from "vue";
import {
  Checkbox,
  Progress,
  Carousel,
  CarouselItem,
  Input,
  InputNumber,
  DatePicker,
  Upload,
  Radio,
  RadioGroup,
  Select,
  Option,
  Loading,
  Button,
  Slider,
  Tooltip,
  Popover,
  Switch,
  Divider,
  Table,
  TableColumn,
  Image,
  Drawer,
  Tabs,
  TabPane,
  Dialog,
  Form,
  FormItem
} from "element-ui";

Vue.use(FormItem);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Checkbox);
Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Button);
Vue.use(Slider);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Switch);
Vue.use(Divider);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Image);
Vue.use(Drawer);