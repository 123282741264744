
import { fromatCDNPath } from "@/assets/js/filter";
export default {
  props: ["item"],
  data() {
    return {
      fromatCDNPath,
    };
  },
  mounted() {},
  methods: {},
};
