import trans from "./help/trans_cn.js";
import problem from "./help/problem_cn.js";
import conditions from "./help/use_cn.js";
import privacy from "./help/privacy_cn.js";
import pc from "./help/PC_cn.js";
import mobile from "./help/Mobile_cn.js";
import novice from "./help/Novice_cn.js";
import steam from "./help/Steam_cn.js";
const cn = {
  vip: {
    title: "VIP等级系统",
    get: "领 取",
    haveGet: "已领取",
    notGet: "不可领取",
    rechargeRewardActiveTxt: "充值福利活动",
    dayTxt: "日",
    weekTxt: "周",
    monthTxt: "月",
    bookTxt: "本",
    rechargeReachedTxt: "充值达到",
    activeRuleTxt: "活动规则",
    activeRule1Txt: "1.满足条件后输入口令即可领取红包",
    activeRule2Txt: "2.更多红包口令将在官方QQ群",
    activeRule3Txt: "内发送",
  },
  header: {
    home: "首页",
    integral: "直播开箱",
    card: "抢卡炼金",
    battle: "盒子大战",
    equip: "装备升级",
    help: "帮助",
    welfare: "ROLL福利",
    rank: "欧皇榜",
    login: "注册/登录",
    logout: "退出登录",
    recharge: "充值",
    inventory: "库存",
    vip: "VIP系统",
    userInfoList: {
      basic: "基本资料",
      inventory: "饰品库存",
      record: "开箱记录",
      promote: "推广用户"
    }
  },
  home: {
    time: "时间",
    getItems: "获得奖品 ",
    cost: "价值",
    player: "获奖用户",
    list: "最近获奖名单",
    day: "日",
    week: "周",
    month: "月",
    hour: "时",
    remaining: "距离开奖剩余时间：",
    txt1: {
      th1: "每",
      th2: "充值满",
      th3: "固定金额",
      th4: "后，将会自动报名参与"
    },
    txt2: {
      th1: "每",
      th2: "固定时间",
      th3: "会抽取一位幸运玩家，获得当前抽奖的饰品"
    },
    txt3: {
      th1: "中奖的饰品，会直接发到",
      th2: "我的库存",
      th3: "里，请注意查收"
    }
  },
  banner: {
    day: "天",
    hour: "时",
    minute: "分",
    second: "秒",
    buttonText: "限时积分放弃",
    prompt: "充值送积分，免费放弃人气饰品，数量有限先兑先得",
    registerNum: "已有注册用户",
    boxOpenNum: "宝箱开启总数",
    battleNum: "盒子大战次数",
    upNum: "装备升级总数"
  },
  index: {
    jptj: "精品推荐",
    activity_prompt: "距离开奖剩余时间：",
    consume: "充值",
    join: "参加",
    activity_1: "每日定时抽奖活动",
    activity_2: "每周定时抽奖活动",
    activity_3: "每月定时抽奖活动",
    activity_4: "每时定时抽奖活动",
    tab_1: "热门人气汇聚",
    tab_2: "稀有限定装备",
    tab_3: "战地近战幽灵",
    tab_4: "精英冲锋战队",
    tab_5: "中坚传奇对抗",
    tab_6: "神秘关键箱子",
    tab_7: "神秘关键箱子",
    open_box: "打开箱子",
    sold: "已售出："
  },
  langue: {
    ch: "中文",
    en: "English"
  },
  gold: "金币",
  openbtn: "打开",
  hoverright: {
    service: "在线客服",
    totop: "回到顶部"
  },
  logins: {
    login: "登录",
    register: "注册",
    updatepwd: "修改密码",
    emailholder: "请输入注册邮箱",
    passwordholder: "请输入6~12位密码",
    btn: "确定",
    freeRegister: "免费注册",
    forgetPwd: "忘记密码",
    read: "我已阅读并同意",
    read1: "《用户协议》",
    read2: "和",
    read3: "《隐私条款》",
    steamlogin: "Steam 登录",
    res_emai_holder: "请输入您的邮箱账号",
    res_code: "获取验证码",
    res_code_holder: "请输入6位数验证码",
    res_code_des: "收不到邮件？可能被转移至垃圾箱，请前往查看",
    res_pwd_holder: "请设置您的密码",
    res_pwd_des: "密码长度为6~12位",
    al_user: "已有账号，",
    gologin: "去登录",
    newpwd: "新密码",
    newpwd_des: "密码长度为6~12位",
    confirm: "再次确认密码",
    tips: {
      t1: "请输入正确的邮箱地址！",
      t2: "密码长度为6~12位！",
      t3: "请勾选我已阅读并同意！",
      t4: "登录成功！",
      t5: "邮件已发送至您的邮箱！",
      t6: "请输入正确的验证码！",
      t8: "您已经成功注册！",
      t9: "两次密码输入不一致！",
      t10: "您的密码修改成功,请登录！"
    }
  },
  energy: {
    lefttitle: "能量宝箱",
    righttitle: "首充福利",
    righttitle1: "新人福利",
    leftbanner: "每次消费都将积累能量",
    ringtext: "我的能量",
    leftstitle: "您的福利：",
    rightbanner: "充值任意金额可免费开启",
    openbtn: "开启宝箱",
    openbtn1: "已开启",
    open_flag: "可开启",
    open_desc: "当您的能量超过",
    open_desc1: "时，将自动激活",
    right_open_btn: "免费开箱",
    right_open_btn1: "已开箱",
    tips: {
      t1: "您的能量还未达到开启这个能量箱子！"
    }
  },
  boxDetail: {
    back: "返回",
    showvoice: "开启声音",
    closevoice: "关闭声音",
    showanimate: "开启动效",
    closeanimate: "关闭动效",
    tipss: "按空格可跳过动效",
    openonce: "开启一次",
    openfive: "开启五次",
    openten: "开启十次",
    detail_title: "共有",
    detail_titles: "",
    detail_titles1: "",
    detail_title1: "件物品在这个箱子中",
    result_banner1: "如需取回到您的Steam账号中，请前往 ",
    result_banner2: "我的背包",
    result_banner3: "中奖结果",
    result_banner3_1: "开箱中",
    result_banner4: " · 开箱",
    result_banner5: "次",
    result_banner6: "共获得",
    result_banner7: "件奖品总价值：",
    result_banner7s: "件奖品总价值：",
    result_banner7_1: "奖品总价值计算中..",
    sellall: "全部放弃",
    sellbtn: "放弃",
    doing: "正在打开...",
    await: "等待打开",
    custom_title: " 10000-CSGO开箱网站良心高爆率的CSGO皮肤饰品交易平台",
    tips: {
      t1: "您的奖品已寄售!",
      t2: "您已没有奖品寄售!",
      t3: "您的代币不足本次开箱，请先充值!"
    }
  },
  tips: {
    t1: "请先登录!"
  },
  warehouse: {
    stor: "我的背包",
    log: "开箱记录",
    back: "取回教程",
    bind: "绑定教程",
    logtype: {
      type1: "崭新出厂",
      type2: "略有磨损",
      type3: "久经沙场",
      type4: "破损不堪",
      type5: "战痕累累"
    },
    class: {
      c1: "普通",
      c2: "纪念品"
    },
    status: {
      s1: "正常",
      s2: "领取中",
      s3: "已放弃",
      s4: "已领取",
      s5: "领取失败"
    },
    tips: {
      t1: "您未填写交易链接",
      t2: "交易链接绑定成功",
      t3: "领取失败",
      t4: "您已选择寄售饰品",
      t5: "您的饰品操作失败"
    }
  },
  stor: {
    placeholder: "请输入您的交易链接",
    placeholder2: "请输入您的交易链接（您还未登录，请先登录哦）",
    savebtn: "保存",
    foot1: "前往Steam获取交易链接",
    foot2: "推荐使用网易UU免费加速“Steam社区/商店”",
    stitle: "您共有 ",
    stitle1: " 件饰品",
    sellbtn: "放弃",
    backbtn: "取回",
    loadmore: "加载更多",
    masker1: "取回中，",
    masker2: "请点击 ",
    masker3: "接受报价",
    masker4: "已放弃",
    masker5: "领取成功",
    masker6: "取回中..",
    desc:
      "需要充值任意金额才能取回哦，未充值用户的背包库存保留8小时，充值后保留30天，保存时间截止后自动放弃"
  },
  log: {
    No: "编号",
    boxname: "箱子名称",
    getitem: "获得物品",
    degree: "磨损度",
    type: "类型",
    time: "时间",
    status: "状态"
  },
  opennew: {
    desc: "您的仓库是空的哦，去开箱获取炫酷装备吧",
    toopen: "去开箱"
  },
  footer: {
    footer1: "联系我们",
    footer2: "饰品查询",
    footer3: "常见问题",
    footer4: "用户协议",
    footer5: "隐私条款",
    official: "官方社交：",
    openbox: "已开箱",
    registeruser: "注册用户",
    lineuser: "在线用户",
    change: "已放弃",
    paytype: "支付方式：",
    ylpay: "云闪付支付",
    wechatpay: "微信支付",
    aplipay: "支付宝支付",
    footer:
      "安全、高效的开箱网站，您可以用优惠的价格获得您想要的皮肤，如果你有任何疑问都可以联系我们，我们将第一时间为您处理"
  },
  helptabs: {
    trans_help: "交易帮助",
    comm_pro: "常见问题",
    conditions: "使用条件",
    privacy_policy: "隐私条款",
    back: "取回教程",
    bind: "绑定教程",
    novice: "新手问题"
  },
  recharge: {
    title: "充值",
    stitle: "如遇充值问题，请更换支付方式重新尝试，或进入交流群进行反馈",
    selecttitle: "请选择充值金额",
    desc1:
      "为了保证您能顺利地领取到奖品，请您在充值前确认本账号是否被Steam限制交易，请自行前往Steam社区市场",
    desc2: "查看该帐号的交易安全内容",
    typetitle: "请选择支付方式",
    type1: "支付宝支付",
    type2: "微信支付",
    paymoney: "实付金额:",
    select_desc: "请选择充值金额",
    usedesc1: "请使用",
    usedesc2: "扫一扫",
    usedesc3: "完成支付",
    wechat: "微信",
    aplipay: "支付宝",
    usedesc4: "支付环境安全，请放心支付",
    type_desc: "即将开启",
    tips: {
      t1: "充值成功!"
    }
  },
  userinfo: {
    title: "账号设置",
    verified: "实名认证",
    end_verified: "已实名认证",
    invite_code: "绑定邀请码",
    end_invite_code: "已绑定邀请码",
    update_btn: "修改",
    sq: "收起",
    bind: "绑定",
    jbind: "解绑",
    toSteam: "前往Steam获取交易链接",
    toSteam_desc: "推荐使用网易UU免费加速“Steam社区/商店”",
    savebtn: "保存",
    tip: "保存成功",
    identification: "身份验证",
    enterRealName: "请填写您的真实姓名",
    enterIdentification: "请填写您的身份证号",
    identificationMsg: "请完善信息",
    changeemail: {
      title: "更换邮箱：",
      old_email_title: "验证原邮箱号",
      code: "请输入6位数验证码",
      new_email_title: "请绑定新的邮箱",
      new_email: "请输入您的新邮箱号",
      next: "下一步",
      btn: "确定",
      tips: {
        t1: "邮件已发送至您的邮箱！",
        t2: "请输入您的新邮箱！",
        t3: "请输入正确的验证码！",
        t4: "邮箱更换成功！"
      }
    },
    username: {
      title: "昵称：",
      placeholder: "请设置您的昵称",
      desc: "请设置昵称",
      tips: {
        t1: "请输入您的昵称！"
      }
    },
    password: {
      title: "登录密码：",
      desc: "请设置密码",
      old_placeholder: "当前密码",
      new_placeholder: "设置您的新密码",
      conf_placeholder: "确认您的新密码",
      tips: {
        t1: "请输入当前密码！",
        t2: "密码长度为6~12位！",
        t3: "两次密码输入不一致！"
      }
    },
    steam: {
      title: "绑定Steam：",
      desc: "请绑定您的Steam账号",
      dialog: {
        title: "系统提示",
        content: "确定要解除绑定Steam吗？",
        no: "手滑了",
        yes: "确认解绑"
      },
      tips: {
        t1: "绑定成功！",
        t2: "Steam解绑成功！"
      }
    },
    link: {
      title: "交易链接：",
      desc: "请设置交易链接",
      placeholder: "请输入您的交易链接",
      tips: {
        t1: "请输入您的交易链接！"
      }
    }
  },
  common: {
    vfx: "特效",
    balance: "代币：",
    sell: "放弃",
    warehouse: "放入仓库",
    sellAll: "全部放弃",
    explain: "关于玩法介绍",
    login: "您未登录！立即登录吧",
    confirm: "确 认",
    receive: "领 取",
    close:"关 闭",
  },
  points: {},
  unpack: {
    record: "最近开箱记录",
    title: "包含的饰品",
    close: "关",
    open: "开",
    animate: "动画开关",
    txt1: "您可使用热键",
    txt2: "进行快速开箱",
    chooseNumber: "选择数量",
    amount: "应付金额",
    handleSubmit: "确定开箱",
    handleOpening: "开箱中...",
    rewardDetails: "本次箱子详情",
    time: "时间",
    player: "玩家",
    getItems: "获得饰品",
    cost: "价值"
  },
  upgrade: {
    title: "装备升级",
    explain: "关于玩法介绍",
    historyBtn: "升级历史",
    upgradeAct: "选择用于升级的饰品",
    probability: "升级概率",
    wantAct: "选择想要得到的饰品",
    useBalance: "使用代币",
    myBalance: "我的代币",
    handleUpgrade: "点击升级",
    nowUpgrade: "正在升级",
    handleTxt: "* 升级最高概率为",
    myInventory: "我的库存",
    upgradeItem: "升级物品",
    nameItem: "物品名称...",
    all: "全部",
    category: "类别",
    price: "价格",
    priceRange: "价格区间",
    none: "您没有任何库存物品",
    handleGo: "去开箱",
    rewardDetails: "本次升级获得的饰品",
    handleReturn: "返回炼金",
    handleSuccess: "升级成功",
    handleFaild: "升级失败",
    handleSuccessResult: "升级成功",
    handleFaildResult: "升级失败"
  },
  battle: {
    title: "盒子大战",
    explain: "关于玩法介绍",
    model1: "盒子大战",
    model2: "官方比武",
    model3: "已结束",
    createBtn: "创建比赛",
    recordBtn: "我的记录",
    rounds: "回合",
    result: "战况",
    totalWin: "总赢",
    content: "比武内容",
    type: "游戏类型",
    cost: "总价值",
    players: "玩家",
    handle: "操作",
    totalCost: "饰品总价值：",
    handleAnimate: "动画特效",
    handleWatch: "观看",
    handleReturn: "返回列表",
    handleJoin: "立即加入",
    readyStatus: "准备就绪",
    handleWait: "等待玩家",
    handleReady: "准备就绪",
    running: "进行中",
    battle_end: {
      title: "本局盒子大战结果",
      endTxt: "比武结束",
      win: "赢得",
      box: "箱子详情",
      return: "返回列表",
      add: "创建比赛"
    },
    endResultTxt: "共赢得",
    endWin: "胜利!",
    endLose: "请再接再厉",
    battle_create: {
      createNew: "创建比武",
      handleReturn: "返回",
      chooseTitle: "选择箱子",
      totalTitle: "应付总价：",
      t: "恐怖分子",
      ct: "反恐精英",
      handleClear: "清空",
      userTotal: "比武模式",
      batchCreation: "批量创建",
      chooseTeam: "选择队伍",
      handleCreate: "支付创建",
      chosen: "选好了",
      handleAdd: "添加箱子",
      errorBoxs: "请勿超过箱子上限",
      errorBoxsZero: "请添加箱子",
      errorUserZero: "请选择玩家人数",

      
    },
      state_1:"等待中",
      state_2:"比武中",
      state_3:"已结束",
      new_lookdetal:"已结束",
      new_share:'点击邀请好友参战',
    battle_history: {
      return: "返回列表",
      record: "我的记录"
    }
  },
  roll: {
    title: "ROLL福利",
    explain: "关于玩法介绍",
    model1: "进行中",
    model2: "我参与的",
    model3: "已结束",
    createBtn: "创建房间",
    roll_count_time: {
      d: "天",
      h: "时",
      m: "分",
      s: "秒"
    },
    details: "ROLL详情"
  },
  rank: {
    title: "欧皇榜",
    model3: "武力榜",
    model1: "开箱榜",
    model2: "炼金榜",
    holdRankTime: "占领时间",
    curDataReward: "本期奖励",
    recordRank:"欧皇记录榜",
    noBody:"虚位以待"
  },
  card: {
    title: "抢卡炼金",
    model1: "进行中",
    model2: "已结束",
    recordBtn: "我的抢卡",
    return: "返回列表"
  },
  error: {
    d1: "请检查网页是否错误，或尝试刷新以查看是否可以成功",
    d2: "哎呀，界面不见了",
    d3: "返回首页"
  },
  trans,
  problem,
  conditions,
  privacy,
  pc,
  mobile,
  novice,
  steam
};
export default cn;
