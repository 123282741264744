const trans_cn = {
    title: 'ESCSGO 交易帮助',
    content: {
        title1: '1. 什么是Steam交易限制：',
        span1: '为了保护玩家的账户安全， Steam官方会因为用户的一些操作而限制其账户进行交易平台遵循Steam官方市场交易准则交易。交易限制期间的用户将无法取回物品',
        title2: '2. 如何查看我的账号交易限制详情：',
        span2_1: '点击此网站：',
        span2_2: '（若您无法打开此网页，请使用UU加速器加速“Steam社区/商店”）并登陆您的Steam账号即可查看账号目前的交易限制详情',
        title3: '3. 如何解除账号交易限制：',
        span3: 'Steam交易限制是无法解除的，只能等待限制时间结束，Steam客服不会修改交易或 Steam 社区市场中的限制，无论您的帐户出于何种原因受限我们同样无权解除用户账户的限制，有交易限制的用户等限制解除之后即可交易',
        title4: '4. 什么情况下会产生Steam交易限制：',
        title4_1: '4.1 Steam 令牌未启用',
        span4_1: 'Steam手机令牌超过15天，如果您启用Steam令牌不到15 天，您将无法交易，您可以 通过手机直接下载Steam Mobile, 开通Steam令牌，等待15天的冷却时间便可解除限制。 绑定Steam令牌教程',
        title4_2: '4.2 最近修改过账号密码',
        span4_2: '如果您的账户近期更改过Steam登录密码，同样也会产生7天的交易限制，参与活动时请谨慎使用',
        title4_3: '4.3 重置Steam令牌',
        span4_3: '如果您最近移除或重置过Steam手机令牌，也会产生7天交易限制时间。更换手机重新安装手机令牌或者卸载重装均会触发此限制，重置令牌时请谨慎',
        title4_4: '4.4 Steam消费限制',
        span4_4_1: '若您的账号两个月内在Steam未产生任何消费，这将会使您的账户进入交易限制，此时需要在Steam商店购买任意一款任意金额的游戏然后等待七天的交易冷却期即可；',
        span4_4_2: '若您的账号是新注册的Steam账号，则需要在Steam商店购买一款不低于5美元的游戏，然后等待15天的交易限制时间即可',
        span4_5: '请注意，Steam为保障用户的交易安全会设置诸多繁琐的限制来保证您的账户处于安全的交易环境，参与活动前我们建议您将账号中的所有交易限制完全解除后再参与活动，以免造成不必要的损失，感谢您的理解和配合，祝您游戏愉快',
        title5: '5.Steam账号库存隐私设置：',
        span5_1: 'Steam交易过程中需要将库存隐私公开，这样方便我们的派奖机器人能够及时准确的将您获得的奖品发放到您的账户，设置方法可参照下图所示：',
        title6: '6. Steam无法访问解决方案：',
        span6: '我们推荐您使用网易UU加速器加速“Steam社区/商店”来登录Steam，手机端可免费 下载网易UU加速器移动版加速Steam社区/商店，因部分浏览器尚不支持Steam的一些相关页面，我们推荐您使用谷歌浏览器访问Steam，感谢您的理解与支持'
    }
}
export default trans_cn