if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') {
  console.log = (...arge) => { }
}
//转换时间戳
const timestampToTime = function (time) {
  if (!time) return "-";
  let $time = new Date().getTime() - new Date(time * 1000).getTime();
  if ($time < 60) {
    return $time + "秒前";
  }
  let $date = {
    年: 1000 * 60 * 60 * 24 * 7 * 30 * 12,
    个月: 1000 * 60 * 60 * 24 * 7 * 30,
    星期: 1000 * 60 * 60 * 24 * 7,
    天: 1000 * 60 * 60 * 24,
    小时: 1000 * 60 * 60,
    分钟: 1000 * 60
  };
  for (var $v in $date) {
    var $k = $date[$v];
    if ($time >= $k) {
      let $min = Math.floor($time / parseInt($k));
      if ($min == 0) {
        $min += 1;
      }
      return $min + $v + "前";
    }
  }
};


// 用价格自定义饰品等级
const sortOnPrice = (price) => {
  let relPrice = price / 10000
  if (relPrice <= 10) {
    return 1
  } else if (relPrice <= 100) {
    return 2
  } else if (relPrice <= 1000) {
    return 3
  } else {
    return 4
  }
}

const getVideoPath = (filename) => {
  if (process.env.NODE_ENV === "production") {
    return GetCDNHead() + "webAssets/video/" + filename;
  } else {
    // 在开发环境
    return "/video/" + filename;
  }
}

/**
 * 将日期格式化成指定格式的字符串
 * @param date 要格式化的日期，不传时默认当前时间，也可以是一个时间戳
 * @param fmt 目标字符串格式，支持的字符有：y,M,d,q,w,H,h,m,S，默认：yyyy-MM-dd HH:mm:ss
 * @returns 返回格式化后的日期字符串
 */
const formatDate = function (value) {
  return formatDateFn(value * 1000);
};
const formatDate2 = function (value) {
  return formatDateFn(value * 1000, "yyyy-MM-dd");
};
const formatDate3 = function (value) {
  return formatDateFn(value * 1000, "yyyy-MM-dd HH:mm");
};
const formatDate4 = function (value) {
  return formatDateFn(value * 1000, "MM-dd HH:mm");
};
const formatDate5 = function (value) {
  return formatDateFn(value * 1000, "yyyy.MM.dd-HH:mm");
};
const formatDate6 = function (value) {
  return formatDateFn(value * 1000, "yyyy.MM.dd HH:mm:ss");
};
const formatDate7 = function (value) {
  return formatDateFn(value * 1000, "MM/dd");
};
const formatDateFn = function (date, fmt) {
  date = date == undefined ? new Date() : date;
  date = typeof date == "number" ? new Date(date) : date;
  fmt = fmt || "yyyy-MM-dd HH:mm:ss";
  var obj = {
    y: date.getFullYear(), // 年份，注意必须用getFullYear
    M: date.getMonth() + 1, // 月份，注意是从0-11
    d: date.getDate(), // 日期
    q: Math.floor((date.getMonth() + 3) / 3), // 季度
    w: date.getDay(), // 星期，注意是0-6
    H: date.getHours(), // 24小时制
    h: date.getHours() % 12 == 0 ? 12 : date.getHours() % 12, // 12小时制
    m: date.getMinutes(), // 分钟
    s: date.getSeconds(), // 秒
    S: date.getMilliseconds() // 毫秒
  };
  var week = ["天", "一", "二", "三", "四", "五", "六"];
  for (var i in obj) {
    fmt = fmt.replace(new RegExp(i + "+", "g"), function (m) {
      var val = obj[i] + "";
      if (i == "w") return (m.length > 2 ? "星期" : "周") + week[val];
      for (var j = 0, len = val.length; j < m.length - len; j++)
        val = "0" + val;
      return m.length == 1 ? val : val.substring(val.length - m.length);
    });
  }
  return fmt;
};
//秒转为时分秒
const formatSeconds = function (result) {
  var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
  return result = h + " : " + m + " : " + s || "00 : 00 : 00"
};
//秒 换成分秒  05：00
const formatMS = function (date) {
  //计算分钟
  //算法：将秒数除以60，然后下舍入，既得到分钟数
  var m;
  m = Math.floor(date / 60);
  //计算秒
  //算法：取得秒%60的余数，既得到秒数
  date = date % 60;
  //将变量转换为字符串
  m += "";
  date += "";
  //如果只有一位数，前面增加一个0
  m = m.length == 1 ? "0" + m : m;
  date = date.length == 1 ? "0" + date : date;
  return m + ":" + date;
};
//秒 换成分秒  5min
const formatMSMin = function (date) {
  //计算分钟
  //算法：将秒数除以60，然后下舍入，既得到分钟数
  var m;
  m = Math.floor(date / 60);
  //计算秒
  //算法：取得秒%60的余数，既得到秒数
  date = date % 60;
  //将变量转换为字符串
  m += "";
  date += "";
  //如果只有一位数，前面增加一个0
  // m = m.length == 1 ? "0" + m : m;
  date = date.length == 1 ? "0" + date : date;
  return m + "min";
};
// 数组随机
const shuffleArray = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};
// 对象转变get
const object2str = data => {
  let str = "";
  Object.keys(data).forEach((key, index) => {
    if (!index) {
      str += key + "=" + data[key];
    } else {
      str += "&" + key + "=" + data[key];
    }
  });
  return str;
};
// 计算精度
const isInteger = obj => {
  return Math.floor(obj) === obj;
};
const toInteger = floatNum => {
  var ret = { times: 1, num: 0 };
  if (isInteger(floatNum)) {
    ret.num = floatNum;
    return ret;
  }
  var strfi = floatNum + "";
  var dotPos = strfi.indexOf(".");
  var len = strfi.substr(dotPos + 1).length;
  var times = Math.pow(10, len);
  var intNum = parseInt(floatNum * times + 0.5, 10);
  ret.times = times;
  ret.num = intNum;
  return ret;
};
const operation = (a, b, digits, op) => {
  var o1 = toInteger(a);
  var o2 = toInteger(b);
  var n1 = o1.num;
  var n2 = o2.num;
  var t1 = o1.times;
  var t2 = o2.times;
  var max = t1 > t2 ? t1 : t2;
  var result = null;
  switch (op) {
    case "add":
      if (t1 === t2) {
        // 两个小数位数相同
        result = n1 + n2;
      } else if (t1 > t2) {
        // o1 小数位 大于 o2
        result = n1 + n2 * (t1 / t2);
      } else {
        // o1 小数位 小于 o2
        result = n1 * (t2 / t1) + n2;
      }
      return result / max;
    case "subtract":
      if (t1 === t2) {
        result = n1 - n2;
      } else if (t1 > t2) {
        result = n1 - n2 * (t1 / t2);
      } else {
        result = n1 * (t2 / t1) - n2;
      }
      return result / max;
    case "multiply":
      result = (n1 * n2) / (t1 * t2);
      return result;
    case "divide":
      result = (n1 / n2) * (t2 / t1);
      return result;
  }
};
const add = (a, b, digits) => {
  return operation(a, b, digits, "add");
};
const subtract = (a, b, digits) => {
  return operation(a, b, digits, "subtract");
};
const multiply = (a, b, digits) => {
  return operation(a, b, digits, "multiply");
};
const divide = (a, b, digits) => {
  return operation(a, b, digits, "divide");
};
const rollTo = (distance = 0, time) => {
  if (!time) {
    scrollTo(0, distance);
    return distance;
  }
  const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
  let index = time / spacingTime; // 计算循环的次数
  let now = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
  let rollDistance = (distance - now) / index; // 计算每次滑动的距离
  let rollTimer = setInterval(() => {
    if (index > 0) {
      index--;
      rollTo((now += rollDistance));
    } else {
      clearInterval(rollTimer); // 清除计时器
    }
  }, spacingTime);
};
const assemblyList = (data, num) => {
  let arr = JSON.parse(JSON.stringify(data));
  let s = [];
  if (arr.length >= num) {
    s = shuffleArray(arr).splice(0, num);
  } else {
    let l = num - arr.length;
    s = shuffleArray(arr);
    for (var i = 0; i < l; i++) {
      let number = Math.floor(arr.length * Math.random());
      s.push(arr[number]);
    }
  }
  return s;
};
const assemblyBox = (data, num) => {
  let arr = JSON.parse(JSON.stringify(data));
  arr.map(item => {
    item.num = Math.ceil((num / 100) * item.show_rate);
  });
  let s = [];
  arr.map(item => {
    for (var i = 0; i < item.num; i++) {
      s.push(item);
    }
  });
  if (s.length >= num) {
    s = shuffleArray(s).splice(0, num);
  } else {
    let l = num - s.length;
    for (var i = 0; i < l; i++) {
      let number = Math.floor(arr.length * Math.random());
      s.push(arr[number]);
    }
  }
  return s;
};

const assemblyOpenBox = (data, num, quality_rate) => {
  let arr = JSON.parse(JSON.stringify(data));
  // console.log(arr,'arr------------------')
  // var newArr = groupBy(arr, function (item) {
  //   return [item.jewelry_rarity];
  // });
  // console.log(newArr,'newArr-------------')
  // let jewelry_rarity=[]
  // 获取饰品珍贵度数组
  // for(let i=0;i<newArr.length;i++){
  //   jewelry_rarity.push(newArr[i][0].jewelry_rarity)
  // }
  // console.log(jewelry_rarity,'jewelry_rarity----------')

  // 获取饰品珍贵度 对应比例值
  arr.map(item => {
    if (item.jewelry_rarity == '1') {
      // debugger
      item.num = Math.ceil((num / 100) * quality_rate.green);
    }
    if (item.jewelry_rarity == '2') {
      item.num = Math.ceil((num / 100) * quality_rate.blue);
    }
    if (item.jewelry_rarity == '3') {
      item.num = Math.ceil((num / 100) * quality_rate.red);
    }
    if (item.jewelry_rarity == '4') {
      item.num = Math.ceil((num / 100) * quality_rate.gold);
    }
    // item.num = Math.ceil((num / 100) * item.show_rate);
  });
  // console.log(arr,'ar-----------------------------')
  let s = [];
  arr.map(item => {
    for (var i = 0; i < item.num; i++) {
      s.push(item);
    }
  });
  if (s.length >= num) {
    s = shuffleArray(s).splice(0, num);
  } else {
    let l = num - s.length;
    for (var i = 0; i < l; i++) {
      let number = Math.floor(arr.length * Math.random());
      s.push(arr[number]);
    }
  }
  return s;
};

const sortArr = (data, boolean) => {
  return (a, b) => {
    var value1 = a[data];
    var value2 = b[data];
    if (boolean) {
      return value1 - value2;
    } else {
      return value2 - value1;
    }
  };
};
const strLen = sString => {
  var j = 0;
  var s = sString;
  if (s == "") return j;
  for (var i = 0; i < s.length; i++) {
    if (s.substr(i, 1).charCodeAt(0) > 255) j = j + 2;
    else j++;
  }
  return j;
};
const subString = (str, len, hasDot) => {
  var newLength = 0;
  var newStr = "";
  var chineseRegex = /[^\x00-\xff]/g;
  var singleChar = "";
  var strLength = str.replace(chineseRegex, "**").length;
  for (var i = 0; i < strLength; i++) {
    singleChar = str.charAt(i).toString();
    if (singleChar.match(chineseRegex) != null) {
      newLength += 2;
    } else {
      newLength++;
    }
    if (newLength > len) {
      break;
    }
    newStr += singleChar;
  }

  if (hasDot && strLength > len) {
    newStr += "...";
  }
  return newStr;
};

const FormatS2CUserCoin = (_value) => {
  return _value / 10000;
}
let CDN_URL = null
const GetCDNHead = () => {
  if (CDN_URL == null) {
    if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') { // 发布版本
      let protocol = '';
      let locationHost = '';
      if (process.server) {
        console.log("process.server call GetCDNHead func")
        return process.env.CDN;
      }else if (process.client) {
        protocol = window.location.protocol;
        locationHost = window.location.host;
      }
      if (protocol.includes('https')) {
        protocol = 'https://'
      }else{
        protocol = 'http://'
      }
      CDN_URL = protocol + locationHost + "/public/escsgo/"
    }else{
      CDN_URL = process.env.CDN;
    }
  }
  return CDN_URL;
};

const fromatCDNPath = (path) => {
  if (!path || path.length == 0) {
    return ""
  }
  if (path.indexOf('://') !== -1) {
    return path;
  } else {
    return GetCDNHead() + path;
  }
}
const GetSoundSrcForKey = () => {
  let soundpath = GetCDNHead() + 'webAssets/mp3/'
  let Config = {
    btnVoice: soundpath + "btnVoice.mp3", 
    sellMp3: soundpath + "sell.mp3",
    sellAllMp3: soundpath + "sellAll.mp3",
    pkLight: soundpath + "pkLight.mp3",
    pkScroll: soundpath + "pkScroll.mp3",
    pkMoney: soundpath + "pkMoney.mp3",
    pkEnd: soundpath + "pkEnd.mp3",
    animateClick: soundpath + "animateClick.mp3",
    unpack1: soundpath + "unpack1.mp3",
    unpack2: soundpath + "unpack2.mp3",
    unpack3: soundpath + "unpack3.mp3",
    unpack4: soundpath + "unpack4.mp3",
    unpack5: soundpath + "unpack5.mp3",
    upgrade: soundpath + "upgrade.mp3",
    animateBoom: soundpath + "animateBoom.mp3",
    hideIn: soundpath + "sellAll.mp3",
    openbox:  soundpath + "unpack.mp3",
    openbox1: soundpath + "unpack1.mp3",
    openbox2: soundpath + "unpack2.mp3",
    openbox3: soundpath + "unpack3.mp3",
    openbox4: soundpath + "unpack4.mp3",
    openbox5: soundpath + "unpack5.mp3",
    pk_scroll_2: soundpath + "pk_scroll_2.mp3",
    pk_scroll_3: soundpath + "pk_scroll_3.mp3",
    pk_scroll_4: soundpath + "pk_scroll_4.mp3",
    pk_scroll_5: soundpath + "pk_scroll_5.mp3",
    fairybean: soundpath + "fairybean.mp3",

    good_shoot_ak_more: soundpath + "good_shoot_ak_more.mp3",
    good_shoot_ak: soundpath + "good_shoot_ak.mp3",
    good_shoot_bg: soundpath + "good_shoot_bg.mp3",
    good_shoot_check_gun: soundpath + "good_shoot_check_gun.mp3",
    good_shoot_mp5_more: soundpath + "good_shoot_mp5_more.mp3",
    good_shoot_mp5: soundpath + "good_shoot_mp5.mp3",
    good_shoot_open_box: soundpath + "good_shoot_open_box.mp3",
    good_shoot_pistol_more: soundpath + "good_shoot_pistol_more.mp3",
    good_shoot_pistol: soundpath + "good_shoot_pistol.mp3",
  }
  return Config;
};

// 乘法精却
const accMul = (arg1, arg2) => {
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try { m += s1.split(".")[1].length } catch (e) { }
  try { m += s2.split(".")[1].length } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

const getObjType = obj => {
  var toString = Object.prototype.toString;
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  };
  if (obj instanceof Element) {
    return 'element';
  }
  return map[toString.call(obj)];
};

//深拷贝
const deepClone = data => {
  var type = getObjType(data);
  var obj;
  if (type === 'array') {
    obj = [];
  } else if (type === 'object') {
    obj = {};
  } else {
    //不再具有下一层次
    return data;
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

const formatDatetime = (time, fmt) => {
  let formateStr = fmt || 'yyyy-MM-dd hh:mm:ss'
  time = new Date(time)
  var o = {
    'M+': time.getMonth() + 1,                 // 月份
    'd+': time.getDate(),                    // 日
    'h+': time.getHours(),                   // 小时
    'm+': time.getMinutes(),                 // 分
    's+': time.getSeconds(),                 // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
    'S': time.getMilliseconds()             // 毫秒
  }
  if (/(y+)/.test(formateStr)) { formateStr = formateStr.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(formateStr)) { formateStr = formateStr.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return formateStr
}
/**
 * 格式化为时间戳
 * @param {Time} time 时间
 * @returns {string} 结果时间戳
 */
const formatTimeStamp = (time) => {
  time = formatDatetime(time)
  let new_str = time.replace(/:/g, '-')
  new_str = new_str.replace(/ /g, '-')
  let arr = new_str.split('-')
  let datum = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3] - 8, arr[4], arr[5]))
  return datum.getTime() / 1000
}

const getServerTime = () => {
  return ((Math.floor(Date.now() / 1000)) + window.__server_time_difference)
}

const getUploadFileUrl = () => {
  return process.env.baseUrl + "usercenter/v1/user/upload-file"
}

const disableScale = function () {
  function mousewheel(e) {
    e = e || window.event;
    if ((e.wheelDelta && event.ctrlKey) || e.detail) {
      event.preventDefault();
    }
  }

  function keydown(event) {
    if (
      (event.ctrlKey === true || event.metaKey === true) &&
      (event.keyCode === 61 ||
        event.keyCode === 107 ||
        event.keyCode === 173 ||
        event.keyCode === 109 ||
        event.keyCode === 187 ||
        event.keyCode === 189 ||
        event.keyCode === 48)
    ) {
      event.preventDefault();
    }
  }

  const addListener = function () {
    document.addEventListener(
      "mousewheel",
      mousewheel,
      {
        capture: false,

        passive: false,
      }
    );

    document.addEventListener(
      "keydown",
      keydown,
      false
    );
  };

  const removeListener = function () {
    document.removeEventListener('mousewheel', mousewheel, false);
    document.removeEventListener('keydown', keydown, false);
  };

  return {
    addListener,
    removeListener,
  }
}();

export {
  formatDateFn,
  formatDate,
  formatDate2,
  formatDate3,
  formatDate4,
  formatDate5,
  formatDate6,
  formatDate7,
  timestampToTime,
  formatMS,
  formatMSMin,
  shuffleArray,
  object2str,
  rollTo,
  assemblyList,
  assemblyBox,
  assemblyOpenBox,
  sortArr,
  add,
  subtract,
  divide,
  multiply,
  strLen,
  subString,
  GetCDNHead,
  GetSoundSrcForKey,
  fromatCDNPath,
  FormatS2CUserCoin,
  accMul,
  formatSeconds,
  getObjType,
  deepClone,
  formatTimeStamp,
  getServerTime,
  getUploadFileUrl,
  disableScale,
  sortOnPrice,
  getVideoPath
};
