export default function ({
  isServer,
  req,
  redirect,
  route,
  query
}) {
  let mobileOrigin = ''
  if(process.server){
    let str = ''
    if(!!query){
      for (let i in query) {
        let value = query[i]
				str += `&${i}=${value}`
			}
			str = str.replace('&', '?')
    }

    let linkUrl = ''
    if (req.headers['host'].includes('escsgo.com')) {
      linkUrl = 'https://m.escsgo.com' + str
    } else {
      linkUrl = 'https://m.' + req.headers['host'].split('.').slice(-2).join('.') + str
    }
    mobileOrigin = req ? linkUrl : 'https://m.escsgo.com/'
  }
  let isMobile = (ua) => {
    return !!ua.match(/AppleWebKit.*Mobile.*/)
  }
  if (process.env.NODE_ENV === "production") {
    let userAgent = req ? req.headers['user-agent'] : navigator.userAgent || ''
    if (isMobile(userAgent)) {
      redirect(mobileOrigin)
    }
  }
}
