
import { GetSoundSrcForKey } from "@/assets/js/filter";
export default {
  data(){
    return{
      soundSrc:{}
    }
  },
  mounted(){
    this.soundSrc = GetSoundSrcForKey();
  },
  methods:{
    open(){
      if(this.$refs["btnVoice"] && this.$refs["btnVoice"].play){
        this.$refs["btnVoice"].currentTime = 0;
        this.$refs["btnVoice"].play();
      }
    }
  }
}
