export default ({ app, env }) => {
  // let getTopLevelDomain = (hostname) => {
  //   // 检查是否是内网 IP 地址 192.168.*.*
  //   if (/^192\.168\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
  //     return hostname;
  //   }
  //   const parts = hostname.split('.');
  //   // 如果只有一个部分，返回整个hostname（如"localhost"）
  //   if (parts.length === 1) {
  //     return hostname;
  //   }
  //   // 检查是否为常见的二级顶级域名（例如：.co.uk，.com.cn 等）
  //   const commonSecondLevelTLDs = ['co.uk', 'org.uk', 'gov.uk', 'ac.uk', 'com.au', 'com.cn', 'net.cn'];
  //   // 组合最后两部分
  //   const lastTwoParts = parts.slice(-2).join('.');
  //   // 检查是否匹配常见的二级顶级域名
  //   if (commonSecondLevelTLDs.includes(lastTwoParts)) {
  //     return parts.slice(-3).join('.'); // 返回最后三部分
  //   }
  //   // 返回最后两部分
  //   return lastTwoParts;
  // }

  // console.log("window.location.search:", window.location.search)
  // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // // 输出结果
  // if (isMobile) {
  //   // console.log("window.location.search:", window.location.search)
  //   const hostname = window.location.hostname;
  //   let url = window.location.protocol + "//m." + getTopLevelDomain(hostname)
  //   if(window.location.search.length > 0){
  //     url = url + window.location.search
  //   }
  //   if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
  //     window.location.href = url
  //   }
  //   console.log("url:", url)
  // }
};
