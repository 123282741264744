const PC_tw = {
    title: 'PC端飾品取回教程',
    content: {
        title1: '溫馨提示：',
        span1_1: '1. 所有飾品0冷卻，秒取回',
        span1_2: '2. 此平臺需與您的Steam帳號綁定',
        span1_3: '3. 在“正在取回”狀態下，請您儘快前往Steam接受報價如果錯過時間，您可以重新取回',
        span1_4: '4. 所有飾品以Steam市場價為基準，回收價格為市場價',
        span1_5: '5. 如遇暫時缺貨的情況，請您稍後再嘗試取回或者聯繫客服。飾品30天後未取回將會被系統全價回收',
        span1_6: '6. 如果有其他問題請聯繫客服',
        title2: '操作步驟：',
        span2_1: '1. 在進行以下操作前，',
        span2_1_1: '請保證您的Steam社區訪問已經被成功加速',
        span2_1_2: '(推薦使用UU加速器免費加速“Steam社區/商店”)',
        span2_2: '2. 在遊戲活動中心的“我的倉庫”點擊飾品取回，過幾分鐘會在Steam介面上',
        span2_2_1: '您的頭像框左邊出現一個綠色的新消息圖示',
        span2_2_2: '，點擊這個圖示',
        span2_3: '3. 在彈出的菜單中選擇“',
        span2_3_1: '待處理的交易報價',
        span2_3_2: '”',
        span2_4: '4. 找到您需要取回的物品，然後點擊“',
        span2_4_1: '回應報價',
        span2_4_2: '”按鈕',
        span2_5: '5. 在彈出的窗口中，先選中“',
        span2_5_1: '準備好交易', 
        span2_5_2: '”按鈕，然後點擊“',
        span2_5_3: '接受交易',
        span2_5_4: '”按鈕。',
        span2_6: '6. 完成以上步驟即可成功取回飾品，你學會了嗎？'
    }
}
export default PC_tw
    