const Mobile_uk = {
    title: 'Mobile terminal jewelry retrieval tutorial',
    content: {
        title1: 'Operation steps:',
        span1_1: '1. In the game center ',
        span1_2: '"My warehouse" click jewelry to retrieve',
        span2_1: '2.',
        span2_2: ' Turn on steam',
        span2_3: '(the mobile version of steam needs to be accelerated before it can be used. It is recommended to use the UU accelerator mobile version. Click accelerate. Note: UU accelerator mobile version of acceleration steam is also free)',
        span3_1: '3. Open steamapp and click on the upper left corner"',
        span3_2: 'Three bars',
        span3_3: '"Open the side menu',
        span4_1: '4. Click the "notice" button on the left to see the new notice with green envelope style. Click the "',
        span4_2: 'new transaction quotation',
        span4_3: '" button',
        span5_1: '5. Click the new transaction quotation to see the transaction details. Click "',
        span5_2: 'response quotation',
        span5_3: '" on the open page to start retrieving',
        span6_1: '6. In the new transaction interface, click "',
        span6_2: 'ready for transaction',
        span6_3: '" and check whether the items provided by the opposite party are consistent. After checking, click "',
        span6_4: 'accept transaction',
        span6_5: '" below',
        span7: '7. After completing the above steps, you can successfully retrieve the jewelry. Have you learned it?'
    }
}
export default Mobile_uk