
import { fromatCDNPath } from "@/assets/js/filter";
export default {
  name: "PageRegisterSuc",
  data() {
    return {
      reward_price: 0,
      qqinfo: {
        qq: "",
        qq_img: "",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    this.getBindCoin();
    // 获取群信息
    this.getGroupInfo();
  },
  methods: {
    formatCdnPath(_value) {
      return fromatCDNPath(_value);
    },
    getGroupInfo() {
      this.$siteGetQQInfo().then((res) => {
        this.qqinfo = res.data;
      });
    },
    getBindCoin() {
      this.$getBindCoin().then((res) => {
        this.reward_price = res.data.reward_price;
      });
    },
    close() {
      this.$emit("close");
    },
    async copySomething() {
      try {
        await this.$copyText(this.qqinfo.qq);
        this.$store.dispatch("showMessagePopupFn", {
          type: 1,
          text: "复制群号成功",
        });
      } catch (e) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "复制群号失败",
        });
      }
    },
    goToPage() {
      this.$router.push("/mine/inventory");
      this.close();
    },
  },
};
