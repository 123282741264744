
export default {
  props: ["type", "title", "OffQuickClose"],
  name: "ComfirmeDialog",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    dialogClose(){
      if(!this.OffQuickClose){
        this.$emit('close')
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
