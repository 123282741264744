import trans from "./help/trans_uk.js";
import problem from "./help/problem_uk.js";
import conditions from "./help/use_uk.js";
import privacy from "./help/privacy_uk.js";
import pc from "./help/PC_uk.js";
import mobile from "./help/Mobile_uk.js";
import novice from "./help/Novice_uk.js";
import steam from "./help/Steam_uk.js";
const uk = {
  vip: {
    title: "VIP rating system",
    get: "Receive",
    haveGet: "Received",
    notGet: "Do not pick up"
  },
  header: {
    home: "Home",
    integral: "Live",
    card: "Card",
    battle: "Battle",
    equip: "Equipment Upgrade",
    help: "Help",
    rank: "Royal",
    welfare: "Welfare",
    login: "Register/Login",
    logout: "Logout",
    recharge: "Recharge",
    inventory: "Inventory",
    vip: "VIP",
    userInfoList: {
      basic: "Basic information",
      inventory: "Accessories inventory",
      record: "Out of the record",
      promote: "To promote the user"
    }
  },
  home: {
    time: "Time",
    getItems: "Skin ",
    cost: "Cost",
    player: "Player",
    list: "Recent Award List",
    day: "Day",
    week: "Week",
    month: "Month",
    hour: "Hour",
    remaining: "Time remaining:",
    txt1: {
      th1: "每",
      th2: "充值满",
      th3: "固定金额",
      th4: "后，将会自动报名参与"
    },
    txt2: {
      th1: "每",
      th2: "固定时间",
      th3: "会抽取一位幸运玩家，获得当前抽奖的饰品"
    },
    txt3: {
      th1: "中奖的饰品，会直接发到",
      th2: "我的库存",
      th3: "里，请注意查收"
    }
  },
  banner: {
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    second: "Second",
    buttonText: "Time limited points exchange",
    prompt:
      "Recharge bonus points, free exchange popular accessories, limited number of first - first - served",
    registerNum: "Registrations",
    boxOpenNum: "Boxes opened",
    battleNum: "Battles",
    upNum: "Equipment upgrades"
  },
  index: {
    jptj: "Boutique recommendation",
    activity_prompt: "Time to draw:",
    consume: "Top-up",
    join: "to join",
    activity_1: "Daily regular lottery",
    activity_2: "Weekly regular lottery",
    activity_3: "Monthly regular lottery",
    activity_4: "Hour regular lottery",
    tab_1: "热门人气汇聚",
    tab_2: "稀有限定装备",
    tab_3: "战地近战幽灵",
    tab_4: "精英冲锋战队",
    tab_5: "中坚传奇对抗",
    tab_6: "神秘关键箱子",
    tab_7: "神秘关键箱子",
    open_box: "Open Box",
    sold: "Sold:"
  },
  langue: {
    ch: "Chinese",
    en: "English"
  },
  gold: "GOLD",
  openbtn: "Open",
  hoverright: {
    service: "Online Service",
    totop: "To the top"
  },
  logins: {
    login: "Log In",
    register: "Register",
    updatepwd: "Change Password",
    emailholder: "Please enter the registration email",
    passwordholder: "Please enter the 6-12-digit password",
    btn: "Determine",
    freeRegister: "Sign up for free",
    forgetPwd: "Forget password",
    read: "I have read and agree",
    read1: '"Terms of use"',
    read2: "and",
    read3: '"Privacy policy"',
    steamlogin: "Steam login",
    res_emai_holder: "Please enter your email",
    res_code: "Get code",
    res_code_holder: "Please enter the 6-12digit verification code",
    res_code_des:
      "No mail? May be transferred to the dustbin, please go to check",
    res_pwd_holder: "Please enter your password",
    res_pwd_des: "The length of the password is 6-12 bits",
    al_user: "You already have an account number,",
    gologin: "Log in",
    newpwd: "New password",
    newpwd_des: "The password length shall not be less than 6 digits",
    confirm: "Confirm the password again",
    tips: {
      t1: "Please enter the correct email address!",
      t2: "The length of the password is between 6 and 32 bits",
      t3: "Please check I have read and agree!",
      t4: "Login successful!",
      t5: "Email has been sent to your email!",
      t6: "Please enter correct verification code!",
      t8: "Successfully registered!",
      t9: "The two passwords are inconsistent!",
      t10: "Modified successfully!"
    }
  },
  energy: {
    lefttitle: "Energy box",
    righttitle: "First charge",
    righttitle1: "New people's welfare",
    leftbanner: "Every consumption will accumulate energy",
    ringtext: "My energy",
    leftstitle: "Your benefits:",
    rightbanner: "Recharge any amount can be opened free of charge",
    openbtn: "Open the box",
    openbtn1: "Turned on",
    open_flag: "Openable",
    open_desc: "When your energy exceeds",
    open_desc1: ", the test energy will be activated automatically",
    right_open_btn: "Free unpacking",
    right_open_btn1: "Turned on",
    tips: {
      t1: "You haven't reached the energy level yet. Open this energy box!"
    }
  },
  boxDetail: {
    back: "Go back",
    showvoice: "Turn on the sound",
    closevoice: "Turn sound off",
    showanimate: "Start effect",
    closeanimate: "Turn off the motor",
    tips: "Press the space bar to skip the action",
    openonce: "Open once for ",
    openfive: "Open five times ",
    openten: "Open ten times ",
    detail_title: "There ",
    detail_titles: "is ",
    detail_titles1: "are ",
    detail_title1: "items in the box",
    result_banner1: "To retrieve it back to your Steam account, please go to ",
    result_banner2: "My Warehouse",
    result_banner3: "Winning result",
    result_banner3_1: "opening",
    result_banner4: " · open",
    result_banner5: "times",
    result_banner6: "Total value of ",
    result_banner7: " prize:",
    result_banner7s: " prizes:",
    result_banner7_1: "The total value of the prize is calculated",
    sellall: "Sell all",
    sellbtn: "Sell ",
    doing: "Opening...",
    await: "Waiting to open",
    custom_title:
      " escsgo - csgo open box high rate of csgo skin jewelry trading platform",
    tips: {
      t1: "Your prize has been sold successfully!",
      t2: "You have no prizes for sale!",
      t3: "Your balance is insufficient, please recharge first!"
    }
  },
  tips: {
    t1: "Please log in first!"
  },
  warehouse: {
    stor: "My Warehouse",
    log: "Open Log",
    back: "Tutorial retrieved",
    bind: "Binding tutorial",
    logtype: {
      type1: "崭新出厂",
      type2: "略有磨损",
      type3: "久经沙场",
      type4: "破损不堪",
      type5: "战痕累累"
    },
    class: {
      c1: "Ordinary",
      c2: "Keepsake"
    },
    status: {
      s1: "Normal",
      s2: "Receiving",
      s3: "Sold",
      s4: "Received",
      s5: "Collection failed"
    },
    tips: {
      t1: "You did not fill in the transaction link",
      t2: "Transaction link bound successfully",
      t3: "Collection failed",
      t4: "Your accessories have been sold successfully",
      t5: "Your jewelry failed to sell"
    }
  },
  stor: {
    placeholder: "Please enter your transaction link",
    placeholder2:
      "Please enter your transaction link (you have not logged in, please log in first)",
    savebtn: "Save",
    foot1: "Go to steam for transaction link",
    foot2:
      'Recommend using Netease UU to accelerate "steam community / store" for free',
    stitle: "You have ",
    stitle1: " items in total",
    sellbtn: "Sell",
    backbtn: "Retrieve",
    loadmore: "Load more",
    masker1: "Retrieving, ",
    masker2: " ",
    masker3: "accept quotation",
    masker4: "Sold",
    masker5: "Successfully received",
    masker6: "Getting it back..",
    desc:
      "You need to recharge any amount to get it back. The backpack inventory of users who have not recharged will be kept for 8 hours, and it will be kept for 30 days after recharge. It will be automatically sold after the storage time expires."
  },
  log: {
    No: "No",
    boxname: "Box name",
    getitem: "Get items",
    degree: "Degree of wear",
    type: "Types of",
    time: "Time",
    status: "Status"
  },
  opennew: {
    desc: "Your warehouse is empty, go unpack and get cool equipment",
    toopen: "Go unboxing"
  },
  footer: {
    footer1: "Contact Us",
    footer2: "View Accessories",
    footer3: "Q&A",
    footer4: "Terms Of Use",
    footer5: "Privacy Policy",
    official: "The Official Social:",
    openbox: "Unboxed",
    registeruser: "Registered user",
    lineuser: "Online user",
    change: "Redeemed",
    paytype: "Payment method:",
    ylpay: "UnionPay",
    wechatpay: "WeChat Pay",
    aplipay: "Alipay",
    footer:
      "Safe and efficient unboxing website, you can get the skin you want at a preferential price, if you have any questions, you can contact us,We will handle it for you as soon as possible"
  },
  helptabs: {
    trans_help: "Transaction help",
    comm_pro: "Common problem",
    conditions: "Conditions of Use",
    privacy_policy: "Privacy Policy",
    back: "Get back tutorial",
    bind: "Binding tutorial",
    novice: "Novice question"
  },
  recharge: {
    title: "Recharge",
    stitle:
      "In case of recharge problems, please change the payment method and try again, or enter the exchange group to give feedback",
    selecttitle: "Please select the amount",
    desc1:
      "In order to ensure that you can receive the prizes smoothly, please confirm whether this account is restricted by Steam before recharging.System transaction, please go to the Steam community market",
    desc2: " to viewTransaction security content of this account..",
    typetitle: "Please select mode of payment",
    type1: "Alipay",
    type2: "WeChat Pay",
    paymoney: "The amount actually paid:",
    select_desc: "Please select recharge amount",
    usedesc1: "Please use ",
    usedesc2: "Scan",
    usedesc3: "to complete the payment",
    wechat: "Wechat ",
    aplipay: "Alipay ",
    usedesc4: "The payment environment is safe,please rest assured to pay",
    type_desc: "About to open",
    tips: {
      t1: "Recharge success!"
    }
  },
  userinfo: {
    title: "Account Settings",
    invite_code: "Invite Code",
    update_btn: "Modify",
    sq: "Collapse",
    bind: "Binding",
    jbind: "Unbundling",
    toSteam: "Go to steam for transaction link",
    toSteam_desc:
      'Recommend using Netease UU to accelerate "steam community / store" for free',
    savebtn: "Save",
    tip: "success",
    identification: "Identification",
    enterRealName: "Please enter your real name",
    enterIdentification: "Please fill in your id number",
    identificationMsg: "Please complete the information",
    changeemail: {
      title: "Change email:",
      old_email_title: "Verify old email",
      code: "Please enter the 6-12digit verification code",
      new_email_title: "Please bind a new email",
      new_email: "Please enter your new email",
      next: "Next",
      btn: "Determine",
      tips: {
        t1: "Email has been sent to your email!",
        t2: "Please enter your new email!",
        t3: "Please enter the verification code!",
        t4: "Email replacement succeeded!"
      }
    },
    username: {
      title: "Nickname:",
      placeholder: "Please set your nickname",
      desc: "Please set your nickname",
      tips: {
        t1: "Please enter your nickname!"
      }
    },
    password: {
      title: "Login password:",
      desc: "Please set your password",
      old_placeholder: "Current password",
      new_placeholder: "Please enter your new password",
      conf_placeholder: "Confirm your new password",
      tips: {
        t1: "Please enter the current password!",
        t2: "The length of the password is between 6 and 12 bits",
        t3: "The two passwords are inconsistent!"
      }
    },
    steam: {
      title: "Bind Steam:",
      desc: "Please bind your Steam account",
      dialog: {
        title: "System prompt",
        content: "Are you sure you want to unbind steam?",
        no: "No",
        yes: "Yes"
      },
      tips: {
        t1: "Binding succeeded!",
        t2: "Steam unbound successfully!"
      }
    },
    link: {
      title: "Transaction link:",
      desc: "Please set transaction link",
      placeholder: "Please enter your transaction link",
      tips: {
        t1: "Please enter your transaction link!"
      }
    }
  },
  common: {
    vfx: "VFX",
    balance: "BALANCE:",
    sell: "EXCHANGE",
    warehouse: "IN THE WAREHOUSE",
    sellAll: "EXCHANGE ALL",
    explain: "INTRODUCTION TO GAMEPLAY",
    login: "You are not logged in! Log in now",
    confirm: "Confirm"
  },
  points: {},
  unpack: {
    record: "RECENTLY UNPACKED RECORD",
    title: "INCLUDED ORNAMENT",
    close: "CLOSE",
    open: "OPEN",
    animate: "ANIMATE",
    txt1: "You can use hotkeys",
    txt2: "Carry out quick unpacking",
    chooseNumber: "NUMBER",
    amount: "AMOUNT",
    handleSubmit: "DETERMINE THE OPENING",
    handleOpening: "OPENING...",
    rewardDetails: "ITEAMS OBTAINED IN THIS UPGRADE",
    time: "Time",
    player: "Player",
    getItems: "Get Skin",
    cost: "Cost"
  },
  upgrade: {
    title: "EQUIPMENT UPGRADE",
    explain: "INTRODUCTION TO GAMEPLAY",
    historyBtn: "UPGRADE HISTORY",
    upgradeAct: "CHOOSE YOUR ITEMS",
    probability: "UPGRADE PROBABILITY",
    wantAct: "CHOOSE THE ITEM YOU WANT",
    useBalance: "USE BALANCE",
    myBalance: "MY BALANCE",
    handleUpgrade: "UPGRADE",
    nowUpgrade: "UPGRADE NOW",
    handleTxt: "* THE HIGHEST PROBABILITY OF UPGRADING IS",
    myInventory: "MY INVENTORY",
    upgradeItem: "UPGRADE ITEMS",
    nameItem: "ITEMS NAME...",
    all: "All",
    category: "CATEGORY",
    price: "PRICE",
    priceRange: "PRICE RANGE",
    none: "YOU DON'T HAVE ANY IN INVENTORY",
    handleGo: "GO OPEN BOXS",
    rewardDetails: "RESULT OF THIS UPGRADE",
    handleReturn: "RETURN UPGRADE",
    handleSuccess: "UPGRADE SUCCESS",
    handleFaild: "UPGRADE FAILD",
    handleSuccessResult: "SUCCESS",
    handleFaildResult: "FAILD"
  },
  battle: {
    title: "BATTLE",
    explain: "INTRODUCTION TO GAMEPLAY",
    model1: "Box War",
    model2: "Top War",
    model3: "History War",
    createBtn: "CREATE",
    recordBtn: "My Record",
    rounds: "Rounds",
    result: "Result",
    totalWin: "Total Win",
    content: "BATTLE SCENARIO",
    type: "TYPE",
    cost: "COST",
    players: "PLAYERS",
    handle: "HANDLE",
    totalCost: "TOTAL VALUE：",
    handleAnimate: "Animation",
    handleWatch: "WATCH",
    handleReturn: "RETURN",
    handleJoin: "JOIN",
    readyStatus: "READY",
    handleWait: "WAITING",
    handleReady: "READY",
    running: "RUNNING",
    battle_end: {
      title: "THE RESULTS OF OUR BATTLE",
      endTxt: "AGAINST THE END",
      win: "WIN",
      box: "DETAILS",
      return: "RETURN",
      add: "CREATE"
    },
    endResultTxt: "TOTAL WIN",
    endWin: "WINNER!",
    endLose: "LOSER",
    battle_create: {
      createNew: "CREATE BATTLE",
      handleReturn: "RETURN",
      chooseTitle: "SELECT THE BOX",
      totalTitle: "THE TOTAL PRICE",
      t: "T",
      ct: "CT",
      handleClear: "CLEAN",
      userTotal: "PLAYER NUMBER",
      chooseTeam: "SELECT TEAM",
      handleCreate: "PAY TO CREATE",
      chosen: "CHOSEN",
      handleAdd: "ADD THE BOX",
      errorBoxs: "Do not exceed the box limit",
      errorBoxsZero: "Please add boxes",
      errorUserZero: "Please select the number of players"
    },
    battle_history: {
      return: "RETURN LIST",
      record: "MY RECORD"
    }
  },
  roll: {
    title: "ROLL",
    explain: "INTRODUCTION TO GAMEPLAY",
    model1: "All ROLL",
    model2: "ROLL to attend",
    model3: "Has ended",
    createBtn: "Create ROLL",
    roll_count_time: {
      d: "day",
      h: "hour",
      m: "minute",
      s: "second"
    },
    details: "ROLL Details"
  },
  rank: {
    title: "Royal",
    model1: "PK",
    model2: "Cases",
    model3: "Upgrade",
    holdRankTime: "Occupy chart time"
  },
  card: {
    title: "ROB CARD GOLD",
    model1: "ongoing",
    model2: "has ended",
    recordBtn: "my cards",
    return: "return list"
  },
  error: {
    d1:
      "Please check the page for errors or try refreshing to see if it can succeed",
    d2: "Oh, the interface is gone",
    d3: "Back to home"
  },
  trans,
  problem,
  conditions,
  privacy,
  pc,
  mobile,
  novice,
  steam
};
export default uk;
