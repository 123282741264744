
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath, sortOnPrice } from "@/assets/js/filter";
export default {
  name: "LevelReward",
  components: {},
  data() {
    return {
        levelVipConfigs: [],
        levelInfo:{},
        needNextLevelExp: 0,        
    };
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
    
  },
  mounted() {
    this.getVipList()
    this.getVipInfo()
  },
  methods: {
    getVipList(){
        // 获取VIP配置
        this.$getVipList({}).then((res)=>{
            if (!res.data) {
                return
            }
            this.levelVipConfigs = res.data.level_vip_configs
        }).catch((err)=>{

        })
    },
    getVipInfo(){
        this.$getVipInfo({}).then((res)=>{
            if (!res.data) {
                return
            }
            this.levelInfo = res.data.user
            this.needNextLevelExp = this.getNextLevelExpVal()
        }).catch((err)=>{

        })
    },
    getNextLevelExpVal(){
        let needExpVal = 0
        let curLevel = this.levelInfo.vip_level
        for (let index = 0; index < this.levelVipConfigs.length; index++) {
            const element = this.levelVipConfigs[index];
            if (curLevel < element.level) {
                needExpVal = (element.exp_val - this.levelInfo.vip_exp_val) / 10000
                break
            }
        }
        return needExpVal
    },
    reciveYesterdayReward(){
        this.$getVipYesterdayTurnoverReward({}).then((res)=>{
            if (!res.data) {
                return
            }
            let amount = (res.data.amount / 10000).toFixed(2)
            this.$store.dispatch("showMessagePopupFn", {
                type: 1,
                text: "成功领取"+ amount +"代币",
            });
            this.getVipInfo()
        }).catch((res)=>{

        })
    }
  },
};
