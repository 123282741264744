// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/images/goodCommon/bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{font-family:\"AlibabaPuHuiTi-Medium\"}#__nuxt{padding:10px;background-color:#151225;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% auto;background-position:top}.good_page{padding:110px 0 250px;position:relative;min-height:calc(100vh - 10px)}.nuxt_contanier{display:flex;justify-content:flex-end}.slide-right-enter-active,.slide-right-leave-active{transition:all .5s}.slide-right-enter,.slide-right-leave-to{opacity:0;transform:translateX(1%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
