
export default {
  name: "Dialog",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
