
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
export default {
  components:{
    ConfirmDialog
  },
  name: "PageIdentification",
  data() {
    return {
      form: {
        realname: "",
        identification: "",
        captcha: "",
      },
      loading: false,
      picPath: "",
      captcha_id: "",
    };
  },
  mounted() {
    this.loginVerify();
  },
  methods: {
    close() {
      this.$store.commit("SET_VISIBLE_ID_CARD", false);
    },
    handleConfirm() {
      if (!this.form.realname || !this.form.identification) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 2,
          text: this.$t("userinfo.identificationMsg"),
        });
        return
      }

      if (!this.form.captcha) {
          this.$store.dispatch("showMessagePopupFn", {
            // title: "登录提示",
            type: 2,
            text: "请输入图片验证码",
          });
          return;
        }
      let _sendData = {
        realname: this.form.realname,
        identification: this.form.identification,
        captcha: this.form.captcha,
        captcha_id: this.captcha_id,
      };
      this.loading = true
      this.$getIDCardStatus(_sendData).then(() => {
        this.$store.dispatch("showMessagePopupFn", {
          type: 1,
          text: "身份认证成功",
        });
        this.$store.dispatch("getUserInfo");
        this.$store.commit("SET_VISIBLE_ID_CARD", false);
      }).catch(() => {
        this.loading = false
        this.$store.commit("SET_LOADING", false);
        this.loginVerify();
      });
    },
    idCardYear(cardId) {
      var birthYear = cardId.substring(6, 10);
      var birthMonth = cardId.substring(10, 12);
      var birthDay = cardId.substring(12, 14);
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var age = year - birthYear;
      if (age > 18) {
        return true;
      } else {
        if (age < 18) {
          return false;
        } else {
          if (birthMonth > month) {
            return false;
          } else if (birthMonth < month) {
            return true;
          } else {
            if (birthDay <= day) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    },
    idCardValid(id) {
      // 1 "验证通过!", 0 //校验不通过
      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return { status: false, msg: "身份证号码不合规" };
      }
      //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;
      var year = id.substr(6, 4), //身份证年
        month = id.substr(10, 2), //身份证月
        date = id.substr(12, 2), //身份证日
        time = Date.parse(month + "-" + date + "-" + year), //身份证日期时间戳date
        now_time = Date.parse(new Date()), //当前时间戳
        dates = new Date(year, month, 0).getDate(); //身份证当月天数
      if (time > now_time || date > dates) {
        return { status: false, msg: "出生日期不合规" };
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
      var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return { status: false, msg: "身份证校验码不合规" };
      }
      return { status: true, msg: "校验通过" };
    },
    // 港
    isHKCard(card) {
      var reg = /^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(\([0−9aA]\)|[0-9aA])$/;
      if (reg.test(card) === false) {
        return { status: false, msg: "身份证号码不合规" };
      } else {
        return { status: true, msg: "校验通过" };
      }
    },
    // 澳
    isOMCard(card) {
      var reg = /^[1|5|7][0-9]{6}\([0-9Aa]\)/;
      if (reg.test(card) === false) {
        return { status: false, msg: "身份证号码不合规" };
      } else {
        return { status: true, msg: "校验通过" };
      }
    },
    // 台
    isTWCard(card) {
      var reg = /^[a-zA-Z][0-9]{9}$/;
      if (reg.test(card) === false) {
        return { status: false, msg: "身份证号码不合规" };
      } else {
        return { status: true, msg: "校验通过" };
      }
    },
    // 获得验证码
    loginVerify() {
      this.$getCaptcha({}).then((res) => {
        if (res.code == 200) {
          this.picPath = res.data.pic_path;
          this.captcha_id = res.data.captcha_id;
        }
      });
    },
  },
};
