const PC_cn = {
    title: 'PC端饰品取回教程',
    content: {
        title1: '温馨提示',
        span1_1: '1. 所有饰品0冷却，秒取回',
        span1_2: '2. 此平台需与您的Steam账号绑定',
        span1_3: '3. 在“正在取回”状态下，请您尽快前往Steam接受报价如果错过时间，您可以重新取回',
        span1_4: '4. 所有饰品以Steam市场价为基准，回收价格为市场价',
        span1_5: '5. 如遇暂时缺货的情况，请您稍后再尝试取回或者联系客服。饰品7天后未取回将会被系统全价回收',
        span1_6: '6. 如果有其他问题请联系客服',
        title2: '操作步骤：',
        span2_1: '1. 在进行以下操作前，',
        span2_1_1: '请保证您的Steam社区访问已经被成功加速',
        span2_1_2: '(推荐使用UU加速器免费加速“Steam社区/商店”)',
        span2_2: '2. 在游戏活动中心的“我的背包”点击饰品取回，过几分钟会在Steam界面上',
        span2_2_1: '您的头像框左边出现一个新消息图标',
        span2_2_2: '，点击这个图标',
        span2_3: '3. 在弹出的菜单中选择“',
        span2_3_1: '待处理的交易报价',
        span2_3_2: '”',
        span2_4: '4. 找到您需要取回的物品，然后点击“',
        span2_4_1: '回应报价',
        span2_4_2: '”按钮',
        span2_5: '5. 在弹出的窗口中，先选中“',
        span2_5_1: '准备好交易', 
        span2_5_2: '”按钮，然后点击“',
        span2_5_3: '接受交易',
        span2_5_4: '”按钮。',
        span2_6: '6. 完成以上步骤即可成功取回饰品，你学会了吗？'
    }
}
export default PC_cn