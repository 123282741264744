import { initWebSocket, wsJoinPlaza } from "@/plugins/webSocket";
import { deepClone } from "@/assets/js/filter";
import Vue from "vue";
export const state = () => ({
  isLogin: false, //是否登录
  visibleLogin: false, // 登录弹窗
  loginModel: 1, //默认展示的是登录
  freeDailyActive: false,  //充值送好券
  visibleRecharge: false, //显示充值
  visibleChange: false, //修改密码
  userinfo: null, //用户信息
  steamId: "", // steamId
  myMoney: 0, // 我的余额
  myStoreMoney: 0, // 我的商城余额
  myScore: 0, // 我的积分
  newMailCount: 0, // 新邮件数量
  langues: [
    // 国际化
    {
      en: "zh-CN",
      img: require("static/en/CN.png")
    },
    {
      en: "en-US",
      img: require("static/en/UK.png")
    },
    {
      en: "zh-TW",
      img: require("static/en/CN.png")
    }
  ],
  langue: {
    // 国际化
    en: "zh-CN",
    img: require("static/en/CN.png")
  },
  jewelryWear: [
    { key: "1", value: "崭新出厂" },
    { key: "2", value: "略有磨损" },
    { key: "3", value: "久经沙场" },
    { key: "4", value: "破损不堪" },
    { key: "5", value: "战痕累累" },
    { key: "6", value: "无涂装" },
  ],
  cardRoomStatus: [
    { key: 1, value: "进行中" },
    { key: 2, value: "已中奖" },
    { key: 3, value: "未中奖" },
  ],
  //邀请码
  invite_code: "",
  // 礼包
  visibleLucky: false,
  // 中奖
  visibleWin: false,
  // 注册提示
  visibleRegister: false,
  // vip弹窗
  visibleVip: false,
  // 身份弹窗
  visibleIDCard: false,
  // 身份验证
  isIdCard: true,
  // 实时出货记录
  deliveryList: [],
  // 需要登陆的页面数组
  needLoginArr: [],
  // 奖励历史记录
  normalRewardHistorys: [],
  // 大奖历史记录
  bigRewardHistorys: [],
  // 加载弹框
  loading: false,
  personOpenGameType: '', //个人开箱记录type
  personOpenGameUserId: '', //个人开箱用户id
  visibleCDK: false, // cdk兑换弹窗
  expand: true
});
export const getters = {
  getIsLogin(state) {
    return state.isLogin;
  },
  getNeedLoginArr(state) {
    return state.needLoginArr;
  },
  getVisibleRecharge(state) {
    return state.visibleRecharge;
  },
  getVisibleLogin(state) {
    return state.visibleLogin;
  },
  getLoginModel(state) {
    return state.loginModel;
  },
  getFreeDailyActive(state) {
    return state.freeDailyActive;
  },
  getVisibleChange(state) {
    return state.visibleChange;
  },
  getUserinfo(state) {
    return state.userinfo;
  },
  getMyMoney(state) {
    return state.myMoney;
  },
  getMyStoreMoney(state) {
    return state.myStoreMoney;
  },
  getMyScore(state) {
    return state.myScore;
  },
  getNewMailCount(state) {
    return state.newMailCount;
  },
  getLangue(state) {
    return state.langue;
  },
  getLangues(state) {
    return state.langues;
  },
  getJewelryWear(state) {
    return state.jewelryWear;
  },
  getCardRoomStatus(state) {
    return state.cardRoomStatus;
  },
  getInviteCode(state) {
    return state.invite_code;
  },
  getPersonOpenBoxId(state) {
    return state.personOpenGameType;
  },
  getPersonOpenGameUserId(state) {
    return state.personOpenGameUserId;
  },
  getVisibleLucky(state) {
    return state.visibleLucky;
  },
  getVisibleWin(state) {
    return state.visibleWin;
  },
  getVisibleRegister(state) {
    return state.visibleRegister;
  },

  getVisibleCDK(state) {
    return state.visibleCDK;
  },

  getVisibleVip(state) {
    return state.visibleVip;
  },
  getVisibleIDCard(state) {
    return state.visibleIDCard;
  },
  getLoading(state) {
    return state.loading;
  },
  getIsIdCard(state) {
    return state.isIdCard;
  },
  deliveryList(state) {
    return state.deliveryList;
  },
  getNormalRewardHistorys(state) {
    let normalRewardHistorys = deepClone(state.normalRewardHistorys)
    return normalRewardHistorys
  },
  getBigRewardHistorys(state) {
    let bigRewardHistorys = deepClone(state.bigRewardHistorys)
    return bigRewardHistorys
  }
};
export const mutations = {
  SET_EXPAND(state, value) {
    state.expand = value;
  },
  SET_IS_LOGIN(state, value) {
    state.isLogin = value;
  },
  SET_NEEDLOGINARR(state, value) {
    state.needLoginArr = value
  },
  SET_USER_INFO(state, value) {
    state.userinfo = value;
  },
  SET_MY_MONEY(state, value) {
    state.myMoney = value;
  },
  SET_MY_STORE_MONEY(state, value) {
    state.myStoreMoney = value;
  },
  SET_MY_SCORE(state, value) {
    state.myScore = value;
  },
  SET_NEW_MAIL_COUNT(state, value) {
    state.newMailCount = value;
  },
  SET_STEAM_ID(state, value) {
    state.steamId = value;
  },
  SET_IS_SHOW_RECHARGE_DIALOG(state, value) {
    state.visibleRecharge = value;
  },
  SET_LANGUE(state, value) {
    state.langue = value;
  },
  SET_LANGUES(state, value) {
    state.langues = value;
  },
  SET_VISIBLE_LOGIN(state, value) {
    state.visibleLogin = value;
  },
  SET_LOGIN_MODEL(state, value) {
    state.loginModel = value
  },
  SET_FREEDAILY_ACTIVE(state, value) {
    state.freeDailyActive = value
  },
  SET_VISIBLE_CHANGE(state, value) {
    state.visibleChange = value;
  },
  SET_INVITE_CODE(state, value) {
    state.invite_code = value;
  },
  SET_PERSON_OPEN_BOX(state, value) {
    state.personOpenGameType = value
  },
  SET_PERSON_USER_ID(state, value) {
    state.personOpenGameUserId = value
  },
  SET_VISIBLE_LUCKY(state, value) {
    state.visibleLucky = value;
  },
  SET_VISIBLE_WIN(state, value) {
    state.visibleWin = value;
  },
  SET_VISIBLE_REGISTER(state, value) {
    state.visibleRegister = value;
  },

  SET_VISIBLE_CDK(state, value) {
    state.visibleCDK = value;
  },

  SET_VISIBLE_VIP(state, value) {
    state.visibleVip = value;
  },
  SET_VISIBLE_ID_CARD(state, value) {
    state.visibleIDCard = value;
  },
  SET_LOADING(state, value) {
    console.log(value, 'loading----------------------------')
    state.loading = value;
  },
  SET_IS_ID_CARD(state, value) {
    state.isIdCard = value;
  },
  SET_DELIVERY_LIST(state, value) {
    const getName = function (el) {
      const layer = Number(el.exter);
      const getLayer = state.jewelryWear[layer < 1 ? 0 : layer - 1].value;
      const exter = layer ? ` (${getLayer})` : "";
      return `${el.code_name} | ${el.name}  ${exter}`;
    };
    value.forEach(item => {
      [item.result_data].forEach(el => {
        el.formatName = getName(el);
      });
    });
    state.deliveryList = value;
  },
  PUSH_NORMAL_REWARD_HISTORY(state, value) {
    if (!state.normalRewardHistorys) {
      state.normalRewardHistorys = []
    }
    let normalRewardHistorys = deepClone(state.normalRewardHistorys)
    normalRewardHistorys.push(value)
    Vue.set(state, "normalRewardHistorys", normalRewardHistorys);
  },
  CLEAR_NORMAL_REWARD_HISTORY(state, value) {
    state.normalRewardHistorys = value;
    Vue.set(state, "normalRewardHistorys", []);
  },
  PUSH_BIG_REWARD_HISTORY(state, value) {
    if (!state.bigRewardHistorys) {
      state.bigRewardHistorys = []
    }
    let bigRewardHistorys = deepClone(state.bigRewardHistorys)
    bigRewardHistorys.push(value)
    Vue.set(state, "bigRewardHistorys", bigRewardHistorys);
  },
  CLEAR_BIG_REWARD_HISTORY(state, value) {
    state.bigRewardHistorys = value;
    Vue.set(state, "bigRewardHistorys", []);
  }
};
export const actions = {
  // 登录判断
  checkIsLogin({ dispatch }) {
    if (this.$getCookies("Authorization")) {
      dispatch("getUserInfo");
      dispatch("getNewMailCount");
      //临时屏蔽
      // dispatch("getUserLucky");
      // dispatch("getPlayerWin");
    }
    if (
      process.browser &&
      location.search.indexOf("steamId=") > 0 &&
      !this.$getCookies("Authorization")
    ) {
      if (process.browser && location.pathname == "/userinfo") {
        //这里可能是绑定页面 此页面不登录
        return;
      }
      dispatch("getSteamId", location.search);
    }
    if (
      this.$getCookies("u_steam_id") &&
      !this.$getCookies("Authorization")
    ) {
      dispatch("steamLogin", this.$getCookies("u_steam_id"));
    }
  },
  // 获取SteamId
  getSteamId({ }, search) {
    // console.log(search);
    search = search.substring(1);
    let arr = search.split("&");
    for (let i = 0, len = arr.length; i < len; i++) {
      let item = arr[i].split("=");
      if (item[0] == "steamId") {
        this.$setCookies("u_steam_id", '"' + item[1] + '"');
        if (window.opener) {
          window.close();
          window.opener.location.reload();
        }
      }
    }
  },
  // steam登录
  steamLogin({ commit }, steam_id) {
    let params = {
      steam_id,
      nickname: "",
      avatar: "",
      login_type: 0
    };
    this.$steamLogin(params)
      .then(res => {
        if (res.code == 0) {
          // console.log(res);
          this.$setCookies("Authorization", res.data.api_token);
          this.$setCookies("userInfo", res.data);
          this.$setCookies("loginFlag", true);
          this.$setCookies("Login-Type", 0);
          commit("SET_USER_INFO", res.data);
          commit("SET_MY_MONEY", res.data.money / 10000);
          commit("SET_MY_STORE_MONEY", res.data.store_money / 10000);
          commit("SET_MY_SCORE", res.user.score_point);
          commit("SET_IS_LOGIN", true);
          this.$router.push("/");
        }
      })
      .catch(err => {
        reject(err);
      });
  },
  // 显示通知
  showMessagePopupFn({ }, message) {
    if (process.client) {
      this.$myMsg.notify({
        title: message.title,
        content: message.text,
        type: message.type,
        time: message.time,
        userName: message.userName,
        avatar: message.avatar,
        itemImg: message.itemImg,
        itemPrice: message.itemPrice
      });
    }
  },
  // 获取用户信息
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      this.$getUserInfo()
        .then(res => {
          res.data.user_info.created_at = res.data.created_at;
          res.data.user_info.invite_user_id = res.data.invite_user_id;
          res.data.user_info.super_invite_code = res.data.super_invite_code;
          res.data.user_info.real_name = res.data.real_name;
          res.data.user_info.can_modify_invite_code = res.data.can_modify_invite_code;
          res.data.user_info.is_set_trade_password = res.data.is_set_trade_password;
          resolve(res.data.user_info);
          this.$setCookies("UserInfo", res.data.user_info);
          this.$setCookies("UserID", res.data.user_info.user_id);
          console.log(res.data.user_info);
          commit("SET_IS_LOGIN", true);
          commit("SET_USER_INFO", res.data.user_info);
          commit("SET_IS_ID_CARD", res.data.real_name == "" ? false : true);
          commit("SET_MY_MONEY", res.data.user_info.money / 10000);
          commit("SET_MY_STORE_MONEY", res.data.user_info.store_money / 10000);
          // 连接ws
          wsJoinPlaza();
        })
        .catch(err => {
          reject(err);
        });
    });
  },



  // 获取新邮件数量
  getNewMailCount({ commit }) {

    return new Promise((resolve, reject) => {
      this.$getNewMailCount()
        .then(res => {
          if (res.code == 200) {
            commit("SET_NEW_MAIL_COUNT", res.data.new_mail_count);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取礼包
  getUserLucky({ commit }) {
    // return new Promise((resolve, reject) => {
    //   this.$getLuckyBagStatus()
    //     .then(res => {
    //       commit("SET_VISIBLE_LUCKY", res.has_bag);
    //     })
    //     .catch(err => {
    //       reject(err);
    //     });
    // });
  },
  // 判断是否中奖
  getPlayerWin({ commit }) {
    // return new Promise((resolve, reject) => {
    // this.$getLuckyBagStatus({ type: 2 })
    //   .then(res => {
    //     commit("SET_VISIBLE_WIN", res.has_bag);
    //     // commit("SET_VISIBLE_WIN", true);
    //   })
    //   .catch(err => {
    //     reject(err);
    //   });
    // });

  },
  // 登出
  signOut({ commit }, value) {
    this.$removeCookiesAll();
    commit("SET_USER_INFO", {});
    commit("SET_MY_MONEY", 0);
    commit("SET_MY_STORE_MONEY", 0);
    commit("SET_MY_SCORE", 0);
    commit("SET_NEW_MAIL_COUNT", 0);
    commit("SET_IS_LOGIN", false);
    commit("SET_VISIBLE_LUCKY", false);
    commit("SET_VISIBLE_WIN", false);
    if (value) {
      this.$router.push("/");
    }
    if (process.browser) {
      initWebSocket();
    }
  },
  // 国际化语言
  langue({ commit }) {
    if (this.$getCookies("langue")) {
      commit("SET_LANGUE", this.$getCookies("langue"));
    }
  },
  // 获取余额
  getUserMoney({ commit }) {
    return new Promise((resolve, reject) => {
      this.$myGold()
        .then(res => {
          if (res.code == 200) {
            commit("SET_MY_MONEY", res.data.std_money / 10000);
            commit("SET_MY_STORE_MONEY", res.data.store_money / 10000);
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 全部放弃
  sellAction({ dispatch }, idArr) {
    let ids = {
      _id: idArr
    };
    return new Promise((resolve, reject) => {
      this.$postSell(ids)
        .then(res => {
          if (res.code == 0) {
            dispatch("getUserMoney");
            resolve();
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  // getDeliveyList({ commit }) {
  //   this.$getSucceedList().then(res => {
  //     if (res.code == 0) {
  //       commit("SET_DELIVERY_LIST", res.data);
  //     }
  //   });
  // }
};
