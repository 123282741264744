
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath } from "@/assets/js/filter";
import Dialog from "../common/Dialog.vue";
export default {
  name: "messageBar",
  components: {
    Dialog,
  },
  data() {
    return {
      exiting: true,
      selcetStatus: {
        value: "all",
        label: "全部",
      },
      tabBar: [
        {
          value: "all",
          label: "全部",
        },
        {
          value: "read",
          label: "已读",
        },
        {
          value: "unread",
          label: "未读",
        },
      ],
      tableData: [],
      page: {
        page_size: 10,
        page_no: 1,
        totalPage: 1,
      },
      showDialog: false,
      clickMail: {},
      noMore: false,
    };
  },
  computed: {
    ...mapGetters(["getJewelryWear", "getNewMailCount", "getIsLogin"]),
    realList() {
      if (this.selcetStatus.value == "read") {
        return this.tableData.filter((item) => item.mail_status === 2);
      } else if (this.selcetStatus.value == "unread") {
        return this.tableData.filter((item) => item.mail_status === 1);
      }
      return this.tableData;
    },
  },
  mounted() {
    if (this.getIsLogin) {
      this.getMails();
    }
  },
  methods: {
    show() {
      this.page.page_no = 1;
      this.tableData = [];
      this.getMails();
    },
    markRead(item) {
      if (item.mail_status == 1) {
        let _sendData = {
          mail_id: item.mail_id,
        };
        this.$markReadMail(_sendData).then((res) => {
          if (res.code == 200) {
            item.mail_status = 2;
            this.setCount();
          }
        });
      }
    },
    setCount() {
      let count = this.getNewMailCount;
      count--;
      this.$store.commit("SET_NEW_MAIL_COUNT", count);
    },
    markReadAll() {
      this.tableData.forEach((item) => {
        //只能标记通知邮件，奖励邮件需用户点击领取
        if (item.mail_type !== 1 && item.mail_status === 1) {
          this.markRead(item);
          this.setCount();
        }
      });
    },
    checkMail(item) {
      this.clickMail = item;
      this.showDialog = true;
    },
    closeDialog() {
      this.clickMail = {};
      this.showDialog = false;
    },
    handleCheckStatus(item) {
      this.selcetStatus = item;
    },
    formatDate(_value) {
      return formatDate(_value);
    },
    formatCdnPath(_path) {
      return fromatCDNPath(_path);
    },
    handleScroll() {
      if (this.page.page_no >= this.page.totalPage) {
        this.noMore = true;
        return;
      }
      const scrollContainer = this.$refs.listBox;
      //确保只有在有数据时滚动才会触发
      if (
        scrollContainer.scrollHeight - scrollContainer.scrollTop ===
          scrollContainer.clientHeight &&
        this.tableData.length
      ) {
        // 触发翻页函数
        this.page.page_no++;
        this.getMails();
      }
    },
    getMails() {
      if (this.page.page_no == 1) {
        this.tableData = [];
      }
      let _sendData = {
        page_no: this.page.page_no,
        page_size: this.page.page_size,
        mail_type: 0, // 邮件类型 0:全部 1:系统奖励邮件 2:系统通知邮件
      };
      this.$getMails(_sendData)
        .then((res) => {
          if (res.code == 200) {
            let _infos = res.data.infos || [];
            _infos.map((info) => {
              info.appendix_infos = info.appendix_infos || [];
              info.appendix_infos.map((info) => {
                if (info.appendix_type === 3) {
                  info.formatName = info.item_name;
                } else {
                  if (info.item_name == "") {
                    info.formatName = "";
                  } else {
                    info.formatName =
                      info.code_name +
                      " | " +
                      info.item_name +
                      (!Number(info.exterior)
                        ? ""
                        : " (" +
                          this.getJewelryWear[
                            parseInt(info.exterior) - 1 < 0
                              ? 0
                              : parseInt(info.exterior) - 1
                          ].value +
                          ")");
                  }
                }
              });
            });
            this.tableData = this.tableData.concat(_infos);
            let totalPage = parseInt(
              res.data.page_result.total_count / this.page.page_size
            );
            if (res.data.page_result.total_count % this.page.page_size > 0) {
              totalPage = totalPage + 1;
            }
            if (!totalPage) {
              totalPage = 1;
            }
            this.page.totalPage = totalPage;
            this.noMore = this.page.page_no >= this.page.totalPage;
          }
        })
        .catch(() => {});
    },
    getIsReceive(item) {
      if (!item) {
        // 数据有误
        return [false, "数据有误，请联系管理员"];
      }
      if (item.mail_type == 1 && item.mail_status == 2) {
        // 有附件已领取
        return [false, "已领取该附件"];
      }
      if (item.appendix_is_expired == true) {
        // 附件已过期
        return [false, "附件已过期，无法领取了"];
      }
      if (!item.appendix_infos || item.appendix_infos.length <= 0) {
        // 没有附件数据
        return [false, "没有附件信息，无法领取"];
      }
      return [true, ""];
    },
    // 领取
    handleConfirm(item) {
      let result = this.getIsReceive(item);
      let is_receive = result[0]
      let msg = result[1]
      if (is_receive) {
        item.appendix_is_expired = true;
        // 发送领取消息
        let _sendData = {
          mail_id: item.mail_id,
        };
        this.$receiveMailAppendix(_sendData).then((res) => {
          if (res.code == 200) {
            this.setCount();
            this.$store.dispatch("showMessagePopupFn", {
              type: 1,
              text: "领取成功，请到背包中查看",
            });
            this.page.page_no == 1;
            this.getMails();
            setTimeout(() => {
              this.$store.dispatch("getUserMoney");
            }, 1000);
          } else {
            this.$store.dispatch("showMessagePopupFn", {
              type: 0,
              text: "领取失败，请联系管理员",
            });
          }
        });
      }else{
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: msg,
        });
      }
    },
  },
};
