import Vue from "vue";
import { setStore, getStore } from '@/plugins/store'
import { deepClone, assemblyBox } from "@/assets/js/filter";
let timer = null;
let timer1 = null;
let timer2 = null;

export let clearBattleTime = () => {
  timer && clearInterval(timer)
  timer1 && clearTimeout(timer1);
  timer2 && clearTimeout(timer2);
  timer = null;
  timer1 = null;
  timer2 = null;
};
const state = () => ({
  websocket_ready: false,
  battle_status: false,
  client_id: null,
  room: null,
  sit_users: [],
  rooms: [],
  history: null,
  historyRooms: [],
  box_is_num: 0,
  box_nums: [],
  users_rewards: [],
  battle_boxs: {
    box0: [],
    box1: [],
    box2: [],
    box3: []
  },
  scroll_status: true,
  current_reward: [{ num: 0 }, { num: 0 }, { num: 0 }, { num: 0 }],
  battle_complete: false,
  end: [],
  guest: true,
  robot: [],
  record: [],
  record_details: null,
  pic: true,
  red_bag_visible: false,
  red_bag_info: null,
  red_bag_result: null,
  user_red_bag_status: true,
  emoji: [
    { visible: false, direction: true, tag: "1", img: "" },
    { visible: false, direction: true, tag: "1", img: "" },
    { visible: false, direction: true, tag: "1", img: "" },
    { visible: false, direction: true, tag: "1", img: "" }
  ],
  emojiList: [],
  emojiEnd: true,
  watch: 0,
  battle__box__info: [],
  deliveryRecord: null,
  activeGood: {},
  endList: [],
  redBagRefreshApi: null,
  currentRoomId: null,
  showBattleEnd: false,
  battleReport: [],
  actPkpowerCreateRoom: null, // 五一活动开始时收到后台的推送
  maydayScroll: false, // 五一活动是否滚动
});
const getters = {
  // 批量创建的房间时，房间信息返回先后顺序不准确，不建议使用
  // getEndlist(state){
  //   return state.endList;
  // },
  getCurrentRoomId(state) {
    return state.currentRoomId;
  },
  getShowBattleEnd(state) {
    return state.showBattleEnd;
  },
  getWebSocket(state) {
    return state.websocket_ready;
  },
  getBattleStatus(state) {
    return state.battle_status;
  },
  getClientID(state) {
    return state.client_id;
  },
  getRoom(state) {
    return state.room;
  },
  getRoomBoxIsNum(state) {
    if (state.room != null) {
      return state.room.box_is_num
    }
  },
  getSitUsers(state) {
    return state.sit_users;
  },
  getRooms(state) {
    return state.rooms;
  },
  getHistory(state) {
    return state.history;
  },
  getHistoryRooms(state) {
    return state.historyRooms;
  },
  getBoxIsNum(state) {
    return state.box_is_num;
  },
  getBoxNums(state) {
    return state.box_nums;
  },
  getBattleBoxs(state) {
    return state.battle_boxs;
  },
  getUsersRewards(state) {
    return state.users_rewards;
  },
  getScrollStatus(state) {
    return state.scroll_status;
  },
  getCurrentReward(state) {
    return state.current_reward;
  },
  getBattleComplete(state) {
    return state.battle_complete;
  },
  getEnd(state) {
    return state.end;
  },
  getGuest(state) {
    return state.guest;
  },
  getRobot(state) {
    return state.robot;
  },
  getRecord(state) {
    return state.record;
  },
  getRecordDetails(state) {
    return state.record_details;
  },
  getPic(state) {
    return state.pic;
  },
  getRedBagVisible(state) {
    return state.red_bag_visible;
  },
  getRedBagInfo(state) {
    return state.red_bag_info;
  },
  getRedBagResult(state) {
    return state.red_bag_result;
  },
  getUserRedBagStatus(state) {
    return state.user_red_bag_status;
  },
  getEmoji(state) {
    return state.emoji;
  },
  getEmojiList(state) {
    return state.emojiList;
  },
  getEmojiEnd(state) {
    return state.emojiEnd;
  },
  getWatch(state) {
    return state.watch;
  },
  getBattleBoxInfo(state) {
    return state.battle__box__info;
  },
  getDeliveyRecord(state) {
    return state.deliveryRecord;
  },
  getRoyal(state) {
    return state.royal;
  },
  getActiveGood(state) {
    let obj = getStore({ name: 'activeGood' }) || {}
    return (state.activeGood.id == undefined ? obj : state.activeGood);
  },
  getStateRoomScrollStatus(state) {
    if (state.room != null) {
      return state.room.scroll_status;
    }
  },

  getStateRoomBattleBox(state) {
    if (state.room != null) {
      return state.room.battle_box;
    }
  },
  getStateRoomBattleStatus(state) {
    if (state.room != null) {
      return state.room.battle_status;
    }
  },

  getStateRoomBattleEnd(state) {
    if (state.room != null) {
      return state.room.battle_end;
    }
  },
  getRedBagRefreshApi(state) {
    return state.redBagRefreshApi;
  },
  getBattleReport(state) {
    return state.battleReport;
  },
  getActPkpowerCreateRoom(state) {
    return state.actPkpowerCreateRoom;
  },
  getMaydayScroll(state) {
    return state.maydayScroll;
  }
};
const mutations = {
  INIT_ROOM_TRANS(state){
    state.rooms.map((item) => {
      item.translateIndex = 0;
      item.translateX = 0;
      return item;
    });
  },
  SET_ROOM_TRANS(state, {item, index, status}) {
    if (status) {
      if (item.translateIndex < item.total_Round - 4) {
        state.rooms[index].translateIndex += 1;
        state.rooms[index].translateX = -(
          state.rooms[index].translateIndex * 79
        );
        // console.error(state.rooms[index].translateIndex)
      }
    } else {
      if (item.translateIndex > 0) {
        state.rooms[index].translateIndex -= 1;
        state.rooms[index].translateX = -(
          state.rooms[index].translateIndex * 79
        );
      }
    }
  },

  SET_CURRENT_ROOM_ID(state, value) {
    state.currentRoomId = value;
  },
  SET_SHOW_BATTLE_END(state, value) {
    state.showBattleEnd = value;
  },
  SET_WEBSOCKET_READY(state, value) {
    state.websocket_ready = value;
  },
  SET_BATTLE_STATUS(state, value) {
    state.battle_status = value;
  },
  SET_CLIENT_ID(state, value) {
    state.client_id = value;
  },
  SET_ROOM(state, value) {
    state.room = value;
  },
  //更新房间USER
  SET_ROOM_UPDATE_USER(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        let _data = state.room;
        _data.members[value.position] = value.user
        Vue.set(state.room, "members", {
          ..._data.members
        });
      }
    }
  },
  //更新房间battle状态
  SET_ROOM_BATTLE_STATUS(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        state.room.battle_status = value.battle_status;
      }
    }
  },
  //更新房间结果
  SET_ROOM_UPDATE_REWARD(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        state.room.cur_round = value.box_is_num;
        let _reward = value.round_reward;
        let _rewardData = {};
        for (let key in _reward) {
          let flagItems = []
          flagItems = _reward[key].user_item_id.slice(0, value.box_is_num + 1).map((t) => {
            return value.reward[t]
          })
          let getNowAll = _reward[key].user_item_id.slice(0, value.box_is_num).map((t) => {
            return value.reward[t]
          })
          let allMoney = getNowAll.map(item => {
            return (value.box_info[item.box_id].items.find(t => {
              if (item.item_id == t.item_id) {
                return item
              }
            })).cost_price
          })
          _rewardData[key] = {
            items: flagItems,
            user_id: key,
            open_all_price: allMoney.length ? allMoney.reduce((prev, curr) => prev + curr) : 0
          }
        }
        Vue.set(state.room, "_reward", {
          ..._rewardData
        });
      }
    }
  },
  //更新开箱代币结果
  SET_ROOM_UPDATE_REWARD_MONEY(state, value) {
    if (state.room != null) {
      for (let key in state.room._reward) {
        if (key == value.key) {
          state.room._reward[key].open_all_price = value.open_all_price
        }
      }
    }
  },
  // 设置每局箱子
  SET_ROOM_BATTLE_BOX(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        var boxsList = state.room.boxsList;
        let boxs = {};
        let index = 0;
        for (var _key in value.round_reward) {
          let _v = value.round_reward[_key].user_item_id.slice(0, value.box_is_num + 1).map((t) => {
            return value.reward[t]
          });
          let _endrewardInfo = _v[_v.length - 1];
          boxs[_key] = [];
          var _boxsList = boxsList[_endrewardInfo.box_id];

          let time = new Date();
          let sec = time.getSeconds().toString();
          let random = Number(sec.charAt(sec.length - 1));
          let listIndex = Number(index) + random;
          for (let i = 0; i < 29; i++) {
            listIndex += 1;
            let num = _boxsList.length % listIndex;
            if (num > _boxsList.length - 1) {
              num = Number(index);
            }
            boxs[_key].push(_boxsList[num]);
            if (listIndex > _boxsList.length - 1) {
              listIndex = Number(index) + random;
            }
          }

          let _oneItemInfo = _boxsList.find(x => x.item_id === _endrewardInfo.item_id)
          boxs[_key].unshift(_oneItemInfo);
          index++
        }

        Vue.set(state.room, "battle_box", {
          ...boxs
        });
      }
    }
  },
  //更新房间battleEnd信息
  SET_ROOM_BATTLE_END(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        state.room.battle_end = value.battle_end;
      }
    }
  },
  SET_ROOM_SCROLL_STATUS(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        state.room.scroll_status = value.scroll_status;
      }
    }
  },

  SET_ROOM_BOX_IS_NUM(state, value) {
    if (state.room != null) {
      if (value.room_id == state.room.room_id) {
        if (value.box_is_num == 10000) {
          state.room.box_is_num++;
        } else {
          state.room.box_is_num = value.box_is_num;
        }
      }
    }
  },
  SORT_ROOMS_DATA(state) {
    let _state_1 = []; //等待中
    let _state_2 = []; //比武中
    let _state_3 = [];  //已结束
    let _state_Temp = [];  //最终数组
    let _rooms = state.rooms;
    if (_rooms != null) {
      _rooms.map(item => {
        switch (item.room_state) {
          case 1:
            _state_1.push(item);
            break;
          case 2:
            _state_2.push(item);
            break;
          case 3:
            _state_3.push(item);
            break;
        }
      })
    }
    if (_state_1.length > 0) {
      _state_1.sort(function (a, b) {
        return b.created_at - a.created_at
      })
      _state_Temp = _state_Temp.concat(_state_1);
    }

    if (_state_2.length > 0) {
      _state_2.sort(function (a, b) {
        return b.created_at - a.created_at
      })
      _state_Temp = _state_Temp.concat(_state_2);
    }

    if (_state_3.length > 0) {
      _state_3.sort(function (a, b) {
        return b.updated_at - a.updated_at
      })
      _state_Temp = _state_Temp.concat(_state_3);
    }
    state.rooms = _state_Temp;
  },
  SET_ROOMS(state, value) {
    state.rooms = value;
  },
  SET_ROOMS_RIGHT(state, value) {
    value.translateX -= 160;
    value.translateIndex += 1;
  },
  SET_ROOMS_LEFT(state, value) {
    value.translateX += 160;
    value.translateIndex -= 1;
  },
  SET_HISTORY(state, value) {
    state.history = value;
  },
  SET_ADD_HISTORY_ROOMS(state, value) {
    if (state.historyRooms.length > 49) {
      state.historyRooms.unshift(value);
      state.historyRooms.splice(state.historyRooms.length - 1, 1);
    } else {
      state.historyRooms.unshift(value);
    }
  },
  SET_HISTORY_ROOMS(state, value) {
    state.historyRooms = value;
  },
  SET_HISTORY_ROOMS_RIGHT(state, value) {
    value.translateX -= 160;
    value.translateIndex += 1;
  },
  SET_HISTORY_ROOMS_LEFT(state, value) {
    value.translateX += 160;
    value.translateIndex -= 1;
  },
  SET_ROOMS_ADD(state, value) {
    state.rooms.unshift(value);
  },
  //更新房间room_state 状态为4时删除房间
  SET_ROOMS_UPDATE_STATE(state, value) {
    // console.log("更新房间room_state => ",state.rooms);
    let index = -1;
    index = state.rooms.findIndex(
      item => item.room_id === value.room_id
    );
    console.log("option update room_state ------> ", value.room_id, " ------> ", value.room_state);
    if (index != -1) {
      if (value.room_state == 4) {
        state.rooms.splice(index, 1);
      } else {
        Vue.set(state.rooms[index], "room_state", value.room_state);
        if (value.room_state == 3) {
          Vue.set(state.rooms[index], "members", value.room_data.members);
          Vue.set(state.rooms[index], "result", value.room_data.result);
        }
      }
    }
  },
  SET_ROOMS_UPDATE(state, value) {
    let index = -1;
    index = state.rooms.findIndex(
      item => item.roomInfo.room_id === value.roomInfo.room_id
    );
    if (index != -1) {
      Vue.set(state.rooms, index, {
        ...value,
        translateIndex: state.rooms[index]
          ? state.rooms[index].translateIndex
          : 0,
        translateX: state.rooms[index] ? state.rooms[index].translateX : 0
      });
    }
  },

  SET_ROOMS_DELETE(state) {
    if (state.rooms.length > 48) {
      console.log("option delete ------->", state.rooms[state.rooms.length - 1].room_id, "room_state------->", state.rooms[state.rooms.length - 1].room_state)
      if (state.rooms[state.rooms.length - 1].room_state == 3) {
        state.rooms.splice(state.rooms.length - 1, 1);
      }
    }
  },

  SET_ROOMS_DESTORY(state, value) {
    state.endList = deepClone(value.data)
  },

  SET_ROOMS_UPDATE_USER(state, value) {
    let index = -1;
    index = state.rooms.findIndex(
      item => item.room_id === value.room_id
    );
    console.log("option update user--->", value.room_id, "------>", value.user)
    if (index != -1) {
      let _temprRooms = state.rooms[index];
      _temprRooms.members[value.position] = value.user;
      Vue.set(state.rooms, index, {
        ..._temprRooms
      });
    }
    // console.log("SET_ROOMS_UPDATE_USER",state.rooms)
  },
  SET_ROOMS_UPDATE_ROUND(state, value) {
    let index = -1;
    index = state.rooms.findIndex(
      item => item.room_id === value.room_id
    );
    if (index != -1) {
      let _temprRooms = state.rooms[index];
      _temprRooms.cur_round = value.box_is_num;
      Vue.set(state.rooms, index, {
        ..._temprRooms
      });
    }
  },
  SET_SIT_USERS(state, value) {
    state.sit_users = value;
  },
  SET_BOX_IS_NUM(state, value) {
    state.box_is_num = value;
  },
  SET_BOX_NUMS(state, value) {
    state.box_nums = value;
  },
  SET_BATTLE_BOXS(state, value) {
    state.battle_boxs = value;
  },
  SET_SCROLL_STATUS(state, value) {
    state.scroll_status = value;
  },
  SET_USERS_REWARDS(state, value) {
    state.users_rewards = value;
  },
  SET_CURRENT_REWARD(state, value) {
    state.current_reward = value;
  },
  SET_BATTLE_COMPLETE(state, value) {
    state.battle_complete = value;
  },
  SET_BATTLE_END(state, value) {
    state.end = value;
  },
  SET_GUEST(state, value) {
    state.guest = value;
  },
  SET_ROBOT(state, value) {
    state.robot = value;
  },
  SET_RECORD(state, value) {
    state.record = value;
  },
  SET_RECORD_DETAILS(state, value) {
    state.record_details = value;
  },
  SET_RECORD_RIGHT(state, value) {
    value.translateX -= 120;
    value.translateIndex += 1;
  },
  SET_RECORD_LEFT(state, value) {
    value.translateX += 120;
    value.translateIndex -= 1;
  },
  SET_PIC(state) {
    state.pic = !state.pic;
  },
  SET_RED_BAG_VISIBLE(state, value) {
    state.red_bag_visible = value;
  },
  SET_RED_BAG_INFO(state, value) {
    state.red_bag_info = value;
  },
  SET_RED_BAG_RESULT(state, value) {
    state.red_bag_result = value;
  },
  SET_USER_RED_BAG_STATUS(state, value) {
    state.user_red_bag_status = value;
  },
  SET_EMOJI(state, value) {
    if (state.room != null) {
      if (value.data.room_id == state.room.room_id) {
        let index = -1;
        for (let _key in state.room.members) {
          if (state.room.members[_key].user_id == value.data.data.user_id) {
            index = _key;
          }
        }
        let _Path = "";
        if (state.emojiList.length > 0) {
          state.emojiList.forEach(item => {
            if (item.tag == value.data.data.tag) {
              _Path = item.img;
            }
          });
        }
        if (index != -1) {
          Vue.set(state.emoji, index - 1, {
            visible: true,
            direction: value.data.data.direction,
            tag: value.data.data.tag,
            img: _Path
          });
        }
      }
    }
    //  console.log("state.emoji",state.emoji)
    //  console.log("value",value)
  },
  SET_EMOJILIST(state, value) {
    state.emojiList = value;
  },
  SET_EMOJI_REDUCTION(state, value) {
    if (state.room != null) {
      if (value.data.room_id == state.room.room_id) {
        let index = -1;
        for (let _key in state.room.members) {
          if (state.room.members[_key].user_id == value.data.data.user_id) {
            index = _key;
          }
        }
        if (index != -1) {
          Vue.set(state.emoji[index - 1], "visible", false);
        }
      }
    }
    else {
      state.emoji.forEach(item => {
        item.visible = false;
      })
    }
  },
  SET_EMOJI_END(state, value) {
    state.emojiEnd = value;
  },
  SET_WATCH(state, value) {
    state.watch = value;
  },
  SET_BATTLE_BOX_INFO(state, value) {
    state.battle__box__info = value;
  },
  SET_DELIVERY_RECORD(state, value) {
    state.deliveryRecord = value
  },
  SET_ROYAL(state, value) {
    state.royal = value;
  },
  SET_ACTIVEGOOD(state, value) {
    state.activeGood = value
    setStore({
      name: 'activeGood',
      content: value
    })
  },
  SET_REDBAG_REFRESHAPI(state, value) {
    state.redBagRefreshApi = value;
  },
  SET_BATTLE_REPORT(state, value) { // 比武战报处理
    state.battleReport.unshift(value)
    if (state.battleReport.length > 13) {
      state.battleReport.pop()
    }
  },
  SET_ACTPK_POWERCREATEROOM(state, value) {
    state.actPkpowerCreateRoom = value;
  },
  SET_MAYDAY_SCROLL(state, value) {
    state.maydayScroll = value;
  },
};
const actions = {
  reduction({ commit }) {
    commit("SET_BATTLE_STATUS", false);
    commit("SET_ROOM", null);
    commit("SET_SIT_USERS", []);
    commit("SET_BOX_IS_NUM", 0);
    commit("SET_BOX_NUMS", []);
    commit("SET_USERS_REWARDS", []);
    commit("SET_BATTLE_BOXS", {
      box0: [],
      box1: [],
      box2: [],
      box3: []
    });
    commit("SET_SCROLL_STATUS", true);
    commit("SET_CURRENT_REWARD", [
      { num: 0 },
      { num: 0 },
      { num: 0 },
      { num: 0 }
    ]);
    commit("SET_BATTLE_COMPLETE", false);
    commit("SET_BATTLE_END", []);
    commit("SET_EMOJI_REDUCTION", "");
    commit("SET_EMOJI_END", true);
    commit("SET_WATCH", 0);
  },
  GetPkRoomData({ commit }, paramsData) {
    return new Promise((resolve, reject) => {
      console.log(paramsData);
      this.$GetPkRoomData(paramsData._sendData)
        .then(res => {
          let _data = res.data.data;
          let nowDate = res.data.now;
          _data.battle_status = _data.cur_round > 0 ? true : false;
          _data.scroll_status = false;
          _data.battle_complete = false;
          _data.battle_box = {};
          _data.battle_end = false;
          _data.now = res.data.now;
          // 比武改版后让列表可重复使用
          _data.boxes = _data.boxes.map(t => {
            return { box_id: t }
          })

          //重组饰品列表信息
          let boxsList = {};
          let itemList = [];
          for (var a in _data.box_info) {
            let _tt = _data.box_info[a];
            itemList = [];
            for (let _i = 0; _i < _tt.items.length; _i++) {
              itemList.push(_tt.items[_i]);
            }
            boxsList[_tt.box_id] = [];

            if (itemList.length < 30) {
              let num = parseInt(30 / itemList.length);
              if (30 % itemList.length > 0) {
                num = num + 1;
              }
              for (let i = 0; i < num; i++) {
                boxsList[_tt.box_id] = boxsList[_tt.box_id].concat(itemList);
              }
            } else {
              boxsList[_tt.box_id] = boxsList[_tt.box_id].concat(itemList);
            }
          }
          _data.boxsList = boxsList;

          // 计算当前开到第几个箱子 按5s一个箱子计算
          // 先清空所有计时器
          clearBattleTime()
          // 每次开始不让弹出结算弹窗
          commit("SET_SHOW_BATTLE_END", false)
          // 进行中处理数据
          let startFunc = (data1) => {
            let newData = deepClone(data1)
            newData.box_is_num++
            commit("SET_ROOM_BATTLE_STATUS", { room_id: data1.room_id, battle_status: true });
            commit("SET_ROOM_BATTLE_BOX", data1);
            commit("SET_ROOMS_UPDATE_ROUND", data1);
            commit("SET_ROOM_UPDATE_REWARD", data1);
            commit("SET_ROOM_BOX_IS_NUM", { room_id: data1.room_id, box_is_num: newData.box_is_num });
            let _scrollStatusValue = {
              room_id: data1.room_id,
              scroll_status: false
            }
            commit("SET_ROOM_SCROLL_STATUS", _scrollStatusValue);
            timer1 = setTimeout(() => {
              let _scrollStatusValueA = {
                room_id: data1.room_id,
                scroll_status: true
              }
              commit("SET_ROOM_SCROLL_STATUS", _scrollStatusValueA);
            }, 1000);
          }
          // 结束后处理数据
          let endFunc = (data1) => {
            commit("SET_ROOM_BOX_IS_NUM", { room_id: data1.room_id, box_is_num: 10000 });
            let _battleEndValue = {
              room_id: data1.room_id,
              battle_end: true
            }
            commit("SET_ROOM_BATTLE_END", _battleEndValue);
            commit("SET_EMOJI_END", false);
            commit("SET_ROOMS_UPDATE_STATE", {
              room_id: data1.room_id,
              room_state: 3,
              room_data: data1
            });
            commit("SORT_ROOMS_DATA");
            commit("SET_ROOMS_DESTORY", data1);
            commit("SET_ROOMS_DELETE");
          }
          if (_data.started_at == 0) { // 未开始
            _data.box_is_num = 0;
          } else {
            let timeDifference = nowDate - _data.started_at
            let openAllBoxTime = _data.total_Round * 5
            if (openAllBoxTime > timeDifference) { // 进行中
              commit("SET_SHOW_BATTLE_END", true)
              let curIndex = timeDifference / 5
              let cur_round = parseInt(curIndex)
              _data.box_is_num = cur_round;
              timer2 = setTimeout(() => {
                startFunc(_data)
                timer = setInterval(() => {
                  cur_round++
                  if (cur_round + 1 > _data.boxes.length) {
                    clearBattleTime()
                    endFunc(_data)
                    return
                  }
                  startFunc(_data)
                }, 5 * 1000)
              }, 0)
            } else { // 已结束
              _data.box_is_num = _data.total_Round + 1;
              setTimeout(() => {
                commit("SET_ROOM_UPDATE_REWARD", _data);
                // _data.battle_end = true;
                let _battleEndValue = {
                  room_id: _data.room_id,
                  battle_end: true
                }
                commit("SET_ROOM_BATTLE_END", _battleEndValue);
                let _Value = {
                  room_id: _data.room_id,
                  battle_status: true
                }
                commit("SET_ROOM_BATTLE_STATUS", _Value);
              }, 0)
            }
          }
          commit("SET_ROOM", _data);
          if (paramsData.callFunc != null) {
            paramsData.callFunc(_data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  GetOwnPkRecord({ commit }, paramsData) {
    return new Promise((resolve, reject) => {
      console.log(paramsData);
      this.$GetOwnPkRecord(paramsData._sendData).then(res => {
        let _data = res.data.records || [];
        _data.map(item => {
          item.boxes = item.boxes.map((t) => {
            return { box_id: t };
          });
          item.translateIndex = 0;
          item.translateX = 0;
          for (let _key in item.item_info) {
            item.item_info[_key].formatName = item.item_info[_key].code_name +
              " | " +
              item.item_info[_key].name +
              (!item.item_info[_key].exterior
                ? ""
                : " (" +
                $nuxt.$store.state.jewelryWear[parseInt(item.item_info[_key].exterior) - 1]
                  .value +
                ")");
          }
          let status = {
            isWin: false,
            num: 0
          };
          for (let _key in item.result) {
            if (_key == $nuxt.$getCookies("UserInfo").user_id) {
              status.isWin = item.result[_key].is_win;
              if (status.isWin) {
                // 计算赢家的代币(平局的代币 + 自己开的代币 + 赢得对面的代币)
                status.num = item.result[_key].extra_money + item.result[_key].open_item_price + item.result[_key].win_item_price
              } else {
                // 输家只有一个印花
                status.num = item.result[_key].win_item_price
              }
            }
          }
          item.status = status;
        });
        $nuxt.$store.commit("battle/SET_RECORD", _data);
        if (paramsData.callFunc != null) {
          paramsData.callFunc();
        }
      }).catch(err => {
        console.log(err);
      })
    });
  },
  //获取房间列表数据
  GetPkRoomList({ commit }) {
    let _sendData = {}
    this.$getPkRoomList(_sendData).then(res => {
      if (res.code == 200) {
        res.data.data.map(item => {
          item.translateIndex = 0;
          item.translateX = 0;
        })
        $nuxt.$store.commit("battle/SET_ROOMS", res.data.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }
};
export default { namespaced: true, state, getters, mutations, actions };
