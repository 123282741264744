const use_uk = {
    title: 'ESCSGO Terms of use',
    content: {
        title1: 'Conditions for using the service',
        span1_1: '1. People under the age of 18 are not allowed to use the service without the consent of their parents or guardians',
        span1_2: '2. You need a supported web browser to access the service. You acknowledge and agree that the performance of the service depends on the performance of your computer equipment and Internet connection',
        span1_3: '3. You agree to register and register with the steam account provided by Valve Corporation. You are responsible for managing your account and keeping your password secret. You are also responsible for limiting access to your account. You agree not to allow any third party to use or access the service. You agree to be responsible for all activities in your account or for your own use of the password. If you think a third party has access to your password, please use the password regeneration function of the service to obtain the new password as soon as possible',
        title2: '4. Ban',
        span2: 'We may immediately terminate or suspend access to our services for any reason without notice or liability, including but not limited to your breach of this clause. Upon termination, your right to use the service will immediately cease. If you want to terminate your account, you can directly stop using this service. All provisions of this article shall survive termination in essence, including but not limited to ownership, warranty, disclaimer, indemnity and limitation of liability',
        title3: '5. Links to other websites',
        span3: 'Our services may include non www.escsgo.com Links to third party websites or services owned or controlled by. www.escsgo.com Unable to control and assume no responsibility for the content, privacy policies or practices of any third party website or service. You further confirm and agree www.escsgo.com Will not be liable or liable, directly or indirectly, for any damage or loss caused or claimed to be caused by the use or reliance on any such content, goods or services. Or through any such website or service. We strongly recommend that you read the terms and conditions and privacy policy of any third party website or service you visit',
        title4: '6. No guarantee',
        span4: 'This website is provided "as is" without any express or implied representation or warranty. www.escsgo.com No representation or warranty is made on this website or the information and materials provided by this website. Without affecting the generality of the preceding paragraph, www.escsgo.com There is no guarantee that this website will always be available or not be available at all; or that the information on this website is complete, true, accurate or not misleading. Nothing on this website constitutes or is intended to constitute any form of advice. If you need advice on any legal, financial or medical matters, please consult the appropriate professional',
        title5: '7. Relationship',
        span5: 'We have no connection or recognition with valve, Counter Strike: global offering, dota2, steam or any other trademark of valve',
        title6: "8. ESCSGO's account quota",
        span6: 'By adding an amount to your ESCSGO account, you accept that the tokens you receive will be replaced by virtual coins, with no real value and no refund',
        title7: '9. Retrieval of game props',
        span7: 'After using the open box service and obtaining the open box result, the user can receive the game props to the steam account, or sell the props through the user center. After users fulfill the requirements in the website, they can accept game props in the steam account after setting the steam account in the user center. The user has the right to sell the game props that have been obtained but not accepted by Steam account. The virtual currency rewards that can be obtained by selling the props are marked in the description of the props in the user center. These virtual currencies can be used to pay when using the open box service. Both parties agree that virtual currency is not real currency and cannot be returned or exchanged',
        title8: '10. Limitation of liability',
        span8: "You are informed and agree to use the limitation of liability of this agreement to the maximum extent permitted by current law in the event of any loss in connection with the use of the services, inability to use the services, or actions in a particular jurisdiction. Under no circumstances shall ESCSGO, its branches, departments, any employees, managers, persons in charge or agents be liable to you or any third party for any loss, including indirect loss, actual loss, accidental loss, fine loss or special loss, even if any natural or legal person has been reminded of the possibility of such loss or the loss has been predicted Here we are. In any case, the total amount of liability of ESCSGO or any of the above natural or legal persons in connection with the provision of the services shall not exceed the amount paid in the last six months of your use of the services. In some countries and jurisdictions, it is not allowed to cancel or limit the liability for indirect and accidental losses. In these countries or jurisdictions, ESCSGO's liability should be limited to the maximum extent permitted by law",
        title11: '11. Other terms and conditions & End User License Agreement',
        span11: 'When you purchase the Alipay service provided by Alipay on our website, the responsibility for your purchase will first be transferred to the Alipay payment service provider before delivery can be made to you. Alipay service providers bear the primary responsibility for our assistance in payment and payment of relevant customer support. The terms between Alipay service providers and customers who use Alipay services are subject to separate agreements and are not subject to the terms of the website.',
        title12: 'Customers who purchase through Alipay service providers checkout:',
        span12_1: '(1) the privacy policy of Alipay service providers should apply to all payments and should be audited before any purchase is made.',
        span12_2: '(2) the refund policy for Alipay service providers should apply to all payments unless the relevant supplier informed the buyer in advance. In addition, the purchase of certain products may require the purchaser to agree to one or more end-user licensing agreements, which may include product suppliers rather than the additional terms set by us or Alipay service providers.',
        span12_3: 'We and / or entities that sell products on our website through Alipay services are mainly responsible for the warranty, maintenance, technical or product support services of these products. We and / or the entities that sell the products on our website are primarily responsible for assuming to users any responsibility in connection with the fulfillment of orders, as well as agreements signed by end-user customers. Alipay service providers are mainly responsible for promoting your payment.',
        span12_4: 'You will be responsible for any costs, taxes or other charges associated with the purchase and delivery of the goods arising from your relationship with the payment service provider or duties and taxes imposed by your local customs officer or other regulatory authority'
    }
}
export default use_uk