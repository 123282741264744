import { getCode } from "./webSocket/getCode";
let websock = null;
let global_callback = null;
let lockReconnect = false;
let timeout = 10 * 1000;
let timeoutObj = null;
let serverTimeoutObj = null;
let timeoutnum = null;
let websock_status = 0; // 0: None 1: begin 2:close or error
let isJoinPlaza = false;

//weosocket init
let initWebSocket = () => {
  $nuxt.$store.commit("battle/SET_WEBSOCKET_READY", false);
  isJoinPlaza = false;
  if (websock) {
    closeWebsocket();
  }
  try {
    let WS_URL = process.env.SOCKET;
    if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') {
      if (window.location.protocol.includes('https')) {
        WS_URL = "wss://" + window.location.host + "/connect"
      }else{
        WS_URL = "ws://" + window.location.host + "/connect"
      }
    }
    websock_status = 0;
    websock = new WebSocket(WS_URL);
    // websock.binaryType = "arraybuffer";
    websock.onopen = () => {
      websocketopen();
    };
    websock.onerror = () => {
      websocketonerror();
    };
    websock.onclose = () => {
      websocketclose();
    };
    websock.onmessage = e => {
      websocketonmessage(e);
    };
  } catch (e) {
    lockReconnect = false;
    reconnect();
  }
};
let reconnect = () => {
  if (lockReconnect) {
    return;
  }
  lockReconnect = true;
  timeoutnum && clearTimeout(timeoutnum);
  timeoutnum = setTimeout(() => {
    initWebSocket();
    lockReconnect = false;
  }, 2000);
};
let reset = () => {
  clearTimeout(timeoutObj);
  clearTimeout(serverTimeoutObj);
  start();
};
let start = () => {
  timeoutObj && clearTimeout(timeoutObj);
  serverTimeoutObj && clearTimeout(serverTimeoutObj);
  timeoutObj = setTimeout(() => {
    const msg = {
      type: "PING",
      data: {},
    };

    websocketsend(msg);
    serverTimeoutObj = setTimeout(() => {
      websock.close();
    }, timeout);
  }, timeout);
};

let wsJoinPlaza = () => {
  if (isJoinPlaza) {
    return
  }
  let token = $nuxt.$getCookies("Authorization")
  let userID = $nuxt.$getCookies("UserID")
  if (!token || !userID) {
    return
  }
  const msgLogin = {
    type: "LOGIN",
    data: {
      token: token,
      user_id: userID,
    },
  };
  if (websock_status != 1) {
    return
  }
  sendSock(msgLogin);
  const msgJoin = {
    type: "JOIN",
    data: {
      room: "user:"+userID,
    },
  };
  sendSock(msgJoin);
  // 必须要在最下面
  isJoinPlaza = true;
}


let joinPKCallBack = () => {
  const msg = {
    type: "JOIN",
    data: {
      room: "pk",
    },
  };
  if (websock_status != 1) {
    return
  }
  sendSock(msg);
}

//open
let websocketopen = () => {
  $nuxt.$store.commit("battle/SET_WEBSOCKET_READY", true);
  websock_status = 1
  start();
  let loginFlag = $nuxt.$getCookies("loginFlag")
  if (loginFlag) {
    // 已经登录 需要发送一次加入大厅协议
    wsJoinPlaza()
    console.log("websocketopen wsJoinPlaza()")
  }
  // 连接成功就需要发送一次加入PK
  joinPKCallBack()
  console.log("websocketopen joinPKCallBack()")
};

//error
let websocketonerror = () => {
  $nuxt.$store.commit("battle/SET_WEBSOCKET_READY", false);
  isJoinPlaza = false;
  lockReconnect = false;
  websock_status = 2
  reconnect();
};
//close
let websocketclose = () => {
  $nuxt.$store.commit("battle/SET_WEBSOCKET_READY", false);
  isJoinPlaza = false;
  websock_status = 2
};
//message
let websocketonmessage = e => {
  getCode(JSON.parse(e.data), global_callback);
  let data = JSON.parse(e.data);
  if (data.type == "PONG") {
    reset();
  }
};
//send
let websocketsend = str => {
  const _data = JSON.stringify(str);
  if (websock_status == 1) {
    websock.send(_data);
  }
};
// method
let sendSock = (url, _data, callback) => {
  // console.log("sendSock:", url, _data)
  // console.log("websock_status:", websock_status)
  global_callback = callback;
  if (websock_status == 1) {
    // console.log("send websocketsend start")
    websocketsend(url);
    // console.log("send websocketsend end")
  } else if (websock_status == 2) {
    lockReconnect = false;
    reconnect();
    // Toast("Connecting to server, please wait.");
  }
  else if (websock_status == 0) {

  }
};
// close
let closeWebsocket = () => {
  timeoutObj && clearTimeout(timeoutObj);
  serverTimeoutObj && clearTimeout(serverTimeoutObj);
  websock.close();
  websock = null;
};

export { 
  sendSock, 
  start, 
  closeWebsocket, 
  initWebSocket, 
  wsJoinPlaza, 
};
