const privacy_uk = {
  title: "ESCSGO Privacy policy",
  content: {
    span:
      "ESCSGO respects and protects the privacy of all users who use the service. In order to provide you with more accurate and personalized services, ESCSGO will use and disclose your personal information in accordance with this Privacy Policy. However, ESCSGO will treat such information with a high degree of care and prudence. Except as otherwise provided in this Privacy Policy, ESCSGO will not disclose or provide such information to third parties without your prior permission. ESCSGO will update this Privacy Policy from time to time. By agreeing to the ESCSGO Service Use Agreement, you are deemed to have agreed to the entire contents of this Privacy Policy. This Privacy Policy is an integral part of the ESCSGO Service Use Agreement.",
    title1: "1. Scope of application",
    span1_1:
      "A) The personal registration information required by ESCSGO when you register your ESCSGO account;",
    span1_2:
      "B) Information on your browser and computer automatically received and recorded by ESCSGO when you use ESCSGO Network services or visit ESCSGO Platform web pages, Data including but not limited to your IP address, type of browser, language used, date and time of access, software and hardware characteristic information and web page records required by you;",
    span1_3:
      "C) Users' personal data obtained by ESCSGO from business partners through legitimate means.",
    span1:
      "You understand and agree that this Privacy Policy does not apply to the following information:",
    span1_a:
      "A) The keyword information you enter when using the search service provided by ESCSGO Platform;",
    span1_b:
      "B) Information and data collected by ESCSGO from you posted on ESCSGO, including but not limited to participating in activities, transaction information and evaluation details;",
    span1_c:
      "C) Violations of laws or ESCSGO rules and actions taken by ESCSGO against you.",
    title2: "2. Use of information",
    span2_1:
      "A) ESCSGO will not provide, sell, rent, share or trade your Personal Information to any unrelated third party, unless you have prior permission or such third party and ESCSGO (including ESCSGO affiliates) provide services to you individually or jointly, and after the termination of such services, It will be denied access to such information, including all that it was previously able to access;",
    span2_2:
      "B) ESCSGO also does not allow any third party to collect, edit, sell or disseminate your personal information for free by any means. ESCSGO reserves the right to terminate the Service agreement with any ESCSGO Platform user immediately upon discovery of such activity;",
    span2_3:
      "C) For the purpose of serving users, ESCSGO may use your personal Information to provide you with information of interest to you, including but not limited to sending you information about products and services or sharing information with ESCSGO partners so that they can send you information about their products and services (the latter requires your prior consent).",
    title3: "3. Information disclosure",
    span3:
      "ESCSGO will disclose your personal information, in whole or in part, at your own will or as required by law:",
    span3_1: "A) Disclosed to third parties with your prior consent;",
    span3_2:
      "B) Share your personal information with third parties in order to provide the products and services required by you;",
    span3_3:
      "C) Disclosure to a third party or administrative or judicial authority in accordance with relevant provisions of laws or requirements of administrative or judicial authorities;",
    span3_4:
      "D) If you violate relevant Laws and regulations of China or ESCSGO Service Agreement or relevant rules, you need to disclose to a third party;",
    span3_5:
      "E) If you are a qualified INTELLECTUAL property complainant and have filed a complaint, you shall, at the request of the Respondent, disclose it to the Respondent so that both parties can handle possible rights disputes;",
    span3_6:
      "F) In a transaction created on ESCSGO platform, if any party to the transaction performs or partially performs its transaction obligations and requests for information disclosure, ESCSGO has the right to decide to provide such user with the contact information of the counterparty and other necessary information to facilitate the completion of the transaction or the resolution of disputes;",
    span3_7:
      "G) Other disclosures ESCSGO deems appropriate in accordance with laws, regulations or Policies of the Site.",
    title4: "4. Information storage and exchange",
    span4:
      "The information and data collected by ESCSGO about you will be stored on the servers of ESCSGO and/or its affiliates, and such information and data may be transmitted to, accessed, stored and displayed outside the country or region where ESCSGO collects the information and data.",
    title5: "5. The use of cookies",
    span5_1:
      "A) In the event that you do not refuse to accept cookies, ESCSGO will set or retrieve cookies on your computer so that you can log in to or use ESCSGO platform services or features that rely on cookies. Using cookies, ESCSGO can provide you with more thoughtful personalized services, including promotion services;",
    span5_2:
      "B) You have the right to choose to accept or reject cookies. You can refuse to accept cookies by modifying your browser Settings. However, if you choose to refuse to accept cookies, you may not be able to log in or use ESCSGO Network services or features that rely on cookies;",
    span5_3:
      "C) This Policy shall apply to information obtained through cookies set by ESCSGO.",
    title6: "6. Information security",
    span6_1:
      'A) All ESCSGO accounts have security protection functions. Please keep your user name and password properly. ESCSGO will ensure that your information is not lost, abused or altered by encrypting your password and other security measures. In spite of the aforementioned security measures, please note that there are no "perfect security measures" on the information network;',
    span6_2:
      "B) When conducting online transactions using ESCSGO Network services, you will inevitably disclose your personal information, such as contact information or postal address, to the counterparty or potential counterparty. Please protect your personal information and only provide it to others when necessary. If you find that your personal information is leaked, especially ESCSGO user name and password, please contact ESCSGO customer service immediately, so that ESCSGO can take corresponding measures."
  }
};
export default privacy_uk;
