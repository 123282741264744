import Vue from "vue";
import message from "@/assets/js/notify";
let main = {
  install(Vue) {
    Vue.prototype.$myMsg = message.myMsg;
  }
};

Vue.use(main);
export default ({ store }) => {
  store.$myMsg = message.myMsg;
};
