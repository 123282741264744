
import testMode from "@/components/testMode/index";
import LeftSlideNav from "../components/goodCommon/leftSlideNav.vue";
import OpenHistory from "../components/goodPage/openHistory";
import { mapGetters } from "vuex";
import { initWebSocket } from "@/plugins/webSocket";
import GoodPageHeader from "../components/goodPage/goodPageHeader";
import GoodPageFooter from "../components/goodPage/goodPageFooter.vue";
import { disableScale, GetSoundSrcForKey } from "@/assets/js/filter";
export default {
  components: {
    GoodPageHeader,
    GoodPageFooter,
    OpenHistory,
    LeftSlideNav,
    testMode,
  },
  computed: {
    ...mapGetters([
      "getVisibleLogin",
      "getVisibleRecharge",
      "getVisibleChange",
      "getVisibleIDCard",
      "getVisibleRegister",
      "getVisibleCDK",
      "getUserinfo",
    ]),
    ...mapGetters("battle", ["getRedBagVisible"]),
  },
  mounted() {
    this.$store.dispatch("checkIsLogin");
    this.getServerTime();
    if (this.$route.query.code) {
      this.$store.commit("SET_INVITE_CODE", this.$route.query.code);
    }
    // 注册事件
    this.initEvent();
    this.preLoadImg();
  },
  beforeDestroy() {
    this.removeEvent();
  },
  methods: {
    // 获取服务器时间
    getServerTime(){
      this.$GetServerTime().then((res) => {
        const timestampSeconds = Math.floor(Date.now() / 1000);
        // 保存时间差
        window.__server_time_difference = timestampSeconds - res.data.timestamp
      });
    },
    // 预加载图片
    preLoadImg() {
      for (let i = 0; i < 60; i++) {
        let imageNumber = ("00000" + i).slice(-5);
        let img = new Image();
        img.src = require(`../static/images/newEffect/baozha_${imageNumber}.png`);

        img.onerror = function () {
          console.log("Error loading " + this.src);
        };
      }
    },
    initEvent() {
      initWebSocket();
      disableScale.addListener();
      window.addEventListener("disbandRoom", this.handleRoomMessage);
    },
    removeEvent() {
      disableScale.removeListener();
      window.removeEventListener("disbandRoom", this.handleRoomMessage);
    },
    handleRoomMessage({ detail }) {
      if (this.getUserinfo) {
        for (let i in detail.members) {
          if (this.getUserinfo.user_id == detail.members[i].user_id) {
            this.$store.dispatch("showMessagePopupFn", {
              type: 2,
              text: "您参与的对战房间已解散,代币已原路退回",
              time: 6000,
            });
          }
        }
      }
    },
  },
};
