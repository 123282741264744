
export default {
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    totalPage() {
      return this.total;
    },
  },
  watch: {
    currentPage(n) {
      this.$emit("onChangePage", n);
    },
    total(val) {
      this.total = val;
    },
    page_no(val) {
      this.page_no = val;
      this.currentPage = this.page_no;
    },
  },
  props: {
    total: {
      type: Number / String,
      default: 1,
    },
    page_no: {
      type: Number / String,
      default: 1,
    },
  },
  methods: {
    /**
     * @description: 上一页
     * @return {*}
     */
    onPreviousPage() {
      if (this.currentPage == 1) return;
      this.currentPage--;
    },

    /**
     * @description: 下一页
     * @return {*}
     */
    onNextPage() {
      if (this.totalPage == this.currentPage) return;
      this.currentPage++;
    },
  },
};
