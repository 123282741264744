const Steam_cn = {
    title1: '您还没有绑定交易链接',
    desc1: '获取交易链接遇到问题，请点击查看《',
    desc2: 'Steam交易链接帮助',
    desc3: '》',
    back: '返回',
    title2: 'Steam交易链接帮助',
    content: {
        title1: '1. 打开加速器，加速【Steam社区/商店】',
        title2: '2. 加速后，请点击此按钮',
        btn1: '查看交易链接',
        title3: '3. 复制您的交易链接，并在“我的背包-交易链接”出粘贴并保存',
        title4: '1. 交易限制：',
        span4_1: '为了保护玩家的账户安全， Steam官方会因为用户的一些操作而限制其账户进行交易平台遵循Steam官方市场交易准则交易。交易限制期间的用户将无法取回物品',
        title5: '2. 如何查看：',
        span5_1: '打开这个链接能看到为什么不能交易：',
        span5_2: '（需要加速器）',
        title6: '3. 如何解除：',
        span6: '无法解除，只能等待限制时间结束，Steam客服不会修改交易或 Steam 社区市场中的限制，无论您的帐户出于何种原因受限我们同样无权解除用户账户的限制，有交易 限制的用户等限制解除之后即可交易',
        title7: '4. 限制原因：',
        span7: 'Steam 令牌未启用 为防止您的物品和 Steam账户被非法获取您密码的人盗用， Steam官方要求您启用Steam手机令牌超过15天，如果您启用Steam令牌不到15 天，您将无法交易，您可以 通过手机直接下载Steam Mobile, 开通Steam令牌，等待15天的冷却时间便可解除限制。 绑定STEAM令牌教程',
        title8: '5. 您的Steam账号库存隐私设置不正确：',
        span8_1: 'Steam库存隐私状态未设置为公开，点击链接情况设置',
        span8_2: '（需要加速器）',
        title9: '6. Steam无法访问解决方案：',
        span9: '安装网易UU加速器（含手机版）免费加速【Steam社区】。如果仍然无法登录，请更换使用Chrome浏览器'
    }
}
export default Steam_cn