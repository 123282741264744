// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/images/goodUserCenter/userinfo_dialog_bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../static/images/goodRecharge/userinfo_dialog_btn.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../static/images/goodRecharge/userinfo_dialog_btn1.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mask[data-v-a0db4a0c]{position:fixed;left:0;top:0;width:100%;height:100%;overflow:hidden;background:rgba(0,0,0,.75);z-index:5}.mask .infoDialog[data-v-a0db4a0c]{width:630px;height:335px;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:100% 100%}.mask .infoDialog .close_btn[data-v-a0db4a0c]{z-index:1;position:absolute;right:8px;top:8px;width:35px;height:35px;background-color:#212429;border-radius:8px;display:flex;justify-content:center;align-items:center}.mask .infoDialog .close_btn[data-v-a0db4a0c]:hover{filter:brightness(120%)}.mask .infoDialog .close_btn img[data-v-a0db4a0c]{width:16px;height:17px}.mask .infoDialog__title[data-v-a0db4a0c]{font-size:22px;font-family:Microsoft YaHei UI;font-weight:700;color:#e6ebf5;position:absolute;left:20px;top:20px}.mask .infoDialog__mid1[data-v-a0db4a0c]{width:100%;height:100%;padding-top:97px;display:flex;flex-direction:column;align-items:center;position:relative}.mask .infoDialog__url[data-v-a0db4a0c]{width:100%;color:#fff;margin-bottom:32px}.mask .infoDialog__btn[data-v-a0db4a0c],.mask .infoDialog__url[data-v-a0db4a0c]{font-size:16px;font-family:Microsoft YaHei UI;font-weight:700;text-align:center}.mask .infoDialog__btn[data-v-a0db4a0c]{cursor:pointer;width:242px;height:76px;color:#23262c;line-height:76px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");position:absolute;bottom:0;left:61px}.mask .infoDialog__btn.sure[data-v-a0db4a0c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");left:327px;color:#fff}.mask .infoDialog__btn[data-v-a0db4a0c]:hover{filter:brightness(115%)}.mask .infoDialog__txt[data-v-a0db4a0c]{width:570px;height:34px;border-radius:8px;font-size:18px;font-family:Microsoft YaHei UI;font-weight:400;color:#fff;text-align:center}.mask .infoDialog__txt span[data-v-a0db4a0c]{color:#ffaf12}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
