
// import BButton from '@/components/common/Button'
export default {
  props: ["error"],
  // components: { BButton },
  methods: {
    goHome() {
      this.$router.push({ path: "/" });
    }
  }
};
