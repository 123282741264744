const trans_uk = {
    title: 'ESCSGO Transaction help',
    content: {
        title1: '1. What are steam trading restrictions:',
        span1: "In order to protect the security of the player's account, steam official will restrict the trading of the user's account due to some operations of the user, and the trading platform will follow the trading rules of steam official market. During the transaction restriction period, users will not be able to retrieve the purchased items",
        title2: '2. How to view the transaction restriction details of my account:',
        span2_1: 'Click here:',
        span2_2: '(if you are unable to open this page, please use UU accelerator to speed up "steam community / store") and log in to your Steam account to view the details of the current transaction restrictions of the account',
        title3: '3. How to remove account transaction restrictions:',
        span3: 'Steam trading restrictions cannot be lifted. We can only wait for the end of the restriction time. Steam customer service will not modify the restrictions in the transaction or steam community market. No matter what reason your account is restricted, we also have no right to lift the restrictions on user accounts. Users with trading restrictions can trade after the restrictions are lifted',
        title4: '4. When will steam transaction restrictions occur',
        title4_1: '4.1 Steam token not enabled',
        span4_1: 'Steam mobile phone token lasts for more than 15 days. If you enable steam token for less than 15 days, you will not be able to trade. You can download steam mobile directly from your mobile phone, open steam token, and wait for 15 days to cool down. Binding steam token tutorial',
        title4_2: '4.2 Recently changed the account password',
        span4_2: 'If your account has recently changed the steam login password, it will also have a 7-day transaction limit. Please use it carefully when participating in activities',
        title4_3: '4.3 Reset steam token',
        span4_3: 'If you have recently removed or reset the steam mobile phone token, there will also be a 7-day transaction limit. This restriction will be triggered by replacing the mobile phone, re installing the mobile phone token or unloading and re installing the mobile phone token. Please be careful when resetting the token',
        title4_4: '4.4 Steam consumption restriction',
        span4_4_1: "If your account doesn't generate any consumption in steam within two months, it will make your account enter into transaction restriction. At this time, you need to buy any game with any amount in steam store, and then wait for a seven day transaction cooling off period;",
        span4_4_2: 'If your account is a newly registered Steam account, you need to buy a game of no less than $5 at the steam store, and then wait for a 15 day transaction limit',
        span4_5: 'Please note that steam will set many cumbersome restrictions to ensure that your account is in a safe trading environment in order to protect the transaction security of users. Before participating in the activity, we suggest that you completely remove all transaction restrictions in your account before participating in the activity, so as to avoid unnecessary losses. Thank you for your understanding and cooperation, and wish you a happy game',
        title5: '5. Steam account inventory privacy settings:',
        span5_1: 'During the steam transaction, the inventory privacy needs to be disclosed, so that our prize distribution robot can timely and accurately distribute the prizes you have won to your account. The setting method can be shown in the following figure:',
        title6: '6. Steam cannot access the solution:',
        span6: "We recommend that you use Netease UU accelerator to accelerate \"steam community / store\" to log in to steam. The mobile version of Netease UU accelerator can be downloaded free of charge from the mobile terminal to accelerate steam community / store. Because some browsers do not support some of steam's related pages, we recommend that you use Google browser to visit steam. Thank you for your understanding and support"
    }
}
export default trans_uk