const Novice_uk = {
    title: 'Novice FAQs',
    content: {
        title1: "1. Is the unpacking activity official? Why can't I see my prize in the game warehouse?",
        span1: 'This activity is an unofficial activity, all probability and accessories are real and effective, accessories need to be retrieved in order to view in their own game warehouse',
        title2: "2. Why can't I open the website to get the trade link?",
        span2: 'To access steam website from China, it is necessary to accelerate steam community before it can be displayed normally. It is recommended to open VPN or use accelerator to accelerate steam community to access steam normally (here, it is recommended to use UU accelerator to login and select "steam community / store" to access steam normally)',
        title3: "3. If I don't take it back, how long will my skin stay?",
        span3: 'Your skins in the warehouse of the website will be automatically saved for 3 months. After 3 months or when the ornament premium exceeds 10%, the items will be automatically sold and converted into G coins and saved in the account',
        title4: "4. When the prize is retrieved, it is prompted that the retrieval failed, and steam has not received the quotation information. What's the problem?",
        span4: 'Most of the retrieval failures are due to some transaction restrictions on the game account, as follows:',
        span4_1: '4.1 unbound mobile phone token. Please note that the token mentioned here is the steam mobile version, not the mailbox token enabled when registering an account. For unbound users, please refer to the steam token binding tutorial provided in the binding tutorial and bind according to the prompts. The first time you bind a mobile phone token, you will have 15 days to limit the transaction time. Just wait patiently',
        span4_2: '4.2 if the steam mobile phone token has been removed or reset, there will also be a 7-day transaction limit. This restriction will be triggered by replacing the mobile phone, re installing the mobile phone token or unloading and re installing the mobile phone token. Please be careful when resetting the token',
        span4_3: '4.3 the steam login password of the account has been changed recently, which will also result in a 7-day transaction restriction. Please use it carefully when participating in activities',
        span4_4: '4.4 how to remove the trading restrictions as shown in the figure below',
        span4_4_1: "As shown in the figure above, it can be judged that this user is a newly registered Steam account. When the newly registered Steam account completes binding the steam token, it also needs to spend more than US $5 in the steam store to lift the transaction restriction. This is a mandatory rule of steam. All newly registered users need to complete this operation. After spending more than US $5, you can see the detailed cancellation in the community market It's time to limit the transaction",
        span4_4_2: 'Please note that if the account does not generate any consumption in steam within one year, the transaction restriction will also be triggered. If the trading market is opened, the following prompt will appear',
        span4_4_3: 'At this time, you just need to go to steam store to buy any game, any amount of money can be used to trigger the cancellation and check the detailed cancellation transaction time',
        span4_4_4: "All the above transaction restrictions can be lifted in a superposition time. After the transaction restrictions of the account are lifted, you can have a free swim in steam's game trading! If you encounter any problems in the process of lifting the restrictions, please contact super customer service to assist you in query processing",
        title5: '5. When responding to the quotation, what should I do if I accidentally click cancel / reject?',
        span5: 'When you respond to the transaction quotation, if you cancel or reject the quotation late, please wait for five minutes before the active warehouse starts to retrieve it again. Please pay attention not to frequently Click to reject or cancel the quotation. Frequent rejection and cancellation of the quotation may trigger the steam suspension mechanism, and we will not be responsible for the loss. I hope you understand',
        title6: '6. What to do if the transaction quotation shows "transaction pending"?',
        span6: "If the transaction quotation shows \"transaction suspension\", please do not cancel the transaction. This operation may cause your account and robot account to enter the transaction suspension restriction at the same time. \"Transaction suspension\" is steam's official mechanism to protect your Steam account. Just wait patiently for the suspension period to end. Thank you for your understanding",
        title7: '7. My account has been vaced, can I participate in the activity?',
        span7: 'Please note that if the steam account is vaced, it is impossible to carry out normal transaction extraction. Please bind a transaction link of normal transaction account in "my inventory" to carry out prize extraction',
        title8: '8. Can the activity balance be recharged with steam balance? Can the balance be withdrawn?',
        span8_1: "This activity is unofficial. You can't use your Steam account balance, and the balance can't be withdrawn. Please try your best to use it. Meanwhile, the unused balance will always be stored in the activity account. You can log in at any time ",
        span8_2: ' For enquiries, please come back at any time',
        title9: '9. Is the activity refundable?',
        span9: 'Please note that online virtual goods have their own particularity, once purchased, it is impossible to refund. At the same time, it also reminds players that this activity is an unofficial activity, all rewards are real and effective, and there is a probability to show the information of the goods. Open the box is a chance, please rational consumption, do not over play',
        title10: "10. Special notice: the accessories of this activity will be retrieved to the steam account associated with your account. Please ensure that the transaction link you fill in is your own account. We will not be responsible for the loss of prizes or the error of sending objects caused by the wrong transaction link filling. At the same time, please cherish the account. Don't borrow others or log in under bad environment, and don't click the stranger's message We will not be responsible for the blocking of account number and the inability of VAC to use steam"
    }
}
export default Novice_uk