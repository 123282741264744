
import { fromatCDNPath } from "@/assets/js/filter";
import { mapGetters } from "vuex";
import headerImg from "@/components/goodCommon/headerImg.vue";
import moment from "moment";
export default {
  components: {
    headerImg,
  },
  props: ["type", "tabIndex", "record","mode"],
  data() {
    return {
      fromatCDNPath,
    };
  },
  mounted() {
    moment.locale("zh-cn");
  },
  computed: {
    ...mapGetters(["getJewelryWear"]),
    handleLis() {
      Array.isArray(this.record) &&
        this.record.forEach((element, index) => {
          if (!element.uuid) {
            element.uuid = this.generateUUID();
          }
        });
      let arr = this.record || [];
      return arr;
    },
  },
  filters: {
    transAgo(value) {
      if (!value) return "";
      moment.relativeTimeRounding(Math.floor);
      return moment(value * 1000).fromNow();
    },
  },
  methods: {
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    getHisText(item) {
      let formatName = item.item_name;
      if (item.code_name) {
        formatName = formatName + " | " + item.code_name;
      }
      return formatName;
    },
  },
};
