const Steam_uk = {
    title1: 'You have not yet tied the transaction link',
    desc1: 'If you have any problem in obtaining the transaction link, please click to "',
    desc2: 'steam transaction link help',
    desc3: '"',
    back: 'Go back',
    title2: 'Steam deal link help',
    content: {
        title1: '1. Turn on the accelerator to speed up the "steam community / store"',
        title2: '2. After acceleration, please click this button',
        btn1: 'View link',
        title3: '3. Copy your transaction link, paste and save it in "my warehouse transaction link"',
        title4: '1. Transaction restrictions:',
        span4_1: "In order to protect the security of the player's account, steam official will restrict the trading of the user's account due to some operations of the user, and the trading platform will follow the trading rules of steam official market. During the transaction restriction period, users will not be able to retrieve the purchased items",
        title5: '2. How to view:',
        span5_1: "Open this link to see why you can't trade:",
        span5_2: '(need accelerator)',
        title6: '3. How to remove:',
        span6: 'No matter what reason your account is restricted, we also have no right to lift the restriction of user account. Users with transaction restrictions can trade after the restriction is lifted',
        title7: '4. Reasons for restriction:',
        span7: 'Steam token is not enabled. In order to prevent your goods and Steam account from being embezzled by people who illegally obtain your password, steam official requires you to enable steam mobile phone token for more than 15 days. If you enable steam token for less than 15 days, you will not be able to trade. You can download steam mobile directly from your mobile phone, open steam token, and wait for 15 days to cool down. Binding steam token tutorial',
        title8: '5. Your steam account inventory privacy setting is incorrect:',
        span8_1: 'Steam inventory privacy status is not set to public, click the link to set',
        span8_2: '(need accelerator)',
        title9: '6. Steam cannot access the solution:',
        span9: "Install Netease UU accelerator (including mobile version) to accelerate steam community for free. If you still can't log in, please use Chrome browser instead"
    }
}
export default Steam_uk