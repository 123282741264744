export default ({ app, store, redirect }) => {
  app.router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
      //如果未匹配到路由
      // redirect("/notFound");
      if (to.path === '/vip') {
        if (app.$getCookies("loginFlag")) {
          store.commit("SET_VISIBLE_VIP", true);
        } else {
          store.dispatch("signOut", true);
          store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请先登录"
          });
          store.commit("SET_VISIBLE_LOGIN", true);
        }
      } else if (to.path) {
        if (to.path.indexOf('http') !== -1) {
          let url = to.path.slice(1)
          window.open(url, '_blank')
        }
      } else {
        //如果未匹配到路由
        redirect("/notFound");
      }
    } else {
      // debugger
      if (!app.$getCookies("loginFlag")) {
        let fromName = from.name;
        let toName = to.name;
        console.log(toName, 'toName')
        // 路有拦截
        let pageArr1 = [
          "navRoll-create",
          // "goodShoot"
        ];
        let pageArr2 = store.state.needLoginArr;
        let pageArr = pageArr1.concat(pageArr2);

        let toPage = pageArr.includes(toName);
        let fromPage = pageArr.includes(fromName);
        // console.log(fromPage,'fromPage----------')
        if (toPage) {
          if (!from.matched.length) {
            redirect("/");
          } else {
            if (fromPage) {
              store.dispatch("signOut", true);
              store.dispatch("showMessagePopupFn", {
                title: "登录提示",
                type: 2,
                text: "请先登录"
              });
            } else {
              store.dispatch("signOut", false);
            }
            store.commit("SET_VISIBLE_LOGIN", true);
          }
        } else {
          next();
        }
      } else {
        next();
      }
    }
  });

  app.router.afterEach(route => {
    //   console.log('路由守卫关')
  });
};
