
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import Dialog from "@/components/common/Dialog.vue";
import { divide, FormatS2CUserCoin } from "@/assets/js/filter";
import { mapGetters } from "vuex";
import goodRechargeSc from "../../components/goodCommon/goodRechargeSc.vue";
import { fromatCDNPath } from "../../assets/js/filter";
export default {
  components: { goodRechargeSc, Dialog, ConfirmDialog },
  name: "PageRecharge",
  data() {
    return {
      Qrcode: false, // 是否展示二维码付款
      fromatCDNPath,
      checked: false,
      recharge_scale: 0,
      loading: false,
      costList: [],
      real_price: 0,
      // num: 6.6,
      payList: [
        {
          pay: "支付宝",
          icon: require("../../static/images/goodRecharge/recharge__icon__zfb.png"),
          status: true,
          value: "alipay",
        },
      ],
      totalStart: 0,
      total: 0,
      realTotal: "",
      info: "支付宝",
      orderNo: "",
      code: "",
      pay: {
        coin_recharge_id: 0,
        pay_model: "",
        card_coupon_id: 0,
      },
      timer: null,
      status: false,
      getStatus: false,
      refresh: "",
      refreshTime: null,
      refreshShow: false,
      selectOpen: true,
      couponList: [], //优惠券列表
      quanIndex: "",
      extraStdMoney: 0, //额外获得代币
      price: 0, //价格
      chooseMoneyId: "", //选中金额id
      awardType: "", //奖励类型
      awardIndex: "", //记录满减下标
      awardItem: {}, //记录满减对象
      visibleDialogSc: false, //首充选券提示弹框
      costItem: null, //选中的金币
      condition: [
        {
          status: true,
          text: "网站内禁止转售饰品",
        },
        {
          status: true,
          text: "盲盒娱乐，请理性消费",
        },
        {
          status: true,
          text: "本人仅在经济承受范围内消费，且将为消费行为承担后果",
        },
      ],
      is_quick: false,
    };
  },
  computed: {
    ...mapGetters(["getUserinfo"]),
    ...mapGetters(["getMyMoney"]),
    payCostList() {
      console.log("this.pay.pay_model:", this.pay.pay_model)
      if(this.pay.pay_model){
        if (this.pay.pay_model == "alipay") {
          return this.costList.filter((item) => item.alipay == 1);
        }else if (this.pay.pay_model == "wechat_pay") {
          return this.costList.filter((item) => item.wechat == 1);
        }else if (this.pay.pay_model == "union_pay") {
          return this.costList.filter((item) => item.union_pay == 1);
        }
      }else{
        return this.costList;
      }
    },
  },
  created() {},
  mounted() {
    this.getMoneyList();
    // 获取优惠券列表
    this.getCardCouponList();
    this.getPayMode();
    let code1 = 0;
    let code2 = 0;
    // let code3 = 0
    document.onkeydown = () => {
      var key = window.event.keyCode;
      if (key == 17) {
        code1 = 1;
      }
      if (key == 73) {
        code2 = 1;
      }
      // if(key == 48){
      //   code3 = 2
      // }
      if (code1 === 1 && code2 === 1) {
        this.getRechargeManual();
      }
      // if(code1 === 1 && code3===2){
      //   this.getRechargeManual();
      // }
    };
    document.onkeyup = () => {
      var key = window.event.keyCode;
      if (key == 17) {
        code1 = 0;
      }
      if (key == 73) {
        code2 = 0;
      }
      // if (key == 48) {
      //   code3 = 0;
      // }
    };
  },
  watch: {
    checked(val) {
      if (!val) {
        // this.costList.map((el) => {
        //   el.status = false;
        // });
        this.chooseMoneyId = "";
        this.totalStart = 0;
        this.total = 0;
        this.realTotal = "";
        this.pay.coin_recharge_id = 0;
        this.orderNo = "";
        this.code = "";
        this.loading = false;
        this.timer && clearInterval(this.timer);
        this.refreshTime && clearInterval(this.refreshTime);
        this.timer = null;
        this.getStatus = false;
      }
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.refreshTime && clearInterval(this.refreshTime);
    this.timer = null;
    document.onkeydown = "";
    document.onkeyup = "";
  },
  methods: {
    handleAgree(val) {
      this.condition[val].status = !this.condition[val].status;
    },
    close() {
      this.$store.commit("SET_IS_SHOW_RECHARGE_DIALOG", false);
    },
    close2() {
      this.visibleDialogSc = false;
    },
    handleConfirmSc() {
      this.quanIndex = this.awardIndex;
      this.awardType = this.awardItem.info_value.award_type;
      this.selectOpen = true;
      this.visibleDialogSc = false;
    },
    handleClick() {},
    chatOnline() {
      window.open("https://ees-0008.talkvv.com", '_blank');
    },
    createSkinOrder(){
      this.loading = true
      this.$getSkinPay().then((res) => {
        this.loading = false
        console.log(res)
        if (!res.data) {
          return
        }
        this.orderNo = res.data.sn
        this.timer && clearInterval(this.timer);
        this.timer = null;
        this.timer = setInterval(() => {
          this.getRechargeStatus();
        }, 3000);
        window.open(res.data.pay_url)
      }).catch((err)=>{
        this.loading = false
      });
    },
    // 选择券
    chooseQuan(index, item) {
      // 选择金额时先判断用户是否选择了金额
      if (!this.costItem) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 2,
          text: "请先选择充值金额",
        });
        return;
      }

      if (
        this.costItem.money >= item.use_conCondition.min_price &&
        this.costItem.money <= item.use_conCondition.max_price
      ) {
        if (this.quanIndex === index) {
          this.quanIndex = "";
          this.awardType = "";
          this.selectOpen = true;
          this.real_price = this.costItem.price;
        } else {
          this.$isFirstRecharge().then((res) => {
            if (res.data.first_recharge) {
              this.visibleDialogSc = true;
              this.chooseMoneyId = "";
              this.awardIndex = index;
              this.awardItem = item;
            } else {
              this.quanIndex = index;
              this.awardType = item.info_value.award_type;
              this.getRealMoney();
            }
          });
        }
      } else {
        this.$store.dispatch("showMessagePopupFn", {
          type: 2,
          text: "该券不在此充值金额范围内使用",
        });
      }
    },
    getRealMoney() {
      console.error();
      let data = {
        recharge_id: this.pay.coin_recharge_id,
        card_coupon_id: this.couponList[this.quanIndex].user_card_id,
      };
      if (this.quanIndex !== "") {
        data.card_coupon_id = this.couponList[this.quanIndex].user_card_id;
      }
      this.$getRechargeMoney(data).then((res) => {
        this.real_price = res.data.real_price / 10000;
      });
    },
    // 展开优惠券
    handleSelect() {
      this.selectOpen = !this.selectOpen;
      // this.quanIndex = "";
      // this.awardType = "";
      if (!this.selectOpen) {
        this.getCardCouponList();
      }
    },
    FormatS2CUserCoin(_value) {
      return FormatS2CUserCoin(_value);
    },
    pageTo() {
      this.$router.push({ path: "/mine/inventory" });
      this.close();
    },
    close() {
      this.$store.commit("SET_IS_SHOW_RECHARGE_DIALOG", false);
    },
    //获取金额列表
    getMoneyList() {
      this.$getMoneyList().then((res) => {
        console.log(res, "1111111111111111111");
        res.data.elems.map((item) => {
          // item.status = false;
          this.chooseMoneyId = "";
          item.money = Number(item.money);
          item.addition_money = Number(item.addition_money);
          item.percent = Math.floor(divide(item.addition_money, item.money) * 100);
          let percentNum = Math.floor(divide(item.addition_money, item.money) * 100);
          // 首充显示金额
          if (percentNum > 0) {
            item.percentMoney = (percentNum / 100) * item.money + item.money;
            console.log(item.percentMoney, "item.percentMoney----");
          } else {
            item.percentMoney = item.money;
          }
        });
        this.costList = res.data.elems;
        console.log(this.costList, "this.costList");
        this.recharge_scale = 1; //Number(res.recharge_scale);
        this.is_quick = res.data.is_quick
      });
    },
    // 获取优惠券列表
    getCardCouponList() {
      this.$getCardCoupon().then((res) => {
        console.log(res, "优惠券列表--------");
        this.couponList = res.data.infos ? res.data.infos : [];
        console.log(this.couponList, "this.couponList");
      });
    },
    getTypeText(item) {
      // console.error(item);
      //award_type 1 + 加赠 2 是满减
      //award_mode 1 + 比例 2 是固定值
      // if(item.info_value.award_type==1){
      //   // + 赠
      //   if(item.award_mode==1){
      //     return '加赠' + item.info_value.award_value / 10000 + '%'
      //   }else{
      //     return '加赠' + item.info_value.award_value / 10000
      //   }
      // }else if(item.info_value.award_type==2){
      //   if(item.info_value.award_mode==1){
      //     return '满减' + item.info_value.award_value / 10000 + '%'
      //   }else{
      //     return '满减' + item.info_value.award_value / 10000
      //   }
      // }
    },
    //获取支付方式
    getPayMode() {
      this.$getPayMode({ platform: 1 }).then((res) => {
        let payList = res.data.pay_modes;
        let payArrList = [];
        if (payList.length > 0) {
          payList.forEach((ele) => {
            let op = {};
            if (ele === "alipay") {
              op = {
                pay: "支付宝",
                icon: require("../../static/images/goodRecharge/recharge__icon__zfb.png"),
                status: false,
                value: "alipay",
                host_name: ele.host_name,
                id: ele.id,
                sort: 255,
              };
            }
            if (ele === "union_pay") {
              op = {
                pay: "云闪付",
                icon: require("../../static/images/goodRecharge/yinlian.png"),
                status: false,
                value: "union_pay",
                host_name: ele.host_name,
                id: ele.id,
                sort: 253,
              };
            }
            if (ele === "wechat_pay") {
              op = {
                pay: "微信",
                icon: require("../../static/images/goodRecharge/recharge__icon__wx.png"),
                status: false,
                value: "wechat_pay",
                host_name: ele.host_name,
                id: ele.id,
                sort: 254,
              };
            }
            payArrList.push(op);
          });
        }
        // 排序 支付宝  微信  银联
        payArrList.sort((a, b) => b.sort - a.sort);
        if (payArrList.length == 1) {
          if (payArrList[0].value == "union_pay") {
            this.info = "云闪付或银行APP";
            payArrList[0].status = true;
          }
          if (payArrList[0].value == "alipay") {
            this.info = "支付宝";
            payArrList[0].status = true;
          }
          if (payArrList[0].value == "wechat_pay") {
            this.info = "微信";
            payArrList[0].status = true;
          }
        }
        if (payArrList && payArrList.length > 1) {
          let findIndex = payArrList.findIndex((item) => item.value === "alipay");
          if (findIndex !== -1) {
            let aliInfo = payArrList[findIndex];
            payArrList.splice(findIndex, 1);
            payArrList.unshift(aliInfo);
          }
          this.payList = payArrList;
          this.payList[0].status = true;
        } else {
          this.payList = payArrList;
        }
        this.pay.pay_model = this.payList[0].value;
        // this.payList[0].status = true;
        console.log(this.payList, "payList-------------");
        if (this.payList && this.payList.length > 0) {
          this.chooseMoneyId = this.payList[0].id;
        }
      });
    },
    getRchargePay() {
      if (!this.chooseMoneyId) {
        return false;
      } else {
        this.getStatus = true;
        this.loading = true;
        if (this.couponList && this.couponList.length > 0) {
          if (this.quanIndex) {
            this.pay.card_coupon_id = this.couponList[this.quanIndex].user_card_id;
          } else {
            if (this.quanIndex === 0) {
              this.pay.card_coupon_id = this.couponList[0].user_card_id;
            } else {
              this.pay.card_coupon_id = 0;
            }
          }
        } else {
          this.pay.card_coupon_id = 0;
        }
        this.$getRechargePay(this.pay)
          .then((res) => {
            if (res.code == 200) {
              this.Qrcode = true;
              this.orderNo = res.data.order_sn;
              this.code = res.data.pay_data_url;
              this.loading = false;
              this.totalStart = Number(res.data.real_price);
              console.log(this.quanIndex, "this.quanIndex");
              if (this.quanIndex || this.quanIndex === 0) {
                this.total =
                  Number(res.data.real_price) -
                  this.couponList[this.quanIndex].info_value.award_value / 10000;
                this.realTotal = Number(res.data.real_price);
              } else {
                this.total = Number(res.data.real_price);
                this.realTotal = "";
              }
              // 额外获得代币
              this.extraStdMoney = res.data.extra_std_money;
              // 价格
              this.price = res.data.price;

              this.timer && clearInterval(this.timer);
              this.timer = null;
              this.timer = setInterval(() => {
                this.getRechargeStatus();
              }, 3000);
              this.refreshTime && clearInterval(this.refreshTime);
              this.refreshTime = null;
              this.refreshShow = false;
              this.refresh = res.data.expire_time;
              this.refreshTime = setInterval(() => {
                // 获取当前时间
                var timestamp = Date.parse(new Date()) / 1000;
                // console.log(timestamp,'timestamp')
                // console.log(this.refresh,'当前时间')
                if (timestamp > this.refresh) {
                  // console.log('结束了')
                  clearInterval(this.refreshTime);
                  this.refreshShow = true;
                }
              }, 1000);
              this.getStatus = false;
              this.awardIndex = "";
              this.awardItem = {};
            }
          })
          .catch(() => {
            this.commonInit();
          });
      }
    },
    handleChangePay(item) {
      this.Qrcode = false;
      this.costItem = null;
      this.payList.map((el) => {
        el.status = false;
      });
      // this.condition.map((el) => {
      //   el.status = false;
      // });
      this.checked = false;
      item.status = true;
      if (item.value === "union_pay") {
        this.info = "云闪付或银行APP";
      } else if (item.value === "wechat_pay") {
        this.info = "微信";
      } else {
        this.info = item.pay;
      }
      this.pay.pay_model = item.value;
      this.quanIndex = "";
      this.awardType = "";
      this.getCardCouponList();
      this.getMoneyList();
      this.commonInit();
    },
    commonInit() {
      this.orderNo = "";
      this.code = "";
      this.loading = false;
      this.timer && clearInterval(this.timer);
      this.refreshTime && clearInterval(this.refreshTime);
      this.timer = null;
      this.getStatus = false;
      this.awardIndex = "";
      this.awardItem = {};
      this.quanIndex = "";
      this.awardType = "";
      this.total = 0;
    },
    backTo() {
      this.Qrcode = false;
    },
    getRechargeStatus() {
      let data = {
        order_sn: this.orderNo,
      };
      this.$getRechargeStatus(data).then((res) => {
        if (res.data.status == 2) {
          clearInterval(this.timer);
          this.timer = null;
          this.$store.dispatch("getUserInfo");
          this.$store.dispatch("getUserLucky");
          this.$store.dispatch("getPlayerWin");
          this.$store.dispatch("showMessagePopupFn", {
            type: 1,
            text: this.$t("recharge.tips.t1"),
          });
          this.close();
          if (
            this.$route.name == "vipRoll-details" ||
            this.$route.name == "vipDailyTasks" ||
            this.$route.name == "worldCup"
          ) {
            this.$store.dispatch("getUserMoney");
            location.reload();
          }
        } else if (res.data.status == 3) {
          clearInterval(this.timer);
          this.timer = null;
          this.$store.dispatch("showMessagePopupFn", {
            type: 1,
            text: "订单已过期",
          });
        }
      });
    },
    // 统计支付渠道
    getRechargeManual() {
      if (this.is_quick == false) {
        return
      }
      if (this.getUserinfo.account_type == 3 || this.getUserinfo.account_type == 2) {
        if (this.orderNo) {
          if (!this.status) {
            this.status = true;
            let data = {
              sn: this.orderNo,
            };
            this.$anchorQuickRecharge(data).then((res) => {
              if (res.code == 200) {
                clearInterval(this.timer);
                clearInterval(this.refreshTime);
                this.timer = null;
                this.$store.dispatch("getUserInfo");
                this.$store.dispatch("getUserLucky");
                this.$store.dispatch("getPlayerWin");
                this.$store.dispatch("showMessagePopupFn", {
                  type: 1,
                  text: this.$t("recharge.tips.t1"),
                });
                this.close();
                this.code = "";
                this.status = false;
                this.$store.dispatch("getUserMoney");
                // if (
                //   this.$route.name == "vipRoll-details" ||
                //   this.$route.name == "vipDailyTasks" ||
                //   this.$route.name == "worldCup"
                // ) {
                //   location.reload();
                // }
              }
            });
            setTimeout(() => {
              this.status = false;
            }, 3000);
          }
        }
      }
    },
    handleChangeCost(item) {
      this.real_price = item.price;
      this.costItem = item;
      this.chooseMoneyId = item.id;
      this.quanIndex = "";
      this.awardType = "";
      this.pay.coin_recharge_id = item.id;
      this.totalStart = 0;
      this.total = 0;
      this.realTotal = "";
      this.orderNo = "";
      this.code = "";
      this.loading = false;
      this.timer && clearInterval(this.timer);
      this.refreshTime && clearInterval(this.refreshTime);
      this.timer = null;
      this.getStatus = false;
      this.Qrcode = false;
    },
    handlePay() {
      if (this.checked) {
        let result = false;
        let resultIndex = "";
        for (let i = 0; i < this.condition.length; i++) {
          if (!this.condition[i].status) {
            result = true;
            resultIndex = i;
            break;
          }
        }
        if (result) {
          if (resultIndex === 0) {
            this.$store.dispatch("showMessagePopupFn", {
              type: 0,
              text: "请同意并勾选网站内禁止转售饰品",
            });
            return;
          }
          if (resultIndex === 1) {
            this.$store.dispatch("showMessagePopupFn", {
              type: 0,
              text: "请同意并勾选盲盒娱乐，请理性消费",
            });
            return;
          }
          if (resultIndex === 2) {
            this.$store.dispatch("showMessagePopupFn", {
              type: 0,
              text: "请同意并勾选本人仅在经济承受范围内消费，且将为消费行为承担后果",
            });
            return;
          }
        }
        if (this.costItem && this.costItem.id) {
          if (!this.getStatus) {
            this.getRchargePay();
          } else {
            this.$store.dispatch("showMessagePopupFn", {
              type: 2,
              text: "二维码正在加载",
            });
          }
        } else {
          this.$store.dispatch("showMessagePopupFn", {
            type: 2,
            text: "请选择充值金额",
          });
        }
      } else {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text:
            "请确认已满18周岁,且具备完全行为能力,充值即代表同意《用户协议》和《隐私条款》",
        });
      }
    },
  },
};
