import { sortArr } from "../../assets/js/filter";
import { sendSock } from "../../plugins/webSocket";
let timer = null;
let timer1 = null;
let timer2 = null;

export let clearTime = () => {
  $nuxt.$store.dispatch("battle/reduction");
  timer && clearTimeout(timer);
  timer1 && clearTimeout(timer1);
  timer2 && clearTimeout(timer2);
  timer = null;
  timer1 = null;
  timer2 = null;
};


const handleDataForByRoomAnd = (_data) => {
  if (_data.room == "pk") {
    console.log("pk", _data)
    switch (_data.mid) {
      case "create_room":
        let item = _data.data
        item.translateIndex = 0;
        item.translateX = 0;
        item.boxes = item.boxes.map(t => {
          return { box_id: t }
        })
        $nuxt.$store.commit("battle/SET_ROOMS_ADD", item);
        $nuxt.$store.commit("battle/SORT_ROOMS_DATA");
        $nuxt.$store.commit("battle/SET_ROOMS_DELETE");
        break;
      case "join_room":
        $nuxt.$store.commit("battle/SET_ROOM_UPDATE_USER", _data.data);
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE_USER", _data.data);

        break;
      case "battle_start":
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE_STATE", {
          room_id: _data.data.room_id,
          room_state: 2,
        });
        $nuxt.$store.commit("battle/SORT_ROOMS_DATA");

        // 比武改版后只推送比武开始
        if ($nuxt.$store.state.battle && $nuxt.$store.state.battle.currentRoomId == _data.data.room_id) {
          $nuxt.$store.dispatch("battle/GetPkRoomData", { _sendData: { room_id: _data.data.room_id } });
        }
        break;
      case "round_start":
        let _Value = {
          room_id: _data.data.room_id,
          battle_status: true
        }
        $nuxt.$store.commit("battle/SET_ROOM_BATTLE_STATUS", _Value);
        $nuxt.$store.commit("battle/SET_ROOM_BATTLE_BOX", _data.data);
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE_ROUND", _data.data);
        $nuxt.$store.commit("battle/SET_ROOM_UPDATE_REWARD", _data.data);
        $nuxt.$store.commit("battle/SET_ROOM_BOX_IS_NUM", { room_id: _data.data.room_id, box_is_num: _data.data.data.round_no });
        let _scrollStatusValue = {
          room_id: _data.data.room_id,
          scroll_status: false
        }
        $nuxt.$store.commit("battle/SET_ROOM_SCROLL_STATUS", _scrollStatusValue);
        timer1 = setTimeout(() => {
          let _scrollStatusValueA = {
            room_id: _data.data.room_id,
            scroll_status: true
          }
          $nuxt.$store.commit("battle/SET_ROOM_SCROLL_STATUS", _scrollStatusValueA);
        }, 1000);
        break;
      case "battle_end":
        // $nuxt.$store.commit("battle/SET_ROOM_BOX_IS_NUM", {room_id:_data.data.room_id,box_is_num:10000});
        // $nuxt.$store.commit("battle/SET_ROOM_UPDATE_RESULT", _data.data);
        // console.log(_data.data,'_data-----------------------------------')
        // let _battleEndValue = {
        //   room_id: _data.data.room_id,
        //   battle_end: true
        // }
        // 房间结算自己来算
        // $nuxt.$store.commit("battle/SET_ROOM_BATTLE_END",_battleEndValue);
        $nuxt.$store.commit("battle/SET_EMOJI_END", false);
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE_STATE", {
          room_id: _data.data.room_id,
          room_state: 3,
          room_data: _data.data
        });
        $nuxt.$store.commit("battle/SORT_ROOMS_DATA");
        $nuxt.$store.commit("battle/SET_ROOMS_DESTORY", _data.data);
        $nuxt.$store.commit("battle/SET_ROOMS_DELETE");

        break;

      case "send_emoticon":
        $nuxt.$store.commit("battle/SET_EMOJI", _data);
        timer2 = setTimeout(() => {
          $nuxt.$store.commit("battle/SET_EMOJI_REDUCTION", _data);
        }, 2000);
        break;

      // 房间自动解散
      case "battle_expire":
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE_STATE", {
          room_id: _data.data.room_id,
          room_state: 4,
          room_data: _data.data
        });

        // 提交到layout处理
        let event = new CustomEvent('disbandRoom', { detail: _data.data })
        dispatchEvent(event)
        break;
      default:
        break;
    }
  } else if (_data.room == "__ALL__") {
    if (_data.mid == "reward_history") {
      
      let event = new CustomEvent('reward_history', { detail: _data.data })
      dispatchEvent(event)

      // 1 普通记录
      // $nuxt.$store.commit("PUSH_NORMAL_REWARD_HISTORY", _data.data)
      // if (_data.data.reward_history_type == 2) {
      //   // 2 大奖记录
      //   $nuxt.$store.commit("PUSH_BIG_REWARD_HISTORY", _data.data)
      // }
    } else if (_data.mid == "red_packet_round_notice") { // 红包雨
      $nuxt.$store.commit("battle/SET_REDBAG_REFRESHAPI", new Date().getTime());
    } else if (_data.mid == "act_pkpower_create_room") {
      // 五一活动对局开始推送
      $nuxt.$store.commit("battle/SET_ACTPK_POWERCREATEROOM", _data.data);
    } else if (_data.mid == "pk_result_time_result") {
      // 比武战报推送
      $nuxt.$store.commit("battle/SET_BATTLE_REPORT", _data.data)
    }
  } else {
    if (_data.mid == "money_modify") {
      let _userStr = "user:" + $nuxt.$store.getters.getUserinfo.user_id;
      if (_data.room == _userStr) {
        $nuxt.$store.dispatch("getUserMoney");
      }
    }
  }
}

const FromatWSCallBackData = (data) => {
  // console.log(data,'data------------------------')
  if (data != null && data.length > 0) {
    if (data[0].mid == "join_notice_to_task") {
      // console.log('公告进来了')
      let msg = data[0].data
      $nuxt.$store.dispatch("showMessagePopupFn", {
        title: "提示",
        type: 3,
        text: msg.Content,
        avatar: msg.Avatar,
        userName: msg.UserName,
        time: msg.Seconds ? msg.Seconds * 1000 : 3000
      });
    } else if (data[0].mid == "act_pkpower_reward_notice") {
      // console.log(data[0].data,'------------------')
      let msg = data[0].data
      $nuxt.$store.dispatch("showMessagePopupFn", {
        title: "提示",
        type: 4,
        text: "恭喜" + msg.nick_name + "用户在欧皇争霸活动中获得胜利，奖励为",
        avatar: msg.avatar,
        userName: msg.nick_name,
        itemImg: msg.reward_item_img,
        itemPrice: msg.reward_item_price,
        time: 15000
      });
    }
    else {
      let _index = 0;
      let _money_modify_data = null;
      for (let i = 0; i < data.length; i++) {
        if (data[i].mid == "money_modify") {
          _index++;
          _money_modify_data = data[i];
        }
        else {
          handleDataForByRoomAnd(data[i])
        }
      }
      if (_index > 0 && (_money_modify_data != null)) {
        console.log("money_modify count ----------> ", _index)
        handleDataForByRoomAnd(_money_modify_data)
      }
    }
  }
}

export let getCode = (data, global_callback) => {
  let event;
  let _code = data.code;
  if (!_code) {
    let _route = data.type;
    let _data = data.data;
    switch (_route) {
      case "PONG":
        // $nuxt.$store.commit("battle/SET_PIC");
        break;
      case "PUSH":
        FromatWSCallBackData(_data.items);
        event = new CustomEvent('shootInfo', { detail: _data.items })
        break;
      case "pyUserInfo":
        $nuxt.$store.commit("battle/SET_WEBSOCKET_READY", true);
        $nuxt.$store.commit("battle/SET_CLIENT_ID", _data.clientID);
        $nuxt.$store.commit("battle/SET_GUEST", _data.isGuest);
        sendSock("initBaseProcess", {}, null);
        sendSock("onGetHallPlayingRooms", {}, null);
        sendSock("onGetCreatePkRule", {}, null);
        break;
      // 获取历史房间列表
      case "onPushHallHistoryRooms":
        _data.history.map(item => {
          item.translateIndex = 0;
          item.translateX = 0;
          item.result.usersAllPrize.map(el => {
            el.prize.map(ee => {
              ee.formatName =
                ee.code_name +
                " | " +
                ee.name +
                (!ee.exter
                  ? ""
                  : " (" +
                  $nuxt.$store.state.jewelryWear[parseInt(ee.exter) - 1]
                    .value +
                  ")");
            });
          });
          item.result.usersResult.map(el => {
            let total = 0;
            el.awardPrize.map(ee => {
              total += Number(ee.cost_price);
            });
            total += el.price;
            el.allPrice = total;
          });
        });
        $nuxt.$store.commit("battle/SET_HISTORY_ROOMS", _data.history);
        break;
      // 历史房间列表增加
      case "onAddHistoryRoom":
        if ($nuxt.$store.state.battle.historyRooms.length > 0) {
          let item = _data.history;
          item.translateIndex = 0;
          item.translateX = 0;
          item.result.usersAllPrize.map(el => {
            el.prize.map(ee => {
              ee.formatName =
                ee.code_name +
                " | " +
                ee.name +
                (!ee.exter
                  ? ""
                  : " (" +
                  $nuxt.$store.state.jewelryWear[parseInt(ee.exter) - 1]
                    .value +
                  ")");
            });
          });
          item.result.usersResult.map(el => {
            let total = 0;
            el.awardPrize.map(ee => {
              total += Number(ee.cost_price);
            });
            total += el.price;
            el.allPrice = total;
          });
          $nuxt.$store.commit("battle/SET_ADD_HISTORY_ROOMS", item);
        }
        break;
      // 获取房间列表
      case "onRoomInfos":
        _data.map(item => {
          item.translateIndex = 0;
          item.translateX = 0;
        });
        $nuxt.$store.commit("battle/SET_ROOMS", _data);
        break;
      // 加入某个房间
      case "onJoinPkRoom":
        clearTime();
        if ($nuxt.$route.path == "/battle/details") {
          // $nuxt.$store.commit("SET_LOADING", false);
          return false;
        } else {
          // $nuxt.$store.commit("SET_LOADING", false);
          $nuxt.$router.push({
            path: "/battle/details",
            query: { roomID: _data.roomID }
          });
        }
        if (global_callback) {
          global_callback();
        }
        break;
      // 创建房间
      case "onAddRoomInfo":
        _data.translateIndex = 0;
        _data.translateX = 0;
        $nuxt.$store.commit("battle/SET_ROOMS_ADD", _data);
        break;
      // 更新某个房间信息
      case "onUpdateRoomInfo":
        $nuxt.$store.commit("battle/SET_ROOMS_UPDATE", _data);
        break;
      // 某个房间消失
      case "onDestroyRoom":
        // let roomID = _data.roomID;
        // let nowId = $nuxt.$route.query.roomID || null;
        // if (roomID == nowId) {
        //   $nuxt.$router.push({ path: "/battle" });
        // }
        $nuxt.$store.commit("battle/SET_ROOMS_DESTORY", _data);
        break;
      // 更新用户余额
      case "onUpdateUserMoney":
        $nuxt.$store.commit("SET_MY_MONEY", _data.money / 100);
        break;
      case "onSendFrame#roomInfo":
        let roomArr = [];
        for (var i = 0; i < _data.gameBoxInfo.gameBox.length; i++) {
          roomArr.push(i + 1);
        }
        if (_data.roomInfo.inning > 0) {
          $nuxt.$store.commit("battle/SET_BATTLE_STATUS", true);
        } else {
          $nuxt.$store.commit("battle/SET_BATTLE_STATUS", false);
        }
        $nuxt.$store.commit("battle/SET_BOX_IS_NUM", _data.roomInfo.inning);
        $nuxt.$store.commit("battle/SET_BOX_NUMS", roomArr);
        $nuxt.$store.commit("battle/SET_ROOM", _data);
        break;
      case "onSendFrame#sitUsers":
        $nuxt.$store.commit("battle/SET_SIT_USERS", _data);
        break;
      case "onSendFrame#enterRoom":
        // console.log(_data);
        break;
      // 玩家准备
      case "onSendFrame#userReady":
        // console.log("onSendFrame#userReady", _data);
        break;
      // 游戏开始
      case "onSendFrame#gameStart":
        if (
          $nuxt.$store.state.battle.room.roomInfo.inning_total == _data.inning
        ) {
          $nuxt.$store.commit("battle/SET_EMOJI_END", false);
        }
        $nuxt.$store.commit("battle/SET_BOX_IS_NUM", _data.inning);
        $nuxt.$store.commit("battle/SET_BATTLE_STATUS", true);
        break;
      // 当前一局结算
      case "onSendFrame#getPrize":
        let current_reward = [{ num: 0 }, { num: 0 }, { num: 0 }, { num: 0 }];
        let boxsList = [];
        if (_data.boxItems.length < 30) {
          let num = parseInt(30 / _data.boxItems.length);
          if (30 % _data.boxItems.length > 0) {
            num = num + 1;
          }
          for (let i = 0; i < num; i++) {
            boxsList = boxsList.concat(_data.boxItems);
          }
        } else {
          boxsList = boxsList.concat(_data.boxItems);
        }
        let boxs = {
          box0: [],
          box1: [],
          box2: [],
          box3: []
        };
        _data.usersPrize.map((item, index) => {
          current_reward[index].num = item.price;
          let time = new Date();
          let sec = time.getSeconds().toString();
          let random = Number(sec.charAt(sec.length - 1));
          let listIndex = Number(index) + random;
          for (let i = 0; i < 29; i++) {
            listIndex += 1;
            let num = boxsList.length % listIndex;
            if (num > boxsList.length - 1) {
              num = Number(index);
            }
            boxs[`box${index}`].push(boxsList[num]);
            if (listIndex > boxsList.length - 1) {
              listIndex = Number(index) + random;
            }
          }
          boxs[`box${index}`].unshift(item.prize);
        });
        $nuxt.$store.commit("battle/SET_BATTLE_BOXS", boxs);
        $nuxt.$store.commit("battle/SET_CURRENT_REWARD", current_reward);
        $nuxt.$store.commit("battle/SET_SCROLL_STATUS", false);
        timer1 = setTimeout(() => {
          $nuxt.$store.commit("battle/SET_SCROLL_STATUS", true);
        }, 1000);
        break;
      // 玩家所有所得
      case "onSendFrame#usersAllPrize":
        _data.map(item => {
          item.prize.map(el => {
            el.formatName =
              el.code_name +
              " | " +
              el.name +
              (!el.exter
                ? ""
                : " (" +
                $nuxt.$store.state.jewelryWear[parseInt(el.exter) - 1].value +
                ")");
          });
        });
        $nuxt.$store.commit("battle/SET_USERS_REWARDS", _data);
        break;
      case "onSendFrame#gameConclude":
        // console.log("onSendFrame#gameConclude", _data);
        break;
      // 玩家离开
      case "onSendFrame#userLeave":
        // console.log("onSendFrame#userLeave", _data);
        break;
      // 游戏结束
      case "onSendFrame#bigEnd":
        _data.usersResult.map(item => {
          let total = 0;
          item.awardPrize.map(el => {
            total += Number(el.cost_price);
          });
          total += item.price;
          item.allPrice = total;
        });
        $nuxt.$store.commit("battle/SET_BATTLE_END", _data.usersResult);
        timer = setTimeout(() => {
          if ($nuxt.$store.state.battle.end.length > 0) {
            $nuxt.$store.commit("battle/SET_BATTLE_COMPLETE", true);
          }
        }, 6000);
        break;
      // 表情处理
      case "onSendFrame#emoji":
        $nuxt.$store.commit("battle/SET_EMOJI", _data);
        timer2 = setTimeout(() => {
          $nuxt.$store.commit("battle/SET_EMOJI_REDUCTION", _data.index);
        }, 2000);
        break;
      // 观赛人数
      case "onSendFrame#watchCnt":
        $nuxt.$store.commit("battle/SET_WATCH", _data.watchCnt);
        break;
      // 未处理
      case "onSendFrame#destroy":
        // console.log("onSendFrame#destroy", _data);
        break;

      case "onCreatePkRule":
        console.log(_data.boxs, '_data.boxs')
        _data.boxs.map(item => {
          item.quality_rate = JSON.parse(item.quality_rate);
          item.items.sort(sortArr("cost_price", false));
        });
        $nuxt.$store.commit("battle/SET_BATTLE_BOX_INFO", _data.boxs);
        if (global_callback) {
          global_callback(JSON.parse(JSON.stringify(_data.boxs)));
        }
        break;
      // 比武历史记录
      case "pyGetPkRoomHistory":
        _data.map(item => {
          item.translateIndex = 0;
          item.translateX = 0;
          item.game_rules = JSON.parse(item.game_rules);
          item.result = JSON.parse(item.result);
          item.result.usersAllPrize.map(el => {
            el.prize.map(ee => {
              ee.formatName =
                ee.code_name +
                " | " +
                ee.name +
                (!ee.exter
                  ? ""
                  : " (" +
                  $nuxt.$store.state.jewelryWear[parseInt(ee.exter) - 1]
                    .value +
                  ")");
            });
          });
          let status = {
            isWin: false,
            num: 0
          };
          item.result.usersResult.map(el => {
            let total = 0;
            el.awardPrize.map(ee => {
              total += Number(ee.cost_price);
            });
            total += el.price;
            el.allPrice = total;
            if (el.userID == $nuxt.$getCookies("userInfo").vip_id) {
              status.isWin = el.isWin;
              status.num = total;
            }
          });
          item.status = status;
        });
        $nuxt.$store.commit("battle/SET_RECORD", _data);
        break;
      // 红包全局通知
      case "rpRedPacketRainAll":
        if (_data) {
          _data.interval = JSON.parse(_data.interval);
          let data = {
            status: "",
            time: ""
          };
          // 0未开始  1当期红包 2派送中 3已结束
          if (_data.status == 1) {
            let startTime = _data.interval[_data.complete].start_time;
            data.time = startTime - _data.system_time;
            data.status = 1;
            $nuxt.$store.commit("battle/SET_RED_BAG_INFO", data);
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", true);
            $nuxt.$store.commit("battle/SET_USER_RED_BAG_STATUS", true);
          } else if (_data.status == 2) {
            let startTime = _data.interval[_data.complete].start_time;
            let endTime = _data.interval[_data.complete].end_time;
            if (_data.system_time >= startTime) {
              data.status = 2;
              data.time = endTime - _data.system_time;
            } else {
              data.status = 1;
              data.time = startTime - _data.system_time;
            }
            $nuxt.$store.commit("battle/SET_RED_BAG_INFO", data);
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", true);
            $nuxt.$store.commit("battle/SET_USER_RED_BAG_STATUS", true);
          } else {
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", false);
          }
        } else {
          $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", false);
        }
        break;
      // 红包领取后的通知
      case "rpRedPacketRain":
        if (_data) {
          _data.interval = JSON.parse(_data.interval);
          let data = {
            status: "",
            time: ""
          };
          // 0未开始  1当期红包 2派送中 3已结束
          if (_data.status == 1) {
            let startTime = _data.interval[_data.complete].start_time;
            data.time = startTime - _data.system_time;
            data.status = 1;
            $nuxt.$store.commit("battle/SET_RED_BAG_INFO", data);
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", true);
            $nuxt.$store.commit("battle/SET_USER_RED_BAG_STATUS", true);
          } else if (_data.status == 2) {
            let startTime = _data.interval[_data.complete].start_time;
            let endTime = _data.interval[_data.complete].end_time;
            if (_data.system_time >= startTime) {
              data.status = 2;
              data.time = endTime - _data.system_time;
              $nuxt.$store.commit(
                "battle/SET_USER_RED_BAG_STATUS",
                !_data.userComplete
              );
            } else {
              data.status = 1;
              data.time = startTime - _data.system_time;
              $nuxt.$store.commit(
                "battle/SET_USER_RED_BAG_STATUS",
                !_data.userComplete
              );
            }
            $nuxt.$store.commit("battle/SET_RED_BAG_INFO", data);
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", true);
          } else {
            $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", false);
          }
        } else {
          $nuxt.$store.commit("battle/SET_RED_BAG_VISIBLE", false);
        }
        break;
      case "rpOpenRedPacketRain":
        if (global_callback != null) {
          global_callback(_data);
        }
      case "ERR":
        $nuxt.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: data.data.error
        });

        break;
      case "onUpgradeNewRecord":
        // $nuxt.$store.dispatch("getDeliveyList");
        break;

        

      default:
        if (global_callback != null) {
          global_callback(_data);
        }
    }
    if (event) { //如果事件存在 则发送该事件 
      window.dispatchEvent(event)
    }
  } else if (_code == -1) {
    $nuxt.$store.dispatch("showMessagePopupFn", {
      type: 0,
      text: data.msg
    });
  }
};
