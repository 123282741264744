
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath, sortOnPrice, formatDate6 } from "@/assets/js/filter";

export default {
  name: "BoxRecord",
  components: {},
  data() {
    return {
      nowIndex: 0,
      tabData: [
        {
          title: "开箱记录",
          img: require("../../static/images/goodCommon/open.png"),
          active_img: require("../../static/images/goodCommon/open-active.png"),
        },
        {
          title: "升级记录",
          img: require("../../static/images/goodCommon/upgrand.png"),
          active_img: require("../../static/images/goodCommon/upgrand-active.png"),
        },
      ],
      formatDate,
      fromatCDNPath,
      tableData: [],
      page: {
        page_size: 50,
        page_no: 1,
        totalPage: 1,
      },
      destroyListener: null, // 用于保存和清除监听器
    };
  },
  computed: {
    ...mapGetters(["getJewelryWear"]),
  },
  mounted() {
    this.getHistory();

    const main = this.$refs.main;
    this.destroyListener = this.scrollEventHandler.bind(this, main);
    main.addEventListener("scroll", this.destroyListener);
  },
  beforeDestroy() {
    // 组件被销毁时，清除事件监听器
    const main = this.$refs.main;
    main.removeEventListener("scroll", this.destroyListener);
    this.destroyListener = null;
  },
  methods: {
    toggleTabs(index) {
      this.nowIndex = index;
      this.page.page_no = 1;
      this.getHistory();

      this.page.page_no = 1;
      this.tableData = [];
    },
    getHistory() {
      if (this.nowIndex == 0) {
        this.getSelfOpenBoxHistory();
      } else {
        this.getSelfUpgradeHistory();
      }
    },
    // 触底加载
    scrollEventHandler(element, event) {
      if (!this.loading) {
        // 判断是否滚动到元素的底部
        const isBottom =
          element.scrollHeight - element.scrollTop === element.clientHeight;
        if (isBottom) {
          this.page.page_no++;
          this.getHistory();
        }
      }
    },

    getSelfUpgradeHistory() {
      let data = {
        page_no: this.page.page_no,
        page_size: this.page.page_size,
      };
      this.$getUpgradeHistory(data).then((res) => {
        res.data.user_upgrade_history && res.data.user_upgrade_history.forEach((item) => {
          if (!item.put_ins) {
              item.put_ins = [];
            }
          if (item.put_in_std_money) {
            let data = {
              code_name: "",
              cost_price: item.put_in_std_money,
              exterior: 0,
              img: null,
              item_type: 0,
              name: "",
            };
            
            item.put_ins.push(data);
          }
        });

        let arr = res.data.user_upgrade_history || [];
        this.tableData = this.tableData.concat(arr);
      });
    },

    getSelfOpenBoxHistory() {
      if (this.page.page_no == 1) {
        this.tableData = [];
      }
      this.loading = true;
      this.$getSelfLately({
        page_no: this.page.page_no,
        page_size: this.page.page_size,
      })
        .then((res) => {
          if (res.code == 200) {
            let _infos = res.data.infos || [];
            _infos.map((item) => {
              item.formatName =
                item.item_code_name +
                " | " +
                item.item_name +
                (!Number(item.item_exterior)
                  ? ""
                  : " (" +
                    this.getJewelryWear[
                      parseInt(item.item_exterior) - 1 < 0
                        ? 0
                        : parseInt(item.item_exterior) - 1
                    ].value +
                    ")");
            });
            this.tableData = this.tableData.concat(_infos);

            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
