
export default {
  data() {
    return {
      showTest: false,
      showRecharge: false,
      rechargeForm: {
        money: 0,
        pay_mode: 0,
      },

      PayMode: [
        {
          label: "无",
          value: 0,
        },
        {
          label: "支付宝",
          value: "alipay",
        },
        {
          label: "支付宝h5",
          value: "alipay_h5",
        },
        {
          label: "云闪付",
          value: "union_pay",
        },
        {
          label: "微信",
          value: "wechat_pay",
        },
        {
          label: "微信h5",
          value: "wechat_pay_h5",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("keydown", this.getLienser);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getLienser);
  },
  methods: {
    getLienser(event) {
      //键码37对应于 'Ctrl'，而键码 77 对应于 'M'
      if (event.ctrlKey && event.altKey && event.keyCode == 77) {
          console.log("Ctrl + Alt + M was pressed");
          event.preventDefault(); //防止按键的默认行为
          this.showTest = !this.showTest;
      }
    },

    confirmRecharge() {
      // console.error(this.rechargeForm)
      this.$MockRecharge(this.rechargeForm).then((res) => {
        this.$store.dispatch("showMessagePopupFn", {
          type: 1,
          text: "充值成功",
        });
        this.showRecharge = false;
      }).catch(err=>{
        this.$store.dispatch("showMessagePopupFn", {
          type: 2,
          text: "充值失败",
        });
      })
    },
  },
};
