
export default {
  name: "Notify",
  data() {
    return {
      percentage: 0,
      timerprogress: null,
    };
  },
  mounted() {
    this.handleProgress();
  },
  destroyed() {
    this.timerprogress && clearInterval(this.timerprogress);
  },
  methods: {
    handleProgress() {
      this.timerprogress && clearInterval(this.timerprogress);
      if (!this.timerprogress) {
        this.timerprogress = setInterval(() => {
          this.percentage += 1;
          if (this.percentage > 99) {
            clearInterval(this.timerprogress);
            this.timerprogress = null;
          }
        }, 15);
      }
    },
  },
};
