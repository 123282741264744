
import { mapState } from "vuex";
import { deepClone, fromatCDNPath } from "../../assets/js/filter";
export default {
  data() {
    return {
      fromatCDNPath,
      navList: [],
      actList: [],
      toolList: [
        {
          icons: require("../../static/images/help/help_icon_inActive.png"),
          icons_checked: require("../../static/images/help/help_icon_Active.png"),
          isjump: 1,
          need_login: 1,
          path: "/help/qustion",
          title: "帮助中心",
        },
      ],
      activeIndex: "",
    };
  },
  watch: {
    $route: "initActive",
  },
  computed: {
    ...mapState({
      expand: (state) => state.expand,
    }),
  },
  mounted() {
    this.getNavData();
  },
  methods: {
    getNavData() {
      let data = {
        type: "1",
      };
      this.$getNavInfo(data).then((res) => {
        this.navList = res.data.nav_infos;
        this.initActive();
      });
    },
    initActive() {
      let roteName = this.$route.name;
      roteName = roteName.replace(/-/g, "/");

      let needLoginArr = [];
      if (this.navList && this.navList.length > 0) {
        this.navList.forEach((ele) => {
          if (ele.need_login === 2) {
            let name = "";
            if (ele.path && ele.path != "/") {
              name = ele.path.slice(1);
            } else if (ele.path == "/") {
              name = "index";
            }
            needLoginArr.push(name);
          }

          if (ele.path == "/" + roteName && roteName !== "index") {
            this.activeIndex = ele.title;
            console.log(this.activeIndex, "this.activeIndex-------");
          } else if (roteName === "index") {
            this.navList.forEach((child) => {
              if (child.path === "/") {
                this.activeIndex = child.title;
              }
            });
          }
        });
      }
      this.$store.commit("SET_NEEDLOGINARR", needLoginArr);
    },
    clickLink(item) {
      this.activeIndex = item.title;
    },
    setExpand() {
      this.$store.commit("SET_EXPAND", !this.$store.state.expand);
    },
  },
};
