const problem_uk = {
    title: 'ESCSGO Common problem',
    content: {
        title1: 'How can I contact you?',
        span1_1: 'If you have any problems during the game, please send an email to',
        span1_2: 'We will give you a reply within 48 hours. In order to improve communication efficiency, please describe your problems in detail in your email, and note the steam17 digit ID of your account (click the digital ID beginning with Avatar - personal data - edit data - 7656), ESCSGO account ID and your game nickname. If you have recharge problems, please attach the recharge order number and the screenshot of the recharge order Please read this article carefully and check the current transaction status of your Steam account to make sure that our robot sends game accessories to your Steam account',
        title2: "I can't log in to some steam pages?",
        span2: 'When you try to log in to ESCSGO through steam account, you may encounter these problems: "unable to access the website"/“ Steamcommunity.com Your connection request was rejected. Here is a simple solution: Download UU accelerator (mobile version of UU accelerator can be downloaded for free). After downloading, run the software and search for "accelerate steam community / store". Some browsers do not support steam page login. We recommend that you use Google browser',
        
        btitle1: 'Extract related questions',
        title3: 'How to get my prize back from ESCSGO',
        span3: 'When you have finished unpacking, you can click the "retrieve" button in the ESCSGO inventory interface, and go to the Steam client to respond to the corresponding transaction quotation (please refer to the retrieve tutorial for details). After this operation, the accessories will be sent to your game warehouse for your use',
        title4: 'ESCSGO get back tips:',
        span4_1: '1. In order to ensure your activity experience, please confirm your account transaction status before participating in the activity. If there are transaction restrictions, please participate in the activity after the transaction restrictions are lifted.',
        span4_2: '2. Please respond to the quotation as soon as possible after the award robot sends out the jewelry transaction request. If you do not accept the jewelry transaction quotation within 12 hours, your jewelry retrieval will be automatically cancelled',
        span4_3: "3. Please don't refuse the offer frequently, which may cause transaction suspension (transaction suspension is an official mechanism of steam to protect your Steam account). When you trade with others, items traded from your account will be temporarily detained by steam for 15 days before they are delivered. If both parties are good friends for more than one year, it will be suspended for one day.)",
        title6: 'Failed to save steam transaction link, error in privacy setting, failed to send to ESCSGO?',
        span6_1: 'The following reasons can lead to errors:',
        span6_2: '1. Your Steam account transaction is restricted',
        span6_3: '2. Your steam inventory privacy settings are wrong',
        span6_4: '3. The steam transaction link you bound is wrong',
        span6_5: '4. The automatic trading robot of ESCSGO is out of order. Please contact customer service',
        span6_6: 'Click to enter steam community market and log in to check the transaction restriction details:',
        title8: 'Incorrect inventory privacy setting of Steam account:',
        span8_1: 'Steam inventory privacy status is not set to public, click the link to set ',
        span8_2: ' (please use UU accelerator to "speed up steam community / store") or you can make it public in your Steam account → click profile → edit profile → click privacy settings [button]',
        title9: 'How long does ESCSGO keep items for players at most?',
        span9: "Jewelry can be \"created\" for up to 7 days from your account. If not received for more than 7 days, it will be fully recovered by the system. If your account can't be traded, we support you to modify and save the trading link. It is recommended that you save the trading link of your friend's unrestricted account to retrieve the jewelry",
        title10: "If I refuse / ignore the quotation sent by the robot, will it make it impossible for me to retrieve ESCSGO's inventory accessories?",
        span10_1: 'For the transaction quotation that has not been accepted for a certain period of time, the system will automatically reset the status of the inventory prize. When the "retrieve" button lights up again, you can operate again to retrieve the jewelry.',
        span10_2: "Please note that please do not frequently reject steam's offer, which will lead to risk control of account numbers of both parties. We will not be responsible for the loss of account numbers.",
        
        btitle2: 'Account related issues',
        title11: "Can I use my friend's trade link?",
        span11: 'Sure',
        title12: 'How do I save my steam deal link?',
        span12_1: 'Visit the login link:',
        span12_2: '(please use UU accelerator "accelerate steam community / store"), copy your transaction link and save it to your ESCSGO account page',
        title13: 'Why is my account blocked on ESCSGO?',
        span13: 'There may be many reasons for this. The main reason is that you may have violated the rules or terms of the website. In addition, if you are found to have suspicious behavior or improper behavior towards other users, or you have improper behavior in the process of communication with our customer service personnel, your account may also be blocked.',

        btitle3: 'Payment related issues',
        title14: 'My recharge has not arrived, what should I do?',
        span14_1: "Please wait 5-10 minutes. If it still doesn't arrive, please contact online customer service or send an email to ",
        span14_2: 'Contact us and provide your SteamID, Alipay payment details screenshots and ESCSGO account ID',
        title15: 'What is the minimum amount of recharge?',
        span15: 'The minimum recharge amount is 20',

        btitle4: 'Other',
        title16: 'I received a friend request from the ESCSGO administrator. Can I trust him?',
        span16: "If someone adds you under the nickname ESCSGO administrator, mediator, or robot and claims to be a member of our team, remember: they are not. It's a fraud. Please report and blackmail the user. We never send you a screenshot of our control panel. ESCSGO does not buy any skin directly from steam users. We never add you to steam and never send you any files",
        title17: "Why can't I log in to ESCSGO through steam account, which displays this error message?",
        span17: 'If your Steam account has been banned or VAC, your related prizes will not be retrieved to your game account. Please change your normal steam account to participate in the activity'
    }
}
export default problem_uk