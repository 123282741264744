
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath, sortOnPrice } from "@/assets/js/filter";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
export default {
  name: "Bag",
  components: {ConfirmDialog},
  data() {
    return {
      priceSort: 0, // 排序
      fromatCDNPath,
      sortOnPrice,
      tabData: [
        { title: "我的库存", val: 0, img: "", active_img: "" },
        { title: "寄售记录", val: 1, img: "", active_img: "" },
        { title: "提取记录", val: 6, img: "", active_img: "" },
        { title: "领取记录", val: 2, img: "", active_img: "" },
        { title: "升级消耗", val: 3, img: "", active_img: "" },
        { title: "自动寄售", val: 4, img: "", active_img: "" },
        { title: "我的箱子", val: 5, img: "", active_img: "" },
      ],
      loading: false,
      loading1: false,
      is_indeterminate: false,
      select_all_checked: false,
      timer: null,
      setTime: null,
      nowIndex: 0,
      nowTabDataVal: 0,
      dialogIndex: true,
      dialogVisible: false,

      page: {
        page_size: 30,
        page_no: 1,
        totalPage: 1,
      },
      total_price: 0,
      total_count: 0,
      userItems: [],

      pageHistory: {
        page_no: 1,
        page_size: 30,
        totalPage: 1,
        item_status: [],
      },
      get_type: 0,
      historyItems: [],
      pageAuto: {
        page_no: 1,
        page_size: 30,
        totalPage: 1,
      },
      list: [],
      receiveDialog: false, //饰品领取提示框
      receiveItem: {}, //单个领取对象
      isReceiveBatchItem: false,
      fetch_unlock_time: 0, //
      countDownTime: "", //领取解锁倒计时
      coudwnTimwer: null,
      warningTxt: "",
      myBox: [], //我的箱子
      pageBox: {
        page_no: 1,
        page_size: 21,
        totalPage: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["getJewelryWear", "getUserinfo", "getIsIdCard"]),
    choose_price() {
      let p = 0;
      this.userItems.forEach((item) => {
        if (item.select) {
          p += item.item_info.cost_price;
        }
      });
      return p / 10000;
    },
    sort() {
      if (this.priceSort === 1) {
        return 1;
      } else if (this.priceSort === 2) {
        return 2;
      }
      return 0;
    },
  },
  mounted() {
    this.myGoods();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    goUnpack(id) {
      this.$router.push({
        path: "/navUnpack",
        query: { id: id, type: 1, column: 5 },
      });
      this.close();
    },
    handleCheckAllChange(_value) {
      if (_value == true) {
        // 全选所有
        this.userItems.map((item) => {
          if (item.select == false) {
            item.select = true;
          }
        });
      } else {
        // 取消全选
        this.userItems.map((item) => {
          if (item.select == true) {
            item.select = false;
          }
        });
      }
      this.is_indeterminate = false;
    },
    refreshCurSelectStatus() {
      let count = 0;
      this.userItems.forEach(function (item) {
        if (item.select == true) {
          count = count + 1;
        }
      });
      if (count == 0) {
        // 一个都未选中
        this.is_indeterminate = false;
        this.select_all_checked = false;
        return 1;
      } else if (count < this.userItems.length) {
        // 有选中但未全选
        this.is_indeterminate = true;
        this.select_all_checked = false;
        return 2;
      } else {
        // 全部选中
        this.is_indeterminate = false;
        this.select_all_checked = true;
        return 3;
      }
    },
    itemMouseEnter(item_info, index) {
      if (item_info.user_item_id == this.userItems[index].user_item_id) {
        this.userItems[index].mouse_enter = true;
      }
    },
    itemMouseLeave(item_info, index) {
      if (item_info.user_item_id == this.userItems[index].user_item_id) {
        this.userItems[index].mouse_enter = false;
      }
    },
    selectItem(item_info, index) {
      if (item_info.select) {
        this.handleCutSelectItem(item_info, index);
      } else {
        this.handleAddSelectItem(item_info, index);
      }
    },
    handleAddSelectItem(item_info, index) {
      if (item_info.user_item_id == this.userItems[index].user_item_id) {
        this.userItems[index].select = true;
      }
      this.refreshCurSelectStatus();
    },
    handleCutSelectItem(item_info, index) {
      if (item_info.user_item_id == this.userItems[index].user_item_id) {
        this.userItems[index].select = false;
      }
      this.refreshCurSelectStatus();
    },
    formatDate(_value) {
      return formatDate(_value);
    },
    formatConvertText(item_info) {
      if (item_info.item_status == 4 || item_info.item_status == 5 || item_info.item_status == 11) {
        return formatDate(item_info.traded_at);
      } else if (item_info.item_status == 7 || item_info.item_status == 8) {
        return "寄售操作进行中";
      } else if (item_info.item_status == 10) {
        return "提取操作进行中";
      } else {
        return "---";
      }
    },
    formatCdnPath(_path) {
      return fromatCDNPath(_path);
    },
    getTime() {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.historyItems.map((item) => {
          if (item.item_status == 2) {
            if (item.time < 0) {
              return false;
            }
            item.time--;
          }
        });
      }, 1000);
    },
    //切换tab项
    toggleTabs(index, val) {
      this.priceSort = 0;
      this.nowIndex = index;
      this.nowTabDataVal = val;
      this.timer && clearInterval(this.timer);
      this.timer = null;
      if (val == 0) {
        // 我的库存
        this.page.page_no = 1;
        this.myGoods();
      }
      else if (val == 1) {
        // 寄售记录
        this.pageHistory.page_no = 1;
        this.get_type = 3;
        this.getHistory();
      }
      else if (val == 2) {
        // 领取记录
        this.pageHistory.page_no = 1;
        this.get_type = 2;
        this.getHistory();
      }
      else if (val == 3) {
        // 升级消耗
        this.pageHistory.page_no = 1;
        this.get_type = 5;
        this.getHistory();
      }
      else if (val == 4) {
        // 自动寄售
        this.pageAuto.page_no = 1;
        this.get_type = 4;
        this.getAutomaticExchangeRecord();
      }
      else if (val == 5) {
        // 我的箱子
        this.pageBox.page_no = 1;
        this.getMyBox();
      }
      else if (val == 6) {
        // 提取记录
        this.pageHistory.page_no = 1;
        this.get_type = 6;
        this.getHistory();
      }
    },
    // 获取我的箱子
    getMyBox() {
      this.loading = true;
      let _sendData = {
        page_no: this.pageBox.page_no,
        page_size: this.pageBox.page_size,
      };
      this.$getUserBoxList(_sendData)
        .then((res) => {
          let totalPage = parseInt(
            res.data.page_result.total_count / this.pageBox.page_size
          );
          if (res.data.page_result.total_count % this.pageBox.page_size > 0) {
            totalPage = totalPage + 1;
          }
          if (!totalPage) {
            totalPage = 1;
          }
          this.pageBox.totalPage = totalPage;
          this.myBox = res.data.user_box_info || [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取我的卡券
    getUserCoupon() {
      this.loading = true;
      let _sendData = {
        page_no: this.pageCoupon.page_no,
        page_size: this.pageCoupon.page_size,
      };
      this.$getCardCoupon(_sendData)
        .then((res) => {
          let totalPage = parseInt(
            res.data.page_result.total_count / this.pageCoupon.page_size
          );
          if (res.data.page_result.total_count % this.pageCoupon.page_size > 0) {
            totalPage = totalPage + 1;
          }
          if (!totalPage) {
            totalPage = 1;
          }
          this.pageCoupon.totalPage = totalPage;
          this.couponList = res.data.infos || [];
          this.coupon_total_count = res.data.page_result.total_count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取自动放弃记录
    getAutomaticExchangeRecord() {
      this.loading = true;
      let _sendData = {
        page_no: this.pageAuto.page_no,
        page_size: this.pageAuto.page_size,
        get_type: this.get_type,
        sort: 0,
        search_item_name: "",
      };
      this.$getUserSkins(_sendData)
        .then((res) => {
          let totalPage = parseInt(
            res.data.page_result.total_count / this.pageAuto.page_size
          );
          if (res.data.page_result.total_count % this.pageAuto.page_size > 0) {
            totalPage = totalPage + 1;
          }
          if (!totalPage) {
            totalPage = 1;
          }
          this.pageAuto.totalPage = totalPage;
          this.list = res.data.infos || [];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取记录
    getHistory() {
      this.loading = true;
      let _sendData = {
        page_no: this.pageHistory.page_no,
        page_size: this.pageHistory.page_size,
        get_type: this.get_type,
        sort: 0,
        search_item_name: "",
      };
      this.$getUserSkins(_sendData)
        .then((res) => {
          let _infos = res.data.infos || [];
          _infos.map((item) => {
            item.item_info.formatName =
              item.item_info.code_name +
              " | " +
              item.item_info.item_name +
              (!Number(item.item_info.exterior)
                ? ""
                : " (" +
                  this.getJewelryWear[
                    parseInt(item.item_info.exterior) - 1 < 0
                      ? 0
                      : parseInt(item.item_info.exterior) - 1
                  ].value +
                  ")");
            if (item.item_status == 2) {
              if (item.traded_at > 0) {
                item.time = item.traded_at + 24 * 60 * 60 - Date.parse(new Date()) / 1000;
              }
            }
          });
          this.historyItems = _infos;
          let totalPage = parseInt(
            res.data.page_result.total_count / this.pageHistory.page_size
          );
          if (res.data.page_result.total_count % this.pageHistory.page_size > 0) {
            totalPage = totalPage + 1;
          }
          if (!totalPage) {
            totalPage = 1;
          }
          this.pageHistory.totalPage = totalPage;
          this.pageHistory.page_no = res.data.page_result.page_no;
          if (this.nowTabDataVal === 2) {
            this.getTime();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatSeconds(t) {
      let mi = 60,
        hh = mi * 60,
        dd = hh * 24;
      let d = this.formatBit(Math.floor(t / dd)),
        h = this.formatBit(Math.floor((t - d * dd) / hh)),
        m = this.formatBit(Math.floor((t - d * dd - h * hh) / mi)),
        s = this.formatBit(Math.floor(t - d * dd - h * hh - m * mi));
      let tstr = d + "天" + h + "小时" + m + "分" + s + "秒";
      return tstr;
    },
    formatBit(v) {
      v = +v;
      return v > 9 ? v : "0" + v;
    },
    //时分秒换算
    emperorHoldTime(timer) {
      this.coudwnTimwer = setInterval(() => {
        this.countDownTime = this.formatSeconds(--timer);
      }, 1000);
    },
    myGoods() {
      this.loading = true;
      let _sendData = {
        page_no: this.page.page_no,
        page_size: this.page.page_size,
        sort: this.sort,
        get_type: 1,
        search_item_name: "",
      };
      this.$getUserSkins(_sendData)
        .then((res) => {
          let _infos = res.data.infos || [];
          _infos.map((item) => {
            item.select = false;
            item.mouse_enter = false;
          });
          this.userItems = _infos;

          this.fetch_unlock_time = res.data.lock_timestamp;
          if (this.fetch_unlock_time > 0) {
            // 获取当前时间的时间戳
            this.coudwnTimwer && clearInterval(this.coudwnTimwer);
            var timestamp = Date.parse(new Date()) / 1000;
            console.log(this.fetch_unlock_time);
            var timeDiff = this.fetch_unlock_time - timestamp;
            // 计算领取解锁倒计时
            this.emperorHoldTime(timeDiff);
          }
          let totalPage = parseInt(
            res.data.page_result.total_count / this.page.page_size
          );
          if (res.data.page_result.total_count % this.page.page_size > 0) {
            totalPage = totalPage + 1;
          }
          if (!totalPage) {
            totalPage = 1;
          }
          this.page.totalPage = totalPage;
          this.page.page_no = res.data.page_result.page_no;
          this.total_price = (res.data.item_all_price / 10000).toFixed(2);
          this.total_count = res.data.page_result.total_count;
          this.loading = false;
          this.is_indeterminate = false;
          this.select_all_checked = false;
          this.isReadyMyGoods = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 我的库存
    onChangePageInventory(page_no) {
      this.page.page_no = page_no;
      this.myGoods();
    },
    /**
     * @description: 获取我的领取记录
     */
    onChangePageRecord(page_no) {
      this.pageHistory.page_no = page_no;
      this.getHistory();
    },
    /**
     * @description: 获取我的自动放弃记录
     */
    onChangeCoverRecord(page_no) {
      this.pageAuto.page_no = page_no;
      this.getAutomaticExchangeRecord();
    },
    onChangePageBox(page_no) {},
    // 单个放弃
    handleSell(item) {
      if (!item) {
        return;
      }
      this.handleConvertItems(null, item.user_item_id, false);
    },
    // 确认领取
    handleReceive() {
      // 判断批量是否是批量领取弹框
      let userItems = null;
      let user_item_id = null;
      if (this.isReceiveBatchItem == true) {
        userItems = this.userItems;
      } else {
        if (this.receiveItem) {
          user_item_id = this.receiveItem.user_item_id;
        }
      }
      this.handleReceiveItems(userItems, user_item_id);
      // 关闭提示弹框
      // this.receiveDialog = false;
    },
    // 确认领取
    handleReceiveItems(userItems, user_item_id) {
      if (!userItems && !user_item_id) {
        return;
      }
      if (!this.getIsIdCard) {
        this.$store.commit("SET_VISIBLE_ID_CARD", true);
      } else {
        const loading = this.$loading({
          lock: true,
          text: "操作中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let _sendData = {
          user_item_id: [],
          trade_password: "",
        };
        if (userItems == null) {
          _sendData.user_item_id.push(user_item_id);
        } else {
          userItems.map((item) => {
            if (item.select == true) {
              _sendData.user_item_id.push(item.user_item_id);
            }
          });
        }
        this.$getBack(_sendData)
          .then((res) => {
            if (res.code == 200) {
              loading.close();
              this.$store.dispatch("showMessagePopupFn", {
                title: "提示",
                type: 2,
                text: "饰品已发起领取请求，请耐心等待",
              });
              this.page.page_no = 1;
              this.myGoods();
            } else {
              loading.close();
              this.$store.dispatch("showMessagePopupFn", {
                type: 0,
                text: this.$t("warehouse.tips.t3"),
              });
            }
            this.getBagRefresh();
          })
          .catch((error) => {
            loading.close();
            console.log(error);
          });
      }
    },
    handleTransfer(item){
      let user_item_ids = []
      user_item_ids.push(item.user_item_id)
      this.handleTransferItems(user_item_ids)
    },
    handleTransferItems(user_item_ids){
      if (user_item_ids.length <= 0) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: this.$t("请选择需要提取的饰品"),
        });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "操作进行中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _sendData = {
        user_item_id: user_item_ids,
      };
      this.$transfer(_sendData)
      .then((res) => {
        if (res.code == 200) {
          loading.close();
          this.$store.dispatch("showMessagePopupFn", {
            title: "提示",
            type: 1,
            text: "饰品提取成功，请前往商城查看商城余额",
          });
          this.page.page_no = 1;
          this.myGoods();
        } else {
          loading.close();
          this.$store.dispatch("showMessagePopupFn", {
            type: 0,
            text: this.$t("提取饰品失败，请稍后重试"),
          });
        }
        this.getBagRefresh();
      })
      .catch((error) => {
        loading.close();
        console.log(error);
      });
    },
    // 单个领取
    handleBack(item) {
      // if (this.fetch_unlock_time > 0) {
      //   let time = formatDate(this.fetch_unlock_time);
      //   this.warningTxt = "领取功能将于" + time + "解锁";
      //   this.receiveDialog = true;
      //   // this.handleReceive()
      // } else {
      this.receiveItem = item;
      this.isReceiveBatchItem = false;
      // this.receiveDialog = true;
      this.handleReceive();
      // return;
      // }
    },
    handleSend(item) {
      let data = {
        user_item_id: item.user_item_id,
      };
      this.$remindTake(data).then((res) => {
        if (res.code == 200) {
          this.$store.dispatch("showMessagePopupFn", {
            type: 1,
            text: "提醒发货已发送",
          });
        } else {
          this.$store.dispatch("showMessagePopupFn", {
            type: 0,
            text: "提醒发货失败！",
          });
        }
      });
    },
    // 确认放弃
    handleConvertItems(userItems, user_item_id, type) {
      if (!userItems && !user_item_id) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "操作进行中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _sendData = {
        user_item_id: [],
      };
      // 批量存入
      if (type) {
        _sendData["rebate"] = 1;
      }
      if (userItems == null) {
        _sendData.user_item_id.push(user_item_id);
      } else {
        userItems.map((item) => {
          if (item.select == true) {
            _sendData.user_item_id.push(item.user_item_id);
          }
        });
      }
      this.$postSell(_sendData)
        .then((res) => {
          if (res.code == 200) {
            loading.close();
            this.$store.dispatch("showMessagePopupFn", {
              type: 1,
              text: this.$t("warehouse.tips.t4"),
            });
            this.page.page_no = 1;
            this.myGoods();
            setTimeout(() => {
              this.$store.dispatch("getUserMoney");
            }, 1000);
          } else {
            loading.close();
            this.$store.dispatch("showMessagePopupFn", {
              type: 0,
              text: this.$t("warehouse.tips.t5"),
            });
          }
          this.getBagRefresh();
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    // 批量放弃
    handleBatchConvert(type) {
      if (this.is_indeterminate != true && this.select_all_checked != true) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "请先勾选需要寄售的饰品",
        });
        return;
      }
      this.handleConvertItems(this.userItems, null, type);
    },
    // 批量领取
    handleBatchReceive() {
      if (this.is_indeterminate != true && this.select_all_checked != true) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "请先勾选需要领取的饰品",
        });
        return;
      }
      if (this.fetch_unlock_time > 0) {
        let time = formatDate(this.fetch_unlock_time);
        this.warningTxt = "领取功能将于" + time + "解锁";
        this.receiveDialog = true;
      } else {
        this.isReceiveBatchItem = true;
        // this.receiveDialog = true;
        this.handleReceive();
        return;
      }
    },
    // 批量提取
    handleBatchTransfer(){
      if (this.is_indeterminate != true && this.select_all_checked != true) {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "请先勾选需要提取到商城的饰品",
        });
        return;
      }
      let user_item_ids = []
      this.userItems.map((item) => {
        if (item.select == true) {
          user_item_ids.push(item.user_item_id)
        }
      });
      this.handleTransferItems(user_item_ids)
    },
    // 用于通知背包更新了
    getBagRefresh() {
      let updateBag = new CustomEvent("updateBag");
      document.dispatchEvent(updateBag);
    },
    getText(status) {
      switch (status) {
        case 1:
          return "库存中";
        case 2:
          return "领取中";
        case 3:
          return "领取成功";
        case 4:
          return "已自动寄售";
        case 5:
          return "已寄售";
        case 6:
          return "升级消耗";
        case 7:
          return "寄售操作进行中";
        case 8:
          return "寄售操作进行中";
        case 10:
          return "提取到商城中";
        case 11:
          return "已提取到商城";
      }
    },
    close() {
      this.$emit("close");
    },
    // 排序
    changeSort() {
      if (this.priceSort == 0) {
        this.priceSort = 1;
      } else if (this.priceSort == 1) {
        this.priceSort = 2;
      } else {
        this.priceSort = 1;
      }
      this.myGoods();
    },
  },
  filters: {
    format(count) {
      let d = parseInt(count / (24 * 60 * 60)),
        h = parseInt((count - 24 * 60 * 60 * d) / (60 * 60)),
        m = parseInt((count - 24 * 60 * 60 * d - 3600 * h) / 60),
        s = count - 24 * 60 * 60 * d - 3600 * h - 60 * m;

      d = d < 10 ? "0" + d : d;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      if (count > 0) {
        return "预计" + h + ":" + m + ":" + s + "后到货";
      } else {
        return "取回超时，请联系客服";
      }
    },
  },
};
