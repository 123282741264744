const Steam_tw = {
    title1: '您還沒有綁定交易鏈接',
    desc1: '獲取交易鏈接遇到問題，請點擊查看《',
    desc2: 'Steam交易鏈接幫助',
    desc3: '》',
    back: '返回',
    title2: 'Steam交易鏈接幫助',
    content: {
        title1: '1. 打開加速器，加速【Steam社區/商店】',
        title2: '2. 加速後，請點擊此按鈕',
        btn1: '查看交易鏈接',
        title3: '3. 複製您的交易鏈接，並在“我的倉庫-交易鏈接”出粘貼並保存',
        title4: '1. 交易限制：',
        span4_1: '為了保護玩家的帳戶安全， Steam官方會因為用戶的一些操作而限制其帳戶進行交易平臺遵循Steam官方市場交易準則交易。交易限制期間的用戶將無法取回物品',
        title5: '2. 如何查看：',
        span5_1: '打開這個鏈接能看到為什麼不能交易：',
        span5_2: '（需要加速器）',
        title6: '3. 如何解除：',
        span6: '無法解除，只能等待限制時間結束，Steam客服不會修改交易或 Steam 社區市場中的限制，無論您的帳戶出於何種原因受限我們同樣無權解除用戶帳戶的限制，有交易 限制的用戶等限制解除之後即可交易',
        title7: '4. 限制原因：',
        span7: 'Steam 令牌未啟用 為防止您的物品和 Steam帳戶被非法獲取您密碼的人盜用， Steam官方要求您啟用Steam手機令牌超過15天，如果您啟用Steam令牌不到15 天，您將無法交易，您可以 通過手機直接下載Steam Mobile, 開通Steam令牌，等待15天的冷卻時間便可解除限制。 綁定STEAM令牌教程',
        title8: '5. 您的Steam帳號庫存隱私設置不正確：',
        span8_1: 'Steam庫存隱私狀態未設置為公開，點擊鏈接情況設置',
        span8_2: '（需要加速器）',
        title9: '6. Steam無法訪問解決方案：',
        span9: '安裝網易UU加速器（含手機版）免費加速【Steam社區】。如果仍然無法登錄，請更換使用Chrome流覽器'
    }
}
export default Steam_tw
    