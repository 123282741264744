const Novice_cn = {
    title: '新手常见问题',
    content: {
        title1: '1. 开箱活动是官方的活动吗？为什么我的奖品没法在游戏仓库中看到？',
        span1: '本活动是非官方活动，所有概率及饰品均真实有效，饰品需要通过取回才能在自己的游戏仓库中查看',
        title2: '2. 获取交易链接的网站为什么打不开？',
        span2: '从国内访问Steam网站需要通过加速Steam社区之后才能正常显示，建议开启VPN或者使用加速器加速Steam社区可正常访问Steam（这里推荐使用UU加速器登录可选择加速“steam社区/商店”即可正常访问）',
        title3: '3. 如果我不取回，皮肤会保留多久？',
        span3: '您在网站仓库内的饰品会保留7天',
        title4: '4. 取回奖品时提示取回失败，Steam也没收到报价信息是什么问题？',
        span4: '取回失败多数是因为该游戏账号存在一些交易限制，如下所示：',
        span4_1: '4.1  未绑定手机令牌，请注意，这里所说的令牌是Steam移动版，而并非注册账户时所启用的邮箱令牌，未绑定的用户请参照绑定教程中所提供的Steam令牌绑定教程，按照提示绑定。首次绑定手机令牌会有15天的交易限制时间，耐心等待即可',
        span4_2: '4.2  移除或重置过Steam手机令牌，也会产生7天交易限制时间。更换手机重新安装手机令牌或者卸载重装均会触发此限制，重置令牌时请谨慎',
        span4_3: '4.3  账户近期更改过Steam登录密码，同样也会产生7天的交易限制，参与活动时请谨慎使用',
        span4_4: '4.4  如下图所示的交易限制该如何解除',
        span4_4_1: '如上图所示可判断此用户为Steam新注册的账户，新注册的Steam账户在完成绑定Steam令牌的同时还需要在Steam商店中消费超过5美元才可解除交易限制，该规定为Steam强制硬性规定，新注册用户均需要完成此操作，消费超过5美元之后在打开社区市场就可以看到详细的解除交易限制的时间了',
        span4_4_2: '请注意，若账户一年之内未在Steam产生任何一笔消费同样也会触发交易限制，若打开交易市场出现以下提示',
        span4_4_3: '此时，只需要前往Steam商店购买任意一款游戏，任何金额的都可以，就可触发解除并查看详细的解除交易的时间了',
        span4_4_4: '以上所有的交易限制的解除时间是可以叠加的，等待账户的交易限制解除之后就可畅游无阻的进行Steam的游戏交易了！若在解除限制的过程中遇到任何问题请联系超级客服协助您进行查询处理',
        title5: '5. 回应报价时，不小心点了取消/拒绝怎么办？',
        span5: '当您回应交易报价时，若是误点取消或者拒绝报价，请等待五分钟后再活动仓库重新发起取回，请注意不要频繁的点拒绝或者取消报价，频繁拒绝和取消报价有可能会触发Steam暂挂机制，由此带来的损失，我方概不负责，希望各位玩家理解',
        title6: '6. 交易报价显示“交易暂挂”怎么办？',
        span6: '若遇到交易报价显示“交易暂挂”，请勿取消交易，此操作可能会导致您的账号和机器人账号同时进入交易暂挂限制，“交易暂挂”是Steam官方保护您的Steam账户的机制，耐心等待暂挂期结束即可，感谢您的理解',
        title7: '7. 我的账号被VAC了，可以参加活动吗？',
        span7: '请注意，若Steam账户被VAC，是无法进行正常交易提取的，请在“我的库存”中绑定一条可正常交易的账号的交易链接进行奖品提取',
        title8: '8. 活动代币可以用Steam余额充值吗？代币可以进行提现吗？',
        span8_1: '本活动为非官方活动，无法使用您的Steam账户代币，代币也不可进行提现，请尽量使用完毕，同时未使用的代币会一直存放于活动账户，可随时登陆 ',
        span8_2: ' 进行查询，随时欢迎回来',
        title9: '9. 活动可以退款吗？',
        span9: '请注意，网络虚拟物品有其特殊性，一经购买是无法进行退款的，同时也提醒各位玩家，本活动是非官方的活动，所有奖励真实有效，物品信息上都有概率展示的。开箱是有几率性的，请理性消费，切勿过度游戏',
        title10: '10. 特别声明：本活动饰品会取回到您账户关联的Steam账户，请保证您所填写的交易链接是您自己的账户，若出现交易链接填写错误导致的奖品丢失或发送对象错误，我方概不负责，同时，也请珍惜记得账号不要借与他人或在不良环境下登陆，请勿点击陌生人发送的陌生链接，由此造成的账号封禁、VAC等无法使用Steam的情况，我方也不予负责，望知悉'
    }
}
export default Novice_cn