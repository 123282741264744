const problem_cn = {
    title: 'ESCSGO 常见问题',
    content: {
        title1: '如何联系到你们?',
        span1_1: '如果您在游戏过程中遇到任何问题，请发送邮件至 ',
        span1_2: ' 我们会在48小时内给予回复为了提高沟通效率，请您在邮件中尽量详细的描述您所遇到的问题并备注您账号的Steam17位数字ID（点击头像-个人资料-编辑资料-7656开头的数字ID）、ESCSGO账户ID以及您的游戏昵称，若有充值问题，请附上充值订单号及充值订单截图；在充值前，请您仔细查阅这篇文章并检查您的Steam账户目前的交易状态，以确保我们的机器人将游戏饰品发送至您的Steam账户',
        title2: '我无法登录某些Steam网页?',
        span2: '当您尝试通过Steam账号登录ESCSGO时，您可能会遇到这些问题：“无法访问该网站”/"Steamcommunity.com拒绝了您的连接请求”。这里有一个简单的解决办法：下载uu加速器（手机端可免费下载UU加速器移动版）。下载后，运行该软件，搜索“加速STEAM社区/商店”，部分浏览器不支持Steam页面登录，我们推荐您使用谷歌浏览器',
        
        btitle1: '提取相关问题',
        title3: '如何从ESCSGO取回我的奖品',
        span3: '当您完成开箱后，您可以在ESCSGO库存界面点击“取回”按钮，并前往Steam客户端回应相应的交易报价（取回详情可参考取回教程），完成此操作后，饰品将发往您的游戏仓库供您使用',
        title4: 'ESCSGO取回小贴士：',
        span4_1: '1.为了保证您的活动体验，在参与活动前请确认自己的账户交易状态，若存在交易限制，请在交易限制解除后再参与活动。',
        span4_2: '2.在派奖机器人发出饰品交易请求后，请尽快回应交易报价，若您未在12小时内接受饰品交易报价，您的饰品取回会自动取消',
        span4_3: '3.请不要频繁的拒绝交易报价，这可能会导致交易暂挂（交易暂挂是一种Steam官方保护您的Steam账户的机制。当您与他人交易的时候，从您帐户交易出去的物品，在被送达之前，将被 Steam 暂时扣留15天。 双方为好友超过 1 年则是为期 1 天的暂挂）',
        title6: 'STEAM交易链接保存失败、提示隐私设置错误、发送至ESCSGO提示发送失败？',
        span6_1: '以下原因会导致报错：',
        span6_2: '1. 您的Steam账户交易受限',
        span6_3: '2. 您的Steam库存隐私设置错误',
        span6_4: '3. 您绑定的Steam交易链接错误',
        span6_5: '4. ESCSGO的自动交易机器人出现了故障，请联系客服',
        span6_6: '点击进入Steam社区市场并登陆账号查看交易限制详情：',
        title8: 'Steam账号库存隐私设置不正确：',
        span8_1: 'Steam库存隐私状态未设置为公开，点击链接设置',
        span8_2: '（请使用UU加速器“加速Steam社区/商店”）或者您可以在您的Steam账户→点击个人资料→编辑个人资料→点击隐私设置【按钮】，全部公开',
        title9: 'ESCSGO最多为玩家保存多久物品？',
        span9: '饰品自您的账户可“创建交易”起，至多保留7日。超过7日未领取将由系统全额回收。如您的账户无法交易，我们支持您修改保存交易链接，推荐您保存朋友的无交易限制账号的交易链接取回饰品',
        title10: '我拒绝/忽略了机器人发送的报价，会导致我无法取回ESCSGO库存饰品吗？',
        span10_1: '对于超过一定时间未接受的交易报价，系统会自动重置库存奖品的状态，当“取回”按钮再次亮起的时候，您可以重新操作取回饰品。',
        span10_2: '请注意，请勿频繁的拒绝Steam交易报价，这会导致交易双方账号被风控，因此造成的账号损失，我方概不负责。',
        
        btitle2: '账号相关问题',
        title11: '我可以使用我朋友的交易链接吗？',
        span11: '不可以使用，玩家绑定的交易链接默认为本人',
        title12: '我如何保存我的Steam交易链接？',
        span12_1: '访问登录该链接：',
        span12_2: '（请使用UU加速器“加速Steam社区/商店”），复制您的交易链接并把它保存到您的ESCSGO的账号页',
        title13: '为什么我在ESCSGO上的账号被封了？',
        span13: '这可能有多方面的原因。最主要的原因是，您可能违反了网站的规则或条款。另外，如果您被发现有可疑的行为或对其他用户有不当行为，或您在与我们客服人员的沟通过程中有不当行为，您的账号也有可能会因此被封。',

        btitle3: '付款相关问题',
        title14: '我的充值没有到账，我该怎么办？',
        span14_1: '请等候5-10分钟。如果还是没有到账，请联系在线客服或发送邮件至',
        span14_2: '联系我们，并提供您的SteamID、支付宝付款详情截图和ESCSGO账户ID',
        title15: '最少的充值金额是多少？',
        span15: '最少的充值金额是10RMB',

        btitle4: '其他',
        title16: '我收到一个来自ESCSGO管理员的朋友请求。我可以相信他吗？',
        span16: '如果有人以ESCSGO管理员、调解员或机器人为昵称添加您，并声称他是我们团队的成员，请记住：他们不是。这是一个诈骗。请举报和拉黑该用户。我们从来不会向您发送我们的控制面板截图。ESCSGO不会从Steam用户那直接购买任何皮肤。我们从来不会在Steam上添加您，也从来不会向您发送任何文件',
        title17: '为什么我不能通过Steam账号登录ESCSGO，它显示这个错误信息？',
        span17: '若您的Steam账号已被封禁或VAC，您的相关奖品将无法取回到您游戏账户，请更换正常的Steam账号参与活动'
    }
}
export default problem_cn