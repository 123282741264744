
import { mapGetters } from "vuex";
import { fromatCDNPath } from "@/assets/js/filter";
export default {
  props: ["type"],
  name: "Service",
  components: {},
  data() {
    return {
      fromatCDNPath,
      qqinfo: {
        qq: "",
        qq_img: "",
      },
      isCreateAppQRCode: false,
    };
  },
  computed: {},
  mounted() {
    // 获取群信息
    this.getGroupInfo();
  },
  beforeDestroy() {},
  methods: {
    getGroupInfo() {
      this.$siteGetQQInfo().then((res) => {
        this.qqinfo = res.data;
        this.getQRcode();
      });
    },
    async copySomething() {
      if (this.type == 2) {
        try {
          var input = document.createElement("input"); // 创建新的input元素
          document.body.appendChild(input); // 将其添加到DOM
          input.setAttribute("value", this.qqinfo.qq); // 设置其值为你要复制的文本
          input.select(); // 选择文本
          document.execCommand("copy"); // 执行复制命令
          document.body.removeChild(input); // 最后，移除新创建的input元素
          this.$store.dispatch("showMessagePopupFn", {
            type: 1,
            text: "复制成功",
          });
        } catch (err) {
          this.$store.dispatch("showMessagePopupFn", {
            type: 0,
            text: "复制失败",
          });
        }
      }
    },
    getQRcode() {
      if (this.isCreateAppQRCode == true) {
        return;
      }
      this.isCreateAppQRCode = true;
      // 创建下载二维码
      let app_img = "";
      if (this.qqinfo && this.qqinfo.app_img) {
        app_img = this.qqinfo.app_img;
      }
      if (app_img != "") {
        try {
          new QRCode(this.$refs.app_download, {
            text: app_img, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
