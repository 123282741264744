
import { mapGetters } from "vuex";
import { formatDate, fromatCDNPath, sortOnPrice, formatDate6 } from "@/assets/js/filter";
export default {
  name: "MoneyRecord",
  components: {},
  data() {
    return {
      tableData: [],
      page: {
        page_size: 10,
        page_no: 1,
        totalPage: 1,
      },
      store_types:{
        0: "全部",
        101: "购买饰品",
        102: "饰品提取",
        103: "饰品退回",
        104: "商城兑换",
        201: "推广返利",
      },
      change_types: {
        0: "全部",
        1001: "充值",
        1002: "开箱",
        1003: "升级",
        1004: "试炼",
        1005: "饰品寄售",
        // 1006: "抢卡炼金",
        1007: "寄售加成",
        // 1008: "拆箱王",
        1010: "商城购买",
        1030: "游戏",
        // 1014: "对局代付",
        1015: "试炼房间解散",
        // 1016: "Roll房购买饰品",
        // 1017: "Roll房退款",
        1018: "CDK兑换",
        // 1019: "饰品存入",
        // 1020: "射击消耗",
        1021: "ES指数",
        1022: "CDK过期退还",
        1023: "CDK生成",
        1024: "商城兑换",
        2000: "绑定steam链接",
        2001: "出货榜开箱榜",
        2002: "出货榜升级榜",
        2003: "出货榜试炼榜",
        // 2004: "抢卡炼金",
        2005: "Roll房福利",
        2006: "口令红包",
        2007: "充值任务奖励",
        // 2008: "VIP等级奖励",
        2009: "邮件奖励",
        2010: "VIP充值加送",
        2011: "首充奖励",
        2012: "幸运玩家奖励",
        2013: "邀请奖励",
        2017: "充值",
        // 2018: "卡券赠送奖励",
        // 2019: "红包雨活动奖励",
        // 2021: "VIP箱子兑换加成",
        // 2022: "VIP任务奖励",
        // 2023: "VIP箱子奖励",
        2024: "推广任务奖励",
        2025: "推广流水奖励",
        // 2026: "邀请注册活动奖励",
        2027: "VIP流水返利",
        2028: "绑定邀请码",
        2029: "主播拉新",
      },

      select_func_type: 0,
      type_options: [
        {label:"个人账变", value: 0, icon: require("../../static/images/newCommon/token.png")},
        {label:"商城账变", value: 1, icon: require("../../static/images/newCommon/store.png")},
      ],
      select_change_date: "",
      select_change_date_timestamp: 0,
      picker_options: {
        disabledDate(time) {
          if (time.getTime() > Date.now()) {
            return true;
          } else {
            let dateTime = new Date();
            dateTime = dateTime.setDate(dateTime.getDate() - 30);
            dateTime = new Date(dateTime);
            if (time.getTime() < dateTime) {
              return true;
            }
          }
          return false;
        },
      },
      setTime: null,
      destroyListener: null, // 用于保存和清除监听器
    };
  },
  computed: {},
  mounted() {
    this.select_func_type = this.type_options[0].value;
    this.getAccountChangeRecord();

    const main = this.$refs.main;
    this.destroyListener = this.scrollEventHandler.bind(this, main);
    main.addEventListener("scroll", this.destroyListener);
  },
  beforeDestroy() {
    // 组件被销毁时，清除事件监听器
    const main = this.$refs.main;
    main.removeEventListener("scroll", this.destroyListener);
    this.destroyListener = null;
  },
  methods: {
    scrollEventHandler(element, event) {
      if (!this.loading) {
        // 判断是否滚动到元素的底部
        const isBottom =
          element.scrollHeight - element.scrollTop === element.clientHeight;
        if (isBottom) {
          this.page.page_no++;
          this.getAccountChangeRecord();
        }
      }
    },
    // 选择类型
    selectChangeType(item) {
      this.select_func_type = item.value;
      this.page.page_no = 1;
      this.getAccountChangeRecord();
    },
    onChangePage(page_no) {
      this.page.page_no = page_no;
      this.getAccountChangeRecord();
    },
    onSelectChangeDate(value) {
      if (!value) {
        this.select_change_date_timestamp = 0;
      } else {
        let select_timestamp = value.getTime() / 1000;
        this.select_change_date_timestamp = select_timestamp;
      }
      this.getAccountChangeRecord();
    },
    formatDate(_value) {
      return formatDate6(_value);
    },
    formatSwapMoney(money) {
      if (money >= 0) {
        return "+" + money;
      } else {
        return money;
      }
    },
    getChangeTypeText(change_value) {
      if (this.select_func_type == this.type_options[0].value) {
        let txt = this.change_types[change_value];
        if (txt && change_value != 0) {
          return txt;
        } else {
          return "其他";
        }
      }else if (this.select_func_type == this.type_options[1].value){
        let txt = this.store_types[change_value];
        if (txt && change_value != 0) {
          return txt;
        } else {
          return "其他";
        }
      }else{
        return "其他";
      }
    },
    getAccountChangeRecord() {
      if (this.page.page_no == 1) {
        this.tableData = [];
      }
      this.loading = true;
      let _sendData = {
        page_no: this.page.page_no,
        page_size: this.page.page_size,
        change_type: 0,
        created_at: this.select_change_date_timestamp,
        func_type: this.select_func_type + 1,
      };
      this.$getAccountChangeRecord(_sendData)
        .then((res) => {
          if (res.code == 200 && res.data.res) {
            this.tableData = this.tableData.concat(res.data.res || []);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
