import Vue from "vue";
import VueI18n from "vue-i18n";
import cn from "@/plugins/locales/cn";
import uk from "@/plugins/locales/uk";
import tw from "@/plugins/locales/tw";
Vue.use(VueI18n);
export default ({ app, store }) => {
  // Set i18n instance on app
  app.i18n = new VueI18n({
    locale: store.state.langue.en,
    fallbackLocale: "zh-CN",
    messages: {
      "zh-CN": cn,
      "en-US": uk,
      "zh-TW": tw
    }
  });
  app.i18n.path = link => {
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`;
    }
    return `/${app.i18n.locale}/${link}`;
  };
};
