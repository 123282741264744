import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'// eslint-disable-line no-unused-vars
import scrollBehavior from './router.scrollBehavior.js'

const _4b022b3c = () => interopDefault(import('../pages/activity/index.vue' /* webpackChunkName: "pages/activity/index" */))
const _3d5a784e = () => interopDefault(import('../pages/activityUnpack/index.vue' /* webpackChunkName: "pages/activityUnpack/index" */))
const _4320a8dd = () => interopDefault(import('../pages/blank/index.vue' /* webpackChunkName: "pages/blank/index" */))
const _3690b442 = () => interopDefault(import('../pages/luckPlayer/index.vue' /* webpackChunkName: "pages/luckPlayer/index" */))
const _6ab85080 = () => interopDefault(import('../pages/mine/index.vue' /* webpackChunkName: "pages/mine/index" */))
const _4e8978b3 = () => interopDefault(import('../pages/navAwardRank/index.vue' /* webpackChunkName: "pages/navAwardRank/index" */))
const _1b135b84 = () => interopDefault(import('../pages/navBattle/index.vue' /* webpackChunkName: "pages/navBattle/index" */))
const _fba889fc = () => interopDefault(import('../pages/navItemAtlas/index.vue' /* webpackChunkName: "pages/navItemAtlas/index" */))
const _42a0c620 = () => interopDefault(import('../pages/navMall/index.vue' /* webpackChunkName: "pages/navMall/index" */))
const _c7123ea6 = () => interopDefault(import('../pages/navPromotion/index.vue' /* webpackChunkName: "pages/navPromotion/index" */))
const _91bfdd6e = () => interopDefault(import('../pages/navRoll/index.vue' /* webpackChunkName: "pages/navRoll/index" */))
const _65f7f67e = () => interopDefault(import('../pages/navUnpack/index.vue' /* webpackChunkName: "pages/navUnpack/index" */))
const _7007cab4 = () => interopDefault(import('../pages/navUpgrade/index.vue' /* webpackChunkName: "pages/navUpgrade/index" */))
const _4f8bee4e = () => interopDefault(import('../pages/notFound.vue' /* webpackChunkName: "pages/notFound" */))
const _41f5daf2 = () => interopDefault(import('../pages/help/AboutUs.vue' /* webpackChunkName: "pages/help/AboutUs" */))
const _9f85478e = () => interopDefault(import('../pages/help/AntimoneyLaundering.vue' /* webpackChunkName: "pages/help/AntimoneyLaundering" */))
const _6e71d398 = () => interopDefault(import('../pages/help/AntimoneyLaunderingCourse.vue' /* webpackChunkName: "pages/help/AntimoneyLaunderingCourse" */))
const _0dd2ded4 = () => interopDefault(import('../pages/help/BackCourse.vue' /* webpackChunkName: "pages/help/BackCourse" */))
const _1edf8824 = () => interopDefault(import('../pages/help/BackPc.vue' /* webpackChunkName: "pages/help/BackPc" */))
const _07e98d43 = () => interopDefault(import('../pages/help/BackPhone.vue' /* webpackChunkName: "pages/help/BackPhone" */))
const _a0e00728 = () => interopDefault(import('../pages/help/BindCourse.vue' /* webpackChunkName: "pages/help/BindCourse" */))
const _71e7218f = () => interopDefault(import('../pages/help/BindSteam.vue' /* webpackChunkName: "pages/help/BindSteam" */))
const _38006f97 = () => interopDefault(import('../pages/help/Condition.vue' /* webpackChunkName: "pages/help/Condition" */))
const _958ca9dc = () => interopDefault(import('../pages/help/ConditionCourse.vue' /* webpackChunkName: "pages/help/ConditionCourse" */))
const _cd6a5516 = () => interopDefault(import('../pages/help/Help.vue' /* webpackChunkName: "pages/help/Help" */))
const _ab30014e = () => interopDefault(import('../pages/help/HelpTransaction.vue' /* webpackChunkName: "pages/help/HelpTransaction" */))
const _279c876c = () => interopDefault(import('../pages/help/Novice.vue' /* webpackChunkName: "pages/help/Novice" */))
const _173d6585 = () => interopDefault(import('../pages/help/NoviceCourse.vue' /* webpackChunkName: "pages/help/NoviceCourse" */))
const _21b9463f = () => interopDefault(import('../pages/help/PrivacyCourse.vue' /* webpackChunkName: "pages/help/PrivacyCourse" */))
const _2784b054 = () => interopDefault(import('../pages/help/PrivacyPolicy.vue' /* webpackChunkName: "pages/help/PrivacyPolicy" */))
const _1e2731df = () => interopDefault(import('../pages/help/Qustion.vue' /* webpackChunkName: "pages/help/Qustion" */))
const _4087975a = () => interopDefault(import('../pages/help/QustionCourse.vue' /* webpackChunkName: "pages/help/QustionCourse" */))
const _3280c730 = () => interopDefault(import('../pages/mine/coupon.vue' /* webpackChunkName: "pages/mine/coupon" */))
const _54e5502e = () => interopDefault(import('../pages/mine/deal.vue' /* webpackChunkName: "pages/mine/deal" */))
const _19f7ca6d = () => interopDefault(import('../pages/mine/extension.vue' /* webpackChunkName: "pages/mine/extension" */))
const _5bdcf10a = () => interopDefault(import('../pages/mine/inventory.vue' /* webpackChunkName: "pages/mine/inventory" */))
const _35ddbbf9 = () => interopDefault(import('../pages/mine/mail.vue' /* webpackChunkName: "pages/mine/mail" */))
const _4a1d5a74 = () => interopDefault(import('../pages/mine/unpack.vue' /* webpackChunkName: "pages/mine/unpack" */))
const _6f0c5fba = () => interopDefault(import('../pages/navBattle/create.vue' /* webpackChunkName: "pages/navBattle/create" */))
const _40a9b6e2 = () => interopDefault(import('../pages/navBattle/detail.vue' /* webpackChunkName: "pages/navBattle/detail" */))
const _b0f2f074 = () => interopDefault(import('../pages/navBattle/history.vue' /* webpackChunkName: "pages/navBattle/history" */))
const _5b9eb4d1 = () => interopDefault(import('../pages/navItemAtlas/detail.vue' /* webpackChunkName: "pages/navItemAtlas/detail" */))
const _54966295 = () => interopDefault(import('../pages/navRoll/create.vue' /* webpackChunkName: "pages/navRoll/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,
  /* eslint-disable array-bracket-spacing, quotes, quote-props, object-curly-spacing, key-spacing */
  routes: [{
    path: "/activity",
    component: _4b022b3c,
    name: "activity"
  }, {
    path: "/activityUnpack",
    component: _3d5a784e,
    name: "activityUnpack"
  }, {
    path: "/blank",
    component: _4320a8dd,
    name: "blank"
  }, {
    path: "/luckPlayer",
    component: _3690b442,
    name: "luckPlayer"
  }, {
    path: "/mine",
    component: _6ab85080,
    name: "mine"
  }, {
    path: "/navAwardRank",
    component: _4e8978b3,
    name: "navAwardRank"
  }, {
    path: "/navBattle",
    component: _1b135b84,
    name: "navBattle"
  }, {
    path: "/navItemAtlas",
    component: _fba889fc,
    name: "navItemAtlas"
  }, {
    path: "/navMall",
    component: _42a0c620,
    name: "navMall"
  }, {
    path: "/navPromotion",
    component: _c7123ea6,
    name: "navPromotion"
  }, {
    path: "/navRoll",
    component: _91bfdd6e,
    name: "navRoll"
  }, {
    path: "/navUnpack",
    component: _65f7f67e,
    name: "navUnpack"
  }, {
    path: "/navUpgrade",
    component: _7007cab4,
    name: "navUpgrade"
  }, {
    path: "/notFound",
    component: _4f8bee4e,
    name: "notFound"
  }, {
    path: "/help/AboutUs",
    component: _41f5daf2,
    name: "help-AboutUs"
  }, {
    path: "/help/AntimoneyLaundering",
    component: _9f85478e,
    name: "help-AntimoneyLaundering"
  }, {
    path: "/help/AntimoneyLaunderingCourse",
    component: _6e71d398,
    name: "help-AntimoneyLaunderingCourse"
  }, {
    path: "/help/BackCourse",
    component: _0dd2ded4,
    name: "help-BackCourse"
  }, {
    path: "/help/BackPc",
    component: _1edf8824,
    name: "help-BackPc"
  }, {
    path: "/help/BackPhone",
    component: _07e98d43,
    name: "help-BackPhone"
  }, {
    path: "/help/BindCourse",
    component: _a0e00728,
    name: "help-BindCourse"
  }, {
    path: "/help/BindSteam",
    component: _71e7218f,
    name: "help-BindSteam"
  }, {
    path: "/help/Condition",
    component: _38006f97,
    name: "help-Condition"
  }, {
    path: "/help/ConditionCourse",
    component: _958ca9dc,
    name: "help-ConditionCourse"
  }, {
    path: "/help/Help",
    component: _cd6a5516,
    name: "help-Help"
  }, {
    path: "/help/HelpTransaction",
    component: _ab30014e,
    name: "help-HelpTransaction"
  }, {
    path: "/help/Novice",
    component: _279c876c,
    name: "help-Novice"
  }, {
    path: "/help/NoviceCourse",
    component: _173d6585,
    name: "help-NoviceCourse"
  }, {
    path: "/help/PrivacyCourse",
    component: _21b9463f,
    name: "help-PrivacyCourse"
  }, {
    path: "/help/PrivacyPolicy",
    component: _2784b054,
    name: "help-PrivacyPolicy"
  }, {
    path: "/help/Qustion",
    component: _1e2731df,
    name: "help-Qustion"
  }, {
    path: "/help/QustionCourse",
    component: _4087975a,
    name: "help-QustionCourse"
  }, {
    path: "/mine/coupon",
    component: _3280c730,
    name: "mine-coupon"
  }, {
    path: "/mine/deal",
    component: _54e5502e,
    name: "mine-deal"
  }, {
    path: "/mine/extension",
    component: _19f7ca6d,
    name: "mine-extension"
  }, {
    path: "/mine/inventory",
    component: _5bdcf10a,
    name: "mine-inventory"
  }, {
    path: "/mine/mail",
    component: _35ddbbf9,
    name: "mine-mail"
  }, {
    path: "/mine/unpack",
    component: _4a1d5a74,
    name: "mine-unpack"
  }, {
    path: "/navBattle/create",
    component: _6f0c5fba,
    name: "navBattle-create"
  }, {
    path: "/navBattle/detail",
    component: _40a9b6e2,
    name: "navBattle-detail"
  }, {
    path: "/navBattle/history",
    component: _b0f2f074,
    name: "navBattle-history"
  }, {
    path: "/navItemAtlas/detail",
    component: _5b9eb4d1,
    name: "navItemAtlas-detail"
  }, {
    path: "/navRoll/create",
    component: _54966295,
    name: "navRoll-create"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],
  /* eslint-enable array-bracket-spacing, quotes, quote-props, object-curly-spacing, key-spacing */

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
