import vue from "vue";
import myNotify from "@/components/common/Notify";

// 创建vue组件实例
const notify = vue.extend(myNotify);

//添加通知节点(用来存放通知的元素)
let notifyWrap = document.createElement("div");
notifyWrap.className = "notify-wrap";
notifyWrap.style =
  "position: fixed; right: 0px; top: 100px; transition-duration: .5s;z-index:9999;";
document.body.appendChild(notifyWrap);

let myMsg = {
  /**
   * 通知框
   * @title 标题
   * @content 提示内容;
   * @type 提示框类型，parameter： 0:error, 1:success, 2:warning
   * @time 显示时长
   */
  notify: ({ title, content, type, avatar, userName,itemImg,itemPrice, time = 2000 }) => {
    //创建一个存放通知的div
    const notifyDom = new notify({
      el: document.createElement("div"),
      data() {
        return {
          notifyFlag: true, // 是否显示
          title: title, // 标题
          time: time, //取消按钮是否显示
          content: content, // 文本内容
          type: type, // 类型
          timer: "",
          timeFlag: false,
          avatar:avatar, //公告用户头像
          userName:userName,  //公告用户昵称
          itemImg:itemImg,
          itemPrice:itemPrice
        };
      },
      watch: {
        timeFlag() {
          if (this.timeFlag) {
            this.notifyFlag = false;
            window.clearTimeout(this.timer);
          }
        }
      },
      created() {
        this.timer = setTimeout(() => {
          this.timeFlag = true;
        }, this.time);
      },
      beforeDestroy() {
        window.clearTimeout(this.timer);
      }
    });

    //往notifyWrap里面添加通知
    notifyWrap.appendChild(notifyDom.$el);
  }
};

export default { myMsg };
