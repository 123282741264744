
export default {
  data() {
    return {
      locationHostname: "",
      visibleGAB: false,
      visibleICP: false,
      numGAB: "",
      txtICP: "",
      hrefGAB: "",
    };
  },
  mounted() {
    this.locationHostname = location.host
    this.visibleGAB = false;
    this.visibleICP = false;
    if (this.locationHostname.includes("gewei.ltd")) {
      this.visibleGAB = false;
      this.numGAB = "";
      this.visibleICP = true;
      this.txtICP = "闽ICP备2024062277号-1";
    } else if (this.locationHostname.includes("996csgo.net")) {
      this.visibleGAB = false;
      this.numGAB = "";
      this.visibleICP = true;
      this.txtICP = "浙ICP备2024129886号-1";
    } else{
      this.visibleGAB = false;
      this.numGAB = "";
      this.visibleICP = false;
      this.txtICP = "";
    }
  },
};
