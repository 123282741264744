
export default {
  props: {},
  created() {},
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleConfirm() {
      this.$emit("handleConfirm", this.awardItem);
    },
  },
};
