const PC_uk = {
    title: 'PC end jewelry retrieval tutorial',
    content: {
        title1: 'reminder:',
        span1_1: '1. All accessories have 0 cooldown and can be retrieved in seconds',
        span1_2: '2. This platform needs to be bound to your Steam account',
        span1_3: '3. In the "retrieving" status, please go to Steam to accept the offer as soon as possible. If you miss the time, you can retrieve it again',
        span1_4: '4. All accessories are based on the Steam market price, and the recycled price is the market price',
        span1_5: '5. In case of temporary shortage, please try to retrieve it later or contact customer service. Accessories not retrieved after 30 days will be recycled by the system at full price',
        span1_6: '6. If you have other questions, please contact customer service',
        title2: 'Operation steps:',
        span2_1: '1. Before the following operations, ',
        span2_1_1: 'please ensure that your steam community access has been successfully accelerated ',
        span2_1_2: '(it is recommended to use UU accelerator to accelerate "steam community / store" for free)',
        span2_2: '2. Click "my warehouse" in the game activity center to retrieve the accessories. In a few minutes, ',
        span2_2_1: 'a green new message icon will appear on the left side of your avatar box on the steam interface',
        span2_2_2: '. Click this icon',
        span2_3: '3. Select "',
        span2_3_1: 'transaction quotation to be processed',
        span2_3_2: '" in the pop-up menu',
        span2_4: '4. Find the items you need to retrieve, and then click"',
        span2_4_1: 'Response to quotation',
        span2_4_2: '"Button',
        span2_5: '5. In the pop-up window, select"',
        span2_5_1: 'Ready to trade', 
        span2_5_2: '"Button, and then click"',
        span2_5_3: 'Accept the deal',
        span2_5_4: '"Button.',
        span2_6: '6. After completing the above steps, you can successfully retrieve the jewelry. Have you learned it?'
    }
}
export default PC_uk