const use_tw = {
    title: 'ESCSGO用戶協議',
    content: {
        title1: '有關使用本服務的條件',
        span1_1: '1. 未經父母或監護人同意使用本服務，未滿十八（18）歲的人不得使用本服務',
        span1_2: '2. 您需要支持的Web流覽器才能訪問該服務。您承認並同意服務的性能取決於您的電腦設備和Internet連接的性能',
        span1_3: '3. 您同意通過Valve Corporation提供的Steam帳戶註冊並註冊服務。您有責任管理您的帳戶並保密您的密碼。您還有責任限制對帳戶的訪問許可權。您同意不允許任何第三方使用或訪問本服務。您同意自己對您帳戶中發生的所有活動或您自己使用密碼負責。如果您認為第三方已訪問您的密碼，請儘快使用服務的密碼重新生成功能來獲取新密碼',
        title2: '4. 封禁',
        span2: '我們可以出於任何原因立即終止或暫停訪問我們的服務，恕不另行通知或承擔任何責任，包括但不限於您違反本條款。終止後，您使用本服務的權利將立即停止。 如果您希望終止帳戶，可以直接停止使用本服務。本條款的所有條款本質上應在終止後繼續有效，包括但不限於所有權條款，保證免責聲明，賠償和責任限制',
        title3: '5. 與其他網站的鏈接',
        span3: '我們的服務可能包含非www.escsgo.com擁有或控制的第三方網站或服務的鏈接。 www.escsgo.com無法控制，也不對任何第三方網站或服務的內容，隱私條款或做法承擔任何責任。 您進一步確認並同意www.escsgo.com不會直接或間接地對因使用或依賴任何此類內容，商品或服務而導致或聲稱造成的任何損害或損失負責或承擔責任。 或通過任何此類網站或服務。 我們強烈建議您閱讀您訪問的任何第三方網站或服務的條款和條件以及隱私條款',
        title4: '6. 不保證',
        span4: '本網站“按原樣”提供，不作任何明示或暗示的陳述或保證。 www.escsgo.com不對本網站或本網站提供的資訊和材料作出任何陳述或保證。 在不影響前一段的一般性的情況下，www.escsgo.com不保證：本網站將始終可用或根本不可用; 或者本網站上的資訊是完整，真實，準確或無誤導性的。 本網站上的任何內容均不構成或意圖構成任何形式的建議。 如果您需要有關任何法律，財務或醫療事宜的建議，請諮詢相應的專業人士',
        title5: '7. 關係',
        span5: '我們與Valve公司，Counter Strike：Global Offensive，Dota2，Steam或Valve公司的任何其他商標無任何關聯或認可',
        title6: '8. ESCSGO的帳戶額度',
        span6: '通過向您的ESCSGO帳戶添加金額，您接受所收到的代幣將由虛擬幣代替，沒有實際價值，也不受任何退款',
        title7: '9. 遊戲道具的取回',
        span7: '從使用開箱服務並獲得開箱結果後，用戶可將遊戲道具接收至Steam帳戶中，或通過用戶中心兌換道具。 在用戶履行網站中所要求的條件後，對用戶中心的Steam帳戶完成設置後，可在Steam帳戶中接受遊戲道具。 用戶有權兌換已獲得但Steam帳戶尚未接受的遊戲道具，兌換道具可獲得的虛擬幣獎勵標注在用戶中心的道具描述中，在使用開箱服務時可使用這些虛擬幣支付。雙方同意，虛擬幣不是現實中的貨幣，不得退還或兌換',
        title8: '10. 責任限制',
        span8: '您被告知並同意，在出現與使用服務、無法使用服務、在特定管轄權內的作為有關的任何損失的情況下，在現行法律許可的範圍內，最大限度地使用本協議的責任限制。在任何情況下，ESCSGO、其分公司、部門、任何員工、經理、負責人或代理人不對您或任意第三方的，包括間接損失、實際損失、偶然性損失、罰金損失或專門性損失在內的損失承擔責任，哪怕任何的自然人或法人已被提醒出現這些損失的可能性或這些損失已被預測到。 在任何情況下，ESCSGO或上述中的任意自然人或法人所承擔的與提供服務有關的責任的總金額不超過在您使用服務的最近6個月內所繳納的金額。 由於在一些國家和管轄權中不允許取消或限制對間接性、偶然性損失的責任，在這些國家或管轄權中， ESCSGO的責任應在法律許可的範圍內得到最大程度的限制',
        title11: '11. 其他條款和條件&最終用戶許可協議',
        span11: '當您使用支付寶提供的支付寶服務在我們的網站上進行購買時，您購買的責任將首先轉移到支付寶支付服務提供商，然後才能交付給你。 支付寶服務提供商承擔主要責任，我們的協助，支付和支付相關的客戶支持。 支付寶服務提供商與使用支付寶服務的客戶之間的條款受單獨協議的約束，不受本網站條款的約束',
        title12: '對於通過支付寶服務提供商結賬進行購買的客戶:',
        span12_1: '（1）支付寶服務提供商的隱私條款應適用於所有付款，並應在進行任何購買之前進行審核，以及',
        span12_2: '（2）支付寶服務提供商退款政策應適用於所有付款，除非相關供應商事先明確向買方發出通知。此外，購買某些產品還可能要求購物者同意一個或多個最終用戶許可協議，其中可能包括產品供應商而非我們或支付寶服務提供商設定的附加條款',
        span12_3: '我們和/或通過使用支付寶服務在我們網站上銷售產品的實體主要負責這些產品的保修，維護，技術或產品支持服務。我們和/或在我們網站上銷售產品的實體主要負責向用戶承擔與履行訂單相關的任何責任，以及最終用戶客戶簽訂的協議。支付寶服務提供商主要負責促進您的付款',
        span12_4: '您需要承擔與您與支付服務提供商的關係所產生的費用或您當地海關官員或其他監管機構徵收的關稅和稅款所產生的與購買和交付物品相關的任何費用，稅金或其他費用'
    }
}
export default use_tw
    