const privacy_tw = {
    title: "ESCSGO 隱私條款",
    content: {
      span:
        "ESCSGO尊重並保護所有使用服務用戶的個人隱私權。為了給您提供更準確、更有個性化的服務，ESCSGO會按照本隱私權政策的規定使用和披露您的個人資訊。但ESCSGO將以高度的勤勉、審慎義務對待這些資訊。除本隱私權政策另有規定外，在未征得您事先許可的情況下，ESCSGO不會將這些資訊對外披露或向第三方提供。ESCSGO會不時更新本隱私權政策。 您在同意ESCSGO服務使用協議之時，即視為您已經同意本隱私權政策全部內容。本隱私權政策屬於ESCSGO服務使用協議不可分割的一部分。",
      title1: "1.適用範圍",
      span1_1:
        "a) 在您註冊ESCSGO帳號時，您根據ESCSGO要求提供的個人註冊資訊；",
      span1_2:
        "b) 在您使用ESCSGO網路服務，或訪問ESCSGO平臺網頁時，ESCSGO自動接收並記錄的您的流覽器和電腦上的資訊，包括但不限於您的IP地址、流覽器的類型、使用的語言、訪問日期和時間、軟硬體特徵資訊及您需求的網頁記錄等數據；",
      span1_3: "c) ESCSGO通過合法途徑從商業夥伴處取得的用戶個人數據。",
      span1: "您瞭解並同意，以下資訊不適用本隱私權政策：",
      span1_a: "a) 您在使用ESCSGO平臺提供的搜索服務時輸入的關鍵字資訊；",
      span1_b:
        "b) ESCSGO收集到的您在ESCSGO發佈的有關資訊數據，包括但不限於參與活動、成交資訊及評價詳情；",
      span1_c:
        "c) 違反法律規定或違反ESCSGO規則行為及ESCSGO已對您採取的措施。",
      title2: "2.資訊使用",
      span2_1:
        "a) ESCSGO不會向任何無關第三方提供、出售、出租、分享或交易您的個人資訊，除非事先得到您的許可，或該第三方和ESCSGO（含ESCSGO關聯公司）單獨或共同為您提供服務，且在該服務結束後，其將被禁止訪問包括其以前能夠訪問的所有這些資料；",
      span2_2:
        "b) ESCSGO亦不允許任何第三方以任何手段收集、編輯、出售或者無償傳播您的個人資訊。任何ESCSGO平臺用戶如從事上述活動，一經發現，ESCSGO有權立即終止與該用戶的服務協議；",
      span2_3:
        "c) 為服務用戶的目的，ESCSGO可能通過使用您的個人資訊，向您提供您感興趣的資訊，包括但不限於向您發出產品和服務資訊，或者與ESCSGO合作夥伴共用資訊以便他們向您發送有關其產品和服務的資訊（後者需要您的事先同意）。",
      title3: "3.資訊披露",
      span3:
        "在如下情況下，ESCSGO將依據您的個人意願或法律的規定全部或部分的披露您的個人資訊：",
      span3_1: "a) 經您事先同意，向第三方披露；",
      span3_2: "b) 為提供您所要求的產品和服務，而必須和第三方分享您的個人資訊；",
      span3_3:
        "c) 根據法律的有關規定，或者行政或司法機構的要求，向第三方或者行政、司法機構披露；",
      span3_4:
        "d) 如您出現違反中國有關法律、法規或者ESCSGO服務協議或相關規則的情況，需要向第三方披露；",
      span3_5:
        "e) 如您是適格的知識產權投訴人並已提起投訴，應被投訴人要求，向被投訴人披露，以便雙方處理可能的權利糾紛；",
      span3_6:
        "f) 在ESCSGO平臺上創建的某一交易中，如交易任何一方履行或部分履行了交易義務並提出資訊披露請求的，ESCSGO有權決定向該用戶提供其交易對方的聯絡方式等必要資訊，以促成交易的完成或糾紛的解決；",
      span3_7: "g) 其他ESCSGO根據法律、法規或者網站政策認為合適的披露。",
      title4: "4.資訊存儲和交換",
      span4:
        "ESCSGO收集的有關您的資訊和資料將保存在ESCSGO及（或）其關聯公司的伺服器上，這些資訊和資料可能傳送至您所在國家、地區或ESCSGO收集資訊和資料所在地的境外並在境外被訪問、存儲和展示。",
      title5: "5.Cookie的使用",
      span5_1:
        "a) 在您未拒絕接受cookies的情況下，ESCSGO會在您的電腦上設定或取用cookies，以便您能登錄或使用依賴於cookies的ESCSGO平臺服務或功能。ESCSGO使用cookies可為您提供更加周到的個性化服務，包括推廣服務；",
      span5_2:
        "b) 您有權選擇接受或拒絕接受cookies。您可以通過修改流覽器設置的方式拒絕接受cookies。但如果您選擇拒絕接受cookies，則您可能無法登錄或使用依賴於cookies的ESCSGO網路服務或功能；",
      span5_3: "c) 通過ESCSGO所設cookies所取得的有關資訊，將適用本政策。",
      title6: "6.資訊安全",
      span6_1:
        "a) ESCSGO帳號均有安全保護功能，請妥善保管您的用戶名及密碼資訊。ESCSGO將通過對用戶密碼進行加密等安全措施確保您的資訊不丟失，不被濫用和變造。儘管有前述安全措施，但同時也請您注意在資訊網絡上不存在“完善的安全措施”；",
      span6_2:
        "b) 在使用ESCSGO網路服務進行網上交易時，您不可避免的要向交易對方或潛在的交易對方披露自己的個人資訊，如聯絡方式或者郵政地址。請您妥善保護自己的個人資訊，僅在必要的情形下向他人提供。如您發現自己的個人資訊洩密，尤其是ESCSGO用戶名及密碼發生洩露，請您立即聯絡ESCSGO客服，以便ESCSGO採取相應措施。"
    }
  };
export default privacy_tw;
  
  