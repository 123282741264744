
import { strLen, subString } from "@/assets/js/filter";
import { mapGetters } from "vuex";
import { Base64 } from "js-base64";
export default {
  name: "PageLogin",
  data() {
    return {
      tabData: [
        { title: "登录", img: "" },
        { title: "注册", img: "" },
      ],
      forget: false,
      active: 1,
      checked: true,
      checkedAge: false,
      checkedRegister: false,
      checkedRegister1: false, //已满18岁
      checkedRegister2: false, //已阅读条款
      titleCode: "发送",
      countdown: 60,
      timer: null,
      form1: {
        mobile: "",
        password: "",
        showPassword:false,
        captcha:""
      },
      visibleInvite: true,
      form2: {
        mobile: "",
        password: "",
        verify_code: "",
        nick_name: "",
        invite_code: "",
        captcha: "",
        trace_param: "",
        showPassword:false,
      },
      titleCode3: "发送",
      countdown3: 60,
      timer3: null,
      form3: {
        phone: "",
        password: "",
        re_password: "",
        verify_code: "",
        captcha: "",
        showPassword:false,
      },
      sumbitStatus: false,

      picPath: "",
      captcha_id: "",
    };
  },
  mounted() {
    this.form2.invite_code = this.getInviteCode ? this.getInviteCode : "";
    if (this.form2.invite_code.length > 0) {
      this.visibleInvite = false;
    }
    document.onkeydown = (e) => {
      var key = window.event.keyCode;
      if (key == 13) {
        if (this.active === 1) {
          this.loginForm1();
        } else if (this.active === 2) {
          this.loginForm2();
        } else {
          this.loginForm3();
        }
      }
    };
    this.loginVerify();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
    this.timer3 && clearInterval(this.timer3);
    this.timer3 = null;
    document.onkeydown = "";
  },
  computed: {
    ...mapGetters(["getInviteCode", "getLoginModel"]),
  },
  created() {
    // 设置选中模块
    console.log(this.getLoginModel, "this.getLoginModel-----------");
    this.active = this.getLoginModel;
  },
  methods: {
    loginVerify() {
      this.$getCaptcha({}).then((res) => {
        if (res.code == 200) {
          this.picPath = res.data.pic_path;
          this.captcha_id = res.data.captcha_id;
        }
      });
    },
    handleAgree() {
      console.log(this.checkedRegister1, "this.checkedRegister1---");
      if (!this.checkedRegister1) {
        this.$store.dispatch("showMessagePopupFn", {
          title: "温馨提示",
          type: 2,
          text: "请确认本人已满18周岁",
        });
        return;
      }
      // if (!this.checkedRegister2) {
      //   this.$store.dispatch("showMessagePopupFn", {
      //     title: "温馨提示",
      //     type: 2,
      //     text: "请阅读并接受用户协议和隐私条款",
      //   });
      //   return;
      // }
      this.checkedRegister = true;
    },
    clearInput() {
      this.form2.nick_name = this.form2.nick_name
        .replace(
          /[`~!@#$%^&*()\-+=<>?:"{}|,./;'\\[\]·~！#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
      if (strLen(this.form2.nick_name) > 24) {
        this.form2.nick_name = subString(this.form2.nick_name, 24, false);
      }
    },
    handleChange(item) {
      if(this.active != item){
        this.active = item;
        this.loginVerify();
      }
    },
    getCode() {
      if (this.timer) {
        return false;
      } else {
        var reg = 11 && /^((10|11|12|13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
        if (this.form2.mobile == "") {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请填写手机号",
          });
        } else if (!reg.test(this.form2.mobile)) {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请输入正确的手机号",
          });
        } else if (!this.form2.captcha) {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请输入图片验证码",
          });
        } else {
          if (!this.timer && typeof this.timer === "object") {
            clearInterval(this.timer);
          }
          this.timer = setInterval(() => {
            this.countdown--;
            if (this.countdown <= 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.countdown = 60;
              this.titleCode = "重新获取";
            }
          }, 1000);
          const data = {
            mobile: this.form2.mobile,
            code_type: 1,
            captcha: this.form2.captcha,
            captcha_id: this.captcha_id,
          };

          console.log("getPhoneCode", data);
          this.$getPhoneCode(data)
            .then((res) => {
              if (res.code == 200) {
                this.$store.dispatch("showMessagePopupFn", {
                  type: 1,
                  text: "发送成功",
                });
              } else {
                this.loginVerify();
                clearInterval(this.timer);
                this.timer = null;
                this.countdown = 60;
                this.titleCode = "重新获取";
              }
            })
            .catch(() => {
              this.loginVerify();
              clearInterval(this.timer);
              this.timer = null;
              this.countdown = 60;
              this.titleCode = "重新获取";
            });
        }
      }
    },
    getCode3() {
      if (this.timer3) {
        return false;
      } else {
        var reg = 11 && /^((10|11|12|13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
        if (this.form3.phone == "") {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请填写手机号",
          });
        } else if (!reg.test(this.form3.phone)) {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请输入正确的手机号",
          });
        } else if (!this.form3.captcha) {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请输入图片验证码",
          });
        } else {
          if (!this.timer3 && typeof this.timer3 === "object") {
            clearInterval(this.timer3);
          }
          this.timer3 = setInterval(() => {
            this.countdown3--;
            if (this.countdown3 <= 0) {
              clearInterval(this.timer3);
              this.timer3 = null;
              this.countdown3 = 60;
              this.titleCode3 = "重新获取";
            }
          }, 1000);
          const data = {
            mobile: this.form3.phone,
            code_type: 3,
            captcha: this.form3.captcha,
            captcha_id: this.captcha_id,
          };
          this.$getPhoneCode(data)
            .then((res) => {
              if (res.code == 200) {
                this.$store.dispatch("showMessagePopupFn", {
                  type: 1,
                  text: "发送成功",
                });
              } else {
                this.loginVerify();
                clearInterval(this.timer3);
                this.timer3 = null;
                this.countdown3 = 60;
                this.titleCode3 = "重新获取";
              }
            })
            .catch(() => {
              this.loginVerify();
              clearInterval(this.timer3);
              this.timer3 = null;
              this.countdown3 = 60;
              this.titleCode3 = "重新获取";
            });
        }
      }
    },
    loginForm1() {
      if (this.checkedAge) {
        if (this.sumbitStatus) {
          return false;
        }

        if (!this.form1.captcha) {
          this.$store.dispatch("showMessagePopupFn", {
            title: "登录提示",
            type: 2,
            text: "请输入图片验证码",
          });
          return false;
        } 

        let _sendData = {
            mobile : this.form1.mobile,
            password : this.form1.password,
            captcha: this.form1.captcha,
            captcha_id: this.captcha_id
        }
        this.$loginPhone(_sendData)
          .then((res) => {
            if (res.code == 200) {
              this.$setCookies("Authorization", res.data.access_token);
              this.$setCookies("loginFlag", true);
              this.$setCookies("accessexpire", res.data.access_expire);
              this.$setCookies("refreshafter", res.data.refresh_after);
              this.$setCookies("Login-Type", 0);
              this.$store.dispatch("getUserInfo");
              this.$store.dispatch("getUserLucky");
              this.$store.dispatch("getPlayerWin");
              this.$store.dispatch("getNewMailCount");
              this.$store.commit("SET_IS_LOGIN", true);
              this.$store.commit("SET_VISIBLE_LOGIN", false);
              this.$removeCookies("u_steam_id");
              // 判断是不是拆箱王页面 如果是  就将页面进行刷新
              if (
                this.$route.name == "vipUnpackingKing" ||
                this.$route.name == "worldCup"
              ) {
                location.reload();
              }
            }
          })
          .catch((err) => {
            this.loginVerify();
            console.log(err);
          });
        this.sumbitStatus = true;
        setTimeout(() => {
          this.sumbitStatus = false;
        }, 3000);
      } else {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "请确认已满18周岁,并同意《用户协议》和《隐私条款》",
        });
      }
    },
    loginForm2() {
      if (this.form2.nick_name == "") {
        this.$store.dispatch("showMessagePopupFn", {
          type: 0,
          text: "请输入昵称",
        });
      } else {
        // debugger
        if (this.checkedRegister1) {
          if (this.sumbitStatus) {
            return false;
          }
          const loading = this.$loading({
            lock: true,
            text: "注册中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.form2.password = this.form2.password;
          this.$loginPhoneReqister(this.form2)
            .then((res) => {
              if (res.code == 200) {
                console.log("注册返回：", res.data);
                this.$setCookies("Authorization", res.data.access_token);
                // this.$setCookies("userInfo", res.data);
                this.$setCookies("loginFlag", true);
                this.$setCookies("accessexpire", res.data.access_expire);
                this.$setCookies("refreshafter", res.data.refresh_after);
                this.$setCookies("Login-Type", 0);
                this.$store.dispatch("getUserInfo");
                this.$store.dispatch("getUserLucky");
                this.$store.dispatch("getPlayerWin");
                this.$store.commit("SET_IS_LOGIN", true);
                this.$store.commit("SET_VISIBLE_LOGIN", false);
                this.$removeCookies("u_steam_id");
                this.$store.commit("SET_VISIBLE_REGISTER", true);
                loading.close();
                // this.$store.dispatch("showMessagePopupFn", {
                //   type: 1,
                //   text: this.$t("logins.tips.t8")
                // });
                // this.$router.push("/");
              } else {
                loading.close();
              }
            })
            .catch((err) => {
              console.log(err);
              loading.close();
            });
          this.sumbitStatus = true;
          setTimeout(() => {
            this.sumbitStatus = false;
          }, 3000);
        } else {
          this.$store.dispatch("showMessagePopupFn", {
            type: 0,
            text: "请确认已满18周岁,并同意《用户协议》和《隐私条款》",
          });
        }
      }
    },
    loginForm3() {
      if (this.sumbitStatus) {
        return false;
      }
      this.form3.re_password = this.form3.password;
      let _sendData = {
        verify_code: this.form3.verify_code,
        password: this.form3.password,
        re_password: this.form3.re_password,
        phone: this.form3.phone,
      };
      this.$changePasssword(_sendData)
        .then((res) => {
          if (res.code == 200) {
            this.$store.dispatch("showMessagePopupFn", {
              type: 1,
              text: this.$t("logins.tips.t10"),
            });
            this.handleChange(1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.sumbitStatus = true;
      setTimeout(() => {
        this.sumbitStatus = false;
      }, 3000);
    },
    steamLogin() {
      this.$LoginBySteam()
        .then((res) => {
          window.open(
            `${res.data.redirect_url}&openid.return_to=${window.location.origin}/&openid.realm=${window.location.origin}`,
            "_self"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClose() {
      this.$store.commit("SET_VISIBLE_LOGIN", false);
    },
  },
};
