import trans from "./help/trans_tw.js";
import problem from "./help/problem_tw.js";
import conditions from "./help/use_tw.js";
import privacy from "./help/privacy_tw.js";
import pc from "./help/PC_tw.js";
import mobile from "./help/Mobile_tw.js";
import novice from "./help/Novice_tw.js";
import steam from "./help/Steam_tw.js";
const tw = {
  vip: {
    title: "VIP等級系統",
    get: "領取",
    haveGet: "已領取",
    notGet: "不可領取"
  },
  header: {
    home: "首頁",
    integral: "直播開箱",
    card: "搶卡煉金",
    battle: "盒子大戰",
    equip: "裝備升級",
    help: "幫助",
    welfare: "ROLL福利",
    login: "註冊/登錄",
    logout: "退出登錄",
    recharge: "充值",
    inventory: "庫存",
    vip: "VIP系統",
    userInfoList: {
      basic: "基本資料",
      inventory: "飾品庫存",
      record: "開箱記錄",
      promote: "推廣用戶"
    }
  },
  home: {
    time: "時間",
    getItems: "獲得獎品 ",
    cost: "價值",
    player: "獲獎用戶",
    list: "最近獲獎名單",
    day: "日",
    week: "周",
    month: "月",
    hour: "時",
    remaining: "距離開獎剩餘時間：",
    txt1: {
      th1: "每",
      th2: "充值滿",
      th3: "固定金額",
      th4: "後，將會自動報名參與"
    },
    txt2: {
      th1: "每",
      th2: "固定時間",
      th3: "會抽取一位幸運玩家，獲得當前抽獎的飾品"
    },
    txt3: {
      th1: "中獎的飾品，會直接發到",
      th2: "我的庫存",
      th3: "裏，請注意查收"
    }
  },
  banner: {
    day: "天",
    hour: "時",
    minute: "分",
    second: "秒",
    buttonText: "限時積分兌換",
    prompt: "充值送積分，免費兌換人氣飾品，數量有限先兌先得",
    registerNum: "已有註冊用戶",
    boxOpenNum: "寶箱開啟總數",
    battleNum: "盒子大戰次數",
    upNum: "裝備升級總數"
  },
  index: {
    jptj: "精品推薦",
    activity_prompt: "距離開獎剩餘時間：",
    consume: "充值",
    join: "參加",
    activity_1: "每日定時抽獎活動",
    activity_2: "每週定時抽獎活動",
    activity_3: "每月定時抽獎活動",
    activity_4: "每時定時抽獎活動",
    tab_1: "熱門人氣彙聚",
    tab_2: "稀有限定裝備",
    tab_3: "戰地近戰幽靈",
    tab_4: "精英衝鋒戰隊",
    tab_5: "中堅傳奇對抗",
    tab_6: "神秘關鍵箱子",
    tab_7: "神秘關鍵箱子",
    open_box: "打開箱子",
    sold: "已售出："
  },
  langue: {
    ch: "中文",
    en: "English"
  },
  gold: "金幣",
  openbtn: "打開",
  hoverright: {
    service: "線上客服",
    totop: "回到頂部"
  },
  logins: {
    login: "登錄",
    register: "註冊",
    updatepwd: "修改密碼",
    emailholder: "請輸入註冊郵箱",
    passwordholder: "請輸入6~12位密碼",
    btn: "確定",
    freeRegister: "免費註冊",
    forgetPwd: "忘記密碼",
    read: "我已閱讀並同意",
    read1: "《用戶協議》",
    read2: "和",
    read3: "《隱私條款》",
    steamlogin: "Steam 登錄",
    res_emai_holder: "請輸入您的郵箱帳號",
    res_code: "獲取驗證碼",
    res_code_holder: "請輸入6位數驗證碼",
    res_code_des: "收不到郵件？可能被轉移至垃圾箱，請前往查看",
    res_pwd_holder: "請設置您的密碼",
    res_pwd_des: "密碼長度為6~12位",
    al_user: "已有帳號，",
    gologin: "去登錄",
    newpwd: "新密碼",
    newpwd_des: "密碼長度為6~12位",
    confirm: "再次確認密碼",
    tips: {
      t1: "請輸入正確的郵箱地址！",
      t2: "密碼長度為6~12位！",
      t3: "請勾選我已閱讀並同意！",
      t4: "登錄成功！",
      t5: "郵件已發送至您的郵箱！",
      t6: "請輸入正確的驗證碼！",
      t8: "您已經成功註冊！",
      t9: "兩次密碼輸入不一致！",
      t10: "您的密碼修改成功,請登錄！"
    }
  },
  energy: {
    lefttitle: "能量寶箱",
    righttitle: "首充福利",
    righttitle1: "新人福利",
    leftbanner: "每次消費都將積累能量",
    ringtext: "我的能量",
    leftstitle: "您的福利：",
    rightbanner: "充值任意金額可免費開啟",
    openbtn: "開啟寶箱",
    openbtn1: "已開啟",
    open_flag: "可開啟",
    open_desc: "當您的能量超過",
    open_desc1: "時，將自動啟動",
    right_open_btn: "免費開箱",
    right_open_btn1: "已開箱",
    tips: {
      t1: "您的能量還未達到開啟這個能量箱子！"
    }
  },
  boxDetail: {
    back: "返回",
    showvoice: "開啟聲音",
    closevoice: "關閉聲音",
    showanimate: "開啟動效",
    closeanimate: "關閉動效",
    tipss: "按空格可跳過動效",
    openonce: "開啟一次",
    openfive: "開啟五次",
    openten: "開啟十次",
    detail_title: "共有",
    detail_titles: "",
    detail_titles1: "",
    detail_title1: "件物品在這個箱子中",
    result_banner1: "如需取回到您的Steam帳號中，請前往 ",
    result_banner2: "我的倉庫",
    result_banner3: "中獎結果",
    result_banner3_1: "開箱中",
    result_banner4: " · 開箱",
    result_banner5: "次",
    result_banner6: "共獲得",
    result_banner7: "件獎品總價值：",
    result_banner7s: "件獎品總價值：",
    result_banner7_1: "獎品總價值計算中..",
    sellall: "全部兌換",
    sellbtn: "兌換",
    doing: "正在打開...",
    await: "等待打開",
    custom_title: " 10000-CSGO開箱良心高爆率的CSGO皮膚飾品交易平臺",
    tips: {
      t1: "您的獎品已成功兌換!",
      t2: "您已沒有獎品兌換!",
      t3: "您的餘額不足本次開箱，請先充值!"
    }
  },
  tips: {
    t1: "請先登錄!"
  },
  warehouse: {
    stor: "我的倉庫",
    log: "開箱記錄",
    back: "取回教程",
    bind: "綁定教程",
    logtype: {
      type1: "嶄新出廠",
      type2: "略有磨損",
      type3: "久經沙場",
      type4: "破損不堪",
      type5: "戰痕累累"
    },
    class: {
      c1: "普通",
      c2: "紀念品"
    },
    status: {
      s1: "正常",
      s2: "領取中",
      s3: "已兌換",
      s4: "已領取",
      s5: "領取失敗"
    },
    tips: {
      t1: "您未填寫交易鏈接",
      t2: "交易鏈接綁定成功",
      t3: "領取失敗",
      t4: "您的飾品已成功兌換",
      t5: "您的飾品兌換失敗"
    }
  },
  stor: {
    placeholder: "請輸入您的交易鏈接",
    placeholder2: "請輸入您的交易鏈接（您還未登錄，請先登錄哦）",
    savebtn: "保存",
    foot1: "前往Steam獲取交易鏈接",
    foot2: "推薦使用網易UU免費加速“Steam社區/商店”",
    stitle: "您共有 ",
    stitle1: " 件飾品",
    sellbtn: "兌換",
    backbtn: "取回",
    loadmore: "加載更多",
    masker1: "取回中，",
    masker2: "請點擊 ",
    masker3: "接受報價",
    masker4: "已兌換",
    masker5: "領取成功",
    masker6: "取回中..",
    desc:
      "需要充值任意金額才能取回哦，未充值用戶的背包庫存保留8小時，充值後保留30天，保存時間截止後自動兌換"
  },
  log: {
    No: "編號",
    boxname: "箱子名稱",
    getitem: "獲得物品",
    degree: "磨損度",
    type: "類型",
    time: "時間",
    status: "狀態"
  },
  opennew: {
    desc: "您的倉庫是空的哦，去開箱獲取炫酷裝備吧",
    toopen: "去開箱"
  },
  footer: {
    footer1: "聯繫我們",
    footer2: "飾品查詢",
    footer3: "常見問題",
    footer4: "用戶協議",
    footer5: "隱私條款",
    official: "官方社交：",
    openbox: "已開箱",
    registeruser: "註冊用戶",
    lineuser: "線上用戶",
    change: "已兌換",
    paytype: "支付方式：",
    ylpay: "銀聯支付",
    wechatpay: "微信支付",
    aplipay: "支付寶支付",
    footer:
      "安全、高效的開箱網站，您可以用優惠的價格獲得您想要的皮膚，如果你有任何疑問都可以聯繫我們，我們將第一時間為您處理"
  },
  helptabs: {
    trans_help: "交易幫助",
    comm_pro: "常見問題",
    conditions: "使用條件",
    privacy_policy: "隱私條款",
    back: "取回教程",
    bind: "綁定教程",
    novice: "新手問題"
  },
  recharge: {
    title: "充值",
    stitle: "如遇充值問題，請更換支付方式重新嘗試，或進入交流群進行回饋",
    selecttitle: "請選擇充值金額",
    desc1:
      "為了保證您能順利地領取到獎品，請您在充值前確認本帳號是否被Steam限制交易，請自行前往Steam社區市場",
    desc2: "查看該帳號的交易安全內容",
    typetitle: "請選擇支付方式",
    type1: "支付寶支付",
    type2: "微信支付",
    paymoney: "實付金額:",
    select_desc: "請選擇充值金額",
    usedesc1: "請使用",
    usedesc2: "掃一掃",
    usedesc3: "完成支付",
    wechat: "微信",
    aplipay: "支付寶",
    usedesc4: "支付環境安全，請放心支付",
    type_desc: "即將開啟",
    tips: {
      t1: "充值成功!"
    }
  },
  userinfo: {
    title: "帳號設置",
    invite_code: "綁定邀請碼",
    update_btn: "修改",
    sq: "收起",
    bind: "綁定",
    jbind: "解綁",
    toSteam: "前往Steam獲取交易鏈接",
    toSteam_desc: "推薦使用網易UU免費加速“Steam社區/商店”",
    savebtn: "保存",
    tip: "保存成功",
    identification: "身份驗證",
    enterRealName: "請填寫您的真實姓名",
    enterIdentification: "請填寫您的身份證號",
    identificationMsg: "請完善資訊",
    changeemail: {
      title: "更換郵箱：",
      old_email_title: "驗證原郵箱號",
      code: "請輸入6位數驗證碼",
      new_email_title: "請綁定新的郵箱",
      new_email: "請輸入您的新郵箱號",
      next: "下一步",
      btn: "確定",
      tips: {
        t1: "郵件已發送至您的郵箱！",
        t2: "請輸入您的新郵箱！",
        t3: "請輸入正確的驗證碼！",
        t4: "郵箱更換成功！"
      }
    },
    username: {
      title: "昵稱：",
      placeholder: "請設置您的昵稱",
      desc: "請設置昵稱",
      tips: {
        t1: "請輸入您的昵稱！"
      }
    },
    password: {
      title: "登錄密碼：",
      desc: "請設置密碼",
      old_placeholder: "當前密碼",
      new_placeholder: "設置您的新密碼",
      conf_placeholder: "確認您的新密碼",
      tips: {
        t1: "請輸入當前密碼！",
        t2: "密碼長度為6~12位！",
        t3: "兩次密碼輸入不一致！"
      }
    },
    steam: {
      title: "綁定Steam：",
      desc: "請綁定您的Steam帳號",
      dialog: {
        title: "系統提示",
        content: "確定要解除綁定Steam嗎？",
        no: "手滑了",
        yes: "確認解綁"
      },
      tips: {
        t1: "綁定成功！",
        t2: "Steam解綁成功！"
      }
    },
    link: {
      title: "交易鏈接：",
      desc: "請設置交易鏈接",
      placeholder: "請輸入您的交易鏈接",
      tips: {
        t1: "請輸入您的交易鏈接！"
      }
    }
  },
  common: {
    vfx: "特效",
    balance: "餘額：",
    sell: "兌換",
    warehouse: "放入倉庫",
    sellAll: "全部兌換",
    explain: "關於玩法介紹",
    login: "您未登錄！立即登錄吧",
    confirm: "確認"
  },
  points: {},
  unpack: {
    record: "最近開箱記錄",
    title: "包含的飾品",
    close: "關",
    open: "開",
    animate: "動畫開關",
    txt1: "您可使用熱鍵",
    txt2: "進行快速開箱",
    chooseNumber: "選擇數量",
    amount: "應付金額",
    handleSubmit: "確定開箱",
    handleOpening: "開箱中...",
    rewardDetails: "本次箱子詳情",
    time: "時間",
    player: "玩家",
    getItems: "獲得飾品",
    cost: "價值"
  },
  upgrade: {
    title: "裝備升級",
    explain: "關於玩法介紹",
    historyBtn: "升級歷史",
    upgradeAct: "選擇用於升級的飾品",
    probability: "升級概率",
    wantAct: "選擇想要得到的飾品",
    useBalance: "使用餘額",
    myBalance: "我的餘額",
    handleUpgrade: "點擊升級",
    nowUpgrade: "正在升級",
    handleTxt: "* 升級最高概率為",
    myInventory: "我的庫存",
    upgradeItem: "升級物品",
    nameItem: "物品名稱...",
    all: "全部",
    category: "類別",
    price: "價格",
    priceRange: "價格區間",
    none: "您沒有任何庫存物品",
    handleGo: "去開箱",
    rewardDetails: "本次升級獲得的飾品",
    handleReturn: "返回升級",
    handleSuccess: "升級成功",
    handleFaild: "升級失敗",
    handleSuccessResult: "升級成功",
    handleFaildResult: "升級失敗"
  },
  battle: {
    title: "盒子大戰",
    explain: "關於玩法介紹",
    model1: "盒子大戰",
    model2: "官方對戰",
    model3: "已結束",
    createBtn: "創建比賽",
    recordBtn: "我的記錄",
    rounds: "回合",
    result: "戰況",
    totalWin: "總贏",
    content: "對戰內容",
    type: "遊戲類型",
    cost: "總價值",
    players: "玩家",
    handle: "操作",
    totalCost: "飾品總價值：",
    handleAnimate: "動畫特效",
    handleWatch: "觀看",
    handleReturn: "返回列表",
    handleJoin: "加入",
    readyStatus: "準備就緒",
    handleWait: "等待玩家",
    handleReady: "準備就緒",
    running: "進行中",
    battle_end: {
      title: "本局盒子大戰結果",
      endTxt: "對戰結束",
      win: "贏得",
      box: "箱子詳情",
      return: "返回列表",
      add: "創建比賽"
    },
    endResultTxt: "共贏得",
    endWin: "勝利!",
    endLose: "請再接再厲",
    battle_create: {
      createNew: "創建對戰",
      handleReturn: "返回",
      chooseTitle: "選擇箱子",
      totalTitle: "應付總價：",
      t: "恐怖分子",
      ct: "反恐精英",
      handleClear: "清空",
      userTotal: "玩家數量",
      chooseTeam: "選擇隊伍",
      handleCreate: "支付創建",
      chosen: "選好了",
      handleAdd: "添加箱子",
      errorBoxs: "請勿超過箱子上限",
      errorBoxsZero: "請添加箱子",
      errorUserZero: "請選擇玩家人數",

      
    },
      state_1:"等待中",
      state_2:"對戰中",
      state_3:"已结束",
      new_lookdetal:"已结束",
      new_share:'點擊邀請好友參戰',
    battle_history: {
      return: "返回列表",
      record: "我的記錄"
    }
  },
  roll: {
    title: "ROLL福利",
    explain: "關於玩法介紹",
    model1: "全部房間",
    model2: "我參與的",
    model3: "已結束",
    createBtn: "創建房間",
    roll_count_time: {
      d: "天",
      h: "時",
      m: "分",
      s: "秒"
    },
    details: "ROLL詳情"
  },
rank: {
    title: "歐皇榜",
    model3: "對戰榜",
    model1: "開箱榜",
    model2: "拉貨榜",
    holdRankTime: "占領時間",
    curDataReward: "本期獎勵",
    recordRank:"歐皇記錄榜",
    noBody:"虚位以待"
  },
  card: {
    title: "搶卡煉金",
    model1: "進行中",
    model2: "已結束",
    recordBtn: "我的搶卡",
    return: "返回列表"
  },
  error: {
    d1: "請檢查網頁是否錯誤，或嘗試刷新以查看是否可以成功",
    d2: "哎呀，介面不見了",
    d3: "返回首頁"
  },
  trans,
  problem,
  conditions,
  privacy,
  pc,
  mobile,
  novice,
  steam
};
export default tw;

