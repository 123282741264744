const use_cn = {
    title: 'ESCSGO用户协议',
    content: {
        title1: '有关使用本服务的条件',
        span1_1: '1. 未经父母或监护人同意使用本服务，未满十八（18）岁的人不得使用本服务',
        span1_2: '2. 您需要支持的Web浏览器才能访问该服务。您承认并同意服务的性能取决于您的计算机设备和Internet连接的性能',
        span1_3: '3. 您同意通过Valve Corporation提供的Steam帐户注册并注册服务。您有责任管理您的帐户并保密您的密码。您还有责任限制对帐户的访问权限。您同意不允许任何第三方使用或访问本服务。您同意自己对您帐户中发生的所有活动或您自己使用密码负责。如果您认为第三方已访问您的密码，请尽快使用服务的密码重新生成功能来获取新密码',
        title2: '4. 封禁',
        span2: '我们可以出于任何原因立即终止或暂停访问我们的服务，恕不另行通知或承担任何责任，包括但不限于您违反本条款。终止后，您使用本服务的权利将立即停止。 如果您希望终止帐户，可以直接停止使用本服务。本条款的所有条款本质上应在终止后继续有效，包括但不限于所有权条款，保证免责声明，赔偿和责任限制',
        title3: '5. 与其他网站的链接',
        span3: '我们的服务可能包含非www.escsgo.com拥有或控制的第三方网站或服务的链接。 www.escsgo.com无法控制，也不对任何第三方网站或服务的内容，隐私条款或做法承担任何责任。 您进一步确认并同意www.escsgo.com不会直接或间接地对因使用或依赖任何此类内容，商品或服务而导致或声称造成的任何损害或损失负责或承担责任。 或通过任何此类网站或服务。 我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私条款',
        title4: '6. 不保证',
        span4: '本网站“按原样”提供，不作任何明示或暗示的陈述或保证。 www.escsgo.com不对本网站或本网站提供的信息和材料作出任何陈述或保证。 在不影响前一段的一般性的情况下，www.escsgo.com不保证：本网站将始终可用或根本不可用; 或者本网站上的信息是完整，真实，准确或无误导性的。 本网站上的任何内容均不构成或意图构成任何形式的建议。 如果您需要有关任何法律，财务或医疗事宜的建议，请咨询相应的专业人士',
        title5: '7. 关系',
        span5: '我们与Valve公司，Counter Strike：Global Offensive，Dota2，Steam或Valve公司的任何其他商标无任何关联或认可',
        title6: '8. ESCSGO的账户额度',
        span6: '通过向您的ESCSGO账户添加金额，您接受所收到的代币将由虚拟币代替，没有实际价值，也不受任何退款',
        title7: '9. 游戏道具的取回',
        span7: '从使用开箱服务并获得开箱结果后，用户可将游戏道具接收至Steam账户中，或通过用户中心放弃道具。 在用户履行网站中所要求的条件后，对用户中心的Steam账户完成设置后，可在Steam账户中接受游戏道具。 用户有权放弃已获得但Steam账户尚未接受的游戏道具，放弃道具可获得的虚拟币奖励标注在用户中心的道具描述中，在使用开箱服务时可使用这些虚拟币支付。双方同意，虚拟币不是现实中的货币，不得退还或放弃',
        title8: '10. 责任限制',
        span8: '您被告知并同意，在出现与使用服务、无法使用服务、在特定管辖权内的作为有关的任何损失的情况下，在现行法律许可的范围内，最大限度地使用本协议的责任限制。在任何情况下，ESCSGO、其分公司、部门、任何员工、经理、负责人或代理人不对您或任意第三方的，包括间接损失、实际损失、偶然性损失、罚金损失或专门性损失在内的损失承担责任，哪怕任何的自然人或法人已被提醒出现这些损失的可能性或这些损失已被预测到。 在任何情况下，ESCSGO或上述中的任意自然人或法人所承担的与提供服务有关的责任的总金额不超过在您使用服务的最近6个月内所缴纳的金额。 由于在一些国家和管辖权中不允许取消或限制对间接性、偶然性损失的责任，在这些国家或管辖权中， ESCSGO的责任应在法律许可的范围内得到最大程度的限制',
        title11: '11. 其他条款和条件&最终用户许可协议',
        span11: '当您使用支付宝提供的支付宝服务在我们的网站上进行购买时，您购买的责任将首先转移到支付宝支付服务提供商，然后才能交付给你。 支付宝服务提供商承担主要责任，我们的协助，支付和支付相关的客户支持。 支付宝服务提供商与使用支付宝服务的客户之间的条款受单独协议的约束，不受本网站条款的约束',
        title12: '对于通过支付宝服务提供商结账进行购买的客户:',
        span12_1: '（1）支付宝服务提供商的隐私条款应适用于所有付款，并应在进行任何购买之前进行审核，以及',
        span12_2: '（2）支付宝服务提供商退款政策应适用于所有付款，除非相关供应商事先明确向买方发出通知。此外，购买某些产品还可能要求购物者同意一个或多个最终用户许可协议，其中可能包括产品供应商而非我们或支付宝服务提供商设定的附加条款',
        span12_3: '我们和/或通过使用支付宝服务在我们网站上销售产品的实体主要负责这些产品的保修，维护，技术或产品支持服务。我们和/或在我们网站上销售产品的实体主要负责向用户承担与履行订单相关的任何责任，以及最终用户客户签订的协议。支付宝服务提供商主要负责促进您的付款',
        span12_4: '您需要承担与您与支付服务提供商的关系所产生的费用或您当地海关官员或其他监管机构征收的关税和税款所产生的与购买和交付物品相关的任何费用，税金或其他费用'
    }
}
export default use_cn