// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/images/newCommon/page_change_icon.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".compoent-pagination[data-v-8d949dd8]{bottom:20px;display:flex;position:absolute;left:50%;transform:translateX(-50%)}.compoent-pagination .page_box[data-v-8d949dd8]{height:30px;border-radius:4px;display:flex;align-items:center;justify-content:center}.compoent-pagination .page_box .page_check[data-v-8d949dd8]{margin:0 10px;width:44px;height:30px;text-align:center;line-height:30px;color:#8884a7;background:#3c3954;border-radius:4px;cursor:pointer;opacity:.5;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.compoent-pagination .page_box .page_check.active[data-v-8d949dd8]{opacity:1}.compoent-pagination .page_box .page_check.active[data-v-8d949dd8]:hover{filter:brightness(120%)}.compoent-pagination .page_box .page_left[data-v-8d949dd8]{transform:rotateY(180deg)}.compoent-pagination .page_box .page__txt[data-v-8d949dd8]{width:100px;background:#4d3f70;font-size:10px;color:#bfb1e2;height:30px;line-height:30px;border-radius:4px;text-align:center}.compoent-pagination .page_box .page__txt span[data-v-8d949dd8]:first-child{color:#bfb1e2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
