
import historyTab from "@/components/goodCommon/historyTab.vue";
import headerImg from "@/components/goodCommon/headerImg.vue";
import { mapGetters } from "vuex";
import { deepClone, fromatCDNPath, timestampToTime } from "../../assets/js/filter";
export default {
  components: {
    headerImg,
    historyTab,
  },
  data() {
    return {
      timeoutRewardHistory: null,
      boxlist: [], // 代表头部列表
      tabIndex: 0, //0代表实时2代表欧皇
      realIndex: 2,
    };
  },
  computed: {
    ...mapGetters(["getNormalRewardHistorys", "getBigRewardHistorys"]),
    ...mapGetters("battle", ["getRooms"]),
  },
  mounted() {
    this.getList();

    this.aniBoxList = []; // 暂存需要动画数组 不需要响应式

    this.startQueue();
    window.addEventListener("reward_history", this.handleHis);
  },
  beforeDestroy() {
    window.addEventListener("reward_history", this.handleHis);
    clearInterval(this.intervalId);
  },
  methods: {
    startQueue() {
      let delay = (items = [], delayAmount = 0) => {
        if (items.length > 0) {
          this.boxlist.unshift(items[0]);
          this.boxlist.splice(20);
          setTimeout(() => delay(items.slice(1), delayAmount + 50), delayAmount);
        }
      };

      this.intervalId = setInterval(() => {
        if (this.aniBoxList.length > 0) {
          const unshiftItems = this.aniBoxList.splice(0, 5); // 修改这里每次只取一个元素
          delay.call(this, unshiftItems, 50); // 这里初始delayAmount为你给出的150毫秒
        }
      }, 700);
    },
    // 处理监听 1是实时 2是大奖
    handleHis(data) {
      if (this.aniBoxList.length > 15) {
        return;
      }
      data.detail.uuid = this.generateUUID();

      if (this.tabIndex == 0) {
        // 普通
        this.aniBoxList.unshift(data.detail);
      } else {
        if (data.detail.reward_history_type == 2) {
          // 大奖
          this.aniBoxList.unshift(data.detail);
        }
      }
    },

    // 处理点击 game_type  2 开箱 game_type 3 对战 game_type 4 升级 6 开箱王 1014 钻石宝箱
    handleClick(item) {
      if (item.game_type == 2) {
        this.$router.push({
          path: "/navUnpack",
          query: { id: item.target_id },
        });
      } else if (item.game_type == 3) {
        this.$router.push({
          path: "/navBattle",
        });
      } else if (item.game_type == 4) {
        this.$router.push({
          path: "/navUpgrade",
        });
      } else if (item.activity_type == 1014) {
        this.$router.push({
          path: "/activity/diamondBox",
        });
      }
    },
    generateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    formatCdnPath(_value) {
      return fromatCDNPath(_value);
    },
    handleTab(val) {
      let realI = 0;
      if (val == 1) {
        realI = 2;
      } else {
        realI = 0;
      }
      this.realIndex = val;
      this.tabIndex = realI;
      this.aniBoxList = [];
      this.boxlist = [];

      this.getList();
    },
    getList() {
      let _sendData = {
        reward_type: this.tabIndex,
      };
      this.$getmaxPush(_sendData)
        .then((res) => {
          $nuxt.$store.commit("CLEAR_NORMAL_REWARD_HISTORY", []);
          let list = res.data.infos || [];
          list.map((item) => {
            item.uuid = this.generateUUID();
          });
          list.splice(20);

          this.$nextTick(() => {
            this.boxlist = list;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
