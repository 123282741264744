const Novice_tw = {
    title: '新手常見問題',
    content: {
        title1: '1. 開箱活動是官方的活動嗎？為什麼我的獎品沒法在遊戲倉庫中看到？',
        span1: '本活動是非官方活動，所有概率及飾品均真實有效，飾品需要通過取回才能在自己的遊戲倉庫中查看',
        title2: '2. 獲取交易鏈接的網站為什麼打不開？',
        span2: '從國內訪問Steam網站需要通過加速Steam社區之後才能正常顯示，建議開啟VPN或者使用加速器加速Steam社區可正常訪問Steam（這裏推薦使用UU加速器登錄可選擇加速“steam社區/商店”即可正常訪問）',
        title3: '3. 如果我不取回，皮膚會保留多久？',
        span3: '您在網站倉庫內的飾品會保存15天',
        title4: '4. 取回獎品時提示取回失敗，Steam也沒收到報價資訊是什麼問題？',
        span4: '取回失敗多數是因為該遊戲帳號存在一些交易限制，如下所示：',
        span4_1: '4.1 未綁定手機令牌，請注意，這裏所說的令牌是Steam移動版，而並非註冊帳戶時所啟用的郵箱令牌，未綁定的用戶請參照綁定教程中所提供的Steam令牌綁定教程，按照提示綁定。首次綁定手機令牌會有15天的交易限制時間，耐心等待即可',
        span4_2: '4.2 移除或重置過Steam手機令牌，也會產生7天交易限制時間。更換手機重新安裝手機令牌或者卸載重裝均會觸發此限制，重置令牌時請謹慎',
        span4_3: '4.3 帳戶近期更改過Steam登錄密碼，同樣也會產生7天的交易限制，參與活動時請謹慎使用',
        span4_4: '4.4 如下圖所示的交易限制該如何解除',
        span4_4_1: '如上圖所示可判斷此用戶為Steam新註冊的帳戶，新註冊的Steam帳戶在完成綁定Steam令牌的同時還需要在Steam商店中消費超過5美元才可解除交易限制，該規定為Steam強制硬性規定，新註冊用戶均需要完成此操作，消費超過5美元之後在打開社區市場就可以看到詳細的解除交易限制的時間了',
        span4_4_2: '請注意，若帳戶一年之內未在Steam產生任何一筆消費同樣也會觸發交易限制，若打開交易市場出現以下提示',
        span4_4_3: '此時，只需要前往Steam商店購買任意一款遊戲，任何金額的都可以，就可觸發解除並查看詳細的解除交易的時間了',
        span4_4_4: '以上所有的交易限制的解除時間是可以疊加的，等待帳戶的交易限制解除之後就可暢遊無阻的進行Steam的遊戲交易了！若在解除限制的過程中遇到任何問題請聯繫超級客服協助您進行查詢處理',
        title5: '5. 回應報價時，不小心點了取消/拒絕怎麼辦？',
        span5: '當您回應交易報價時，若是誤點取消或者拒絕報價，請等待五分鐘後再活動倉庫重新發起取回，請注意不要頻繁的點拒絕或者取消報價，頻繁拒絕和取消報價有可能會觸發Steam暫掛機制，由此帶來的損失，我方概不負責，希望各位玩家理解',
        title6: '6. 交易報價顯示“交易暫掛”怎麼辦？',
        span6: '若遇到交易報價顯示“交易暫掛”，請勿取消交易，此操作可能會導致您的帳號和機器人帳號同時進入交易暫掛限制，“交易暫掛”是Steam官方保護您的Steam帳戶的機制，耐心等待暫掛期結束即可，感謝您的理解',
        title7: '7. 我的帳號被VAC了，可以參加活動嗎？',
        span7: '請注意，若Steam帳戶被VAC，是無法進行正常交易提取的，請在“我的庫存”中綁定一條可正常交易的帳號的交易鏈接進行獎品提取',
        title8: '8. 活動餘額可以用Steam餘額充值嗎？餘額可以進行提現嗎？',
        span8_1: '本活動為非官方活動，無法使用您的Steam帳戶餘額，餘額也不可進行提現，請儘量使用完畢，同時未使用的餘額會一直存放於活動帳戶，可隨時登陸 ',
        span8_2: ' 進行查詢，隨時歡迎回來',
        title9: '9. 活動可以退款嗎？',
        span9: '請注意，網路虛擬物品有其特殊性，一經購買是無法進行退款的，同時也提醒各位玩家，本活動是非官方的活動，所有獎勵真實有效，物品資訊上都有概率展示的。開箱是有幾率性的，請理性消費，切勿過度遊戲',
        title10: '10. 特別聲明：本活動飾品會取回到您帳戶關聯的Steam帳戶，請保證您所填寫的交易鏈接是您自己的帳戶，若出現交易鏈接填寫錯誤導致的獎品丟失或發送對象錯誤，我方概不負責，同時，也請珍惜記得帳號不要借與他人或在不良環境下登陸，請勿點擊陌生人發送的陌生鏈接，由此造成的帳號封禁、VAC等無法使用Steam的情況，我方也不予負責，望知悉'
    }
}
export default Novice_tw
    