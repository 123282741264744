const Mobile_cn = {
    title: '移动端饰品取回教程',
    content: {
        title1: '操作步骤：',
        span1_1: '1. 在游戏活动中心的',
        span1_2: '“我的背包”点击饰品取回',
        span2_1: '2.',
        span2_2: ' 打开手机Steam',
        span2_3: '（移动版Steam需要加速方可使用，推荐使用UU加速器移动版，点击加速即可。注：UU加速器移动版加速Steam也是免费的）',
        span3_1: '3. 打开SteamAPP，点击左上角“',
        span3_2: '三条杠',
        span3_3: '”的符号，打开侧边菜单',
        span4_1: '4. 点击左侧”通知“按钮，可以看到有蓝色消息图标的新通知，点击“',
        span4_2: '新交易报价',
        span4_3: '”按钮',
        span5_1: '5. 点开新交易报价，可以看到交易详情，在打开的页面点击“',
        span5_2: '回应报价',
        span5_3: '”即可开始进行取回',
        span6_1: '6. 在新交易界面点击“',
        span6_2: '准备好交易',
        span6_3: '”并检查对方提供的物品是否一致，检查无误后在下方点击“',
        span6_4: '接受交易',
        span6_5: '”即可',
        span7: '7. 完成以上步骤即可成功取回饰品，你学会了吗？'
    }
}
export default Mobile_cn