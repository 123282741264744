
import Service from "@/components/userTool/service.vue";
import Coupon from "@/components/userTool/coupon.vue";
import MoneyRecord from "@/components/userTool/moneyRecord.vue";
import BoxRecord from "@/components/userTool/boxRecord.vue";
import LevelReward from "@/components/userTool/levelReward.vue";
import MyBag from "@/components/userTool/bag.vue";
import Dialog from "@/components/common/Dialog.vue";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";

import messageBar from "@/components/userTool/messageBar.vue";
import { mapGetters } from "vuex";
import { fromatCDNPath } from "@/assets/js/filter";
export default {
  components: {
    Dialog,
    LevelReward,
    MyBag,
    BoxRecord,
    MoneyRecord,
    Coupon,
    Service,
    ConfirmDialog,
    messageBar,
  },
  data() {
    return {
      fromatCDNPath,
      CdkInfo: null,
      key: "", // cdk密码
      openCDk: false, //cdk
      clamSuccess: false, // cdk领取成功弹窗
      openLevelReward: false, // 等级奖励
      openBag: false, // 背包
      openBoxRecord: false, // 开箱记录
      openMoneyRecord: false, // 账变记录
      openCoupon: false, // 优惠券
      openService: false, // 用户记录
      openMessage: false,
      type: 1,
      old_people: 0,
      people_num: 0,
      // type 为弹窗 path 为跳转路径
      tool_list: [
        {
          path: "/mine/inventory",
          img: require("../../static/images/goodCommon/tool1.png"),
          imgCheck: require("../../static/images/goodCommon/tool1_active.png"),
          title: "个人信息",
        },
        {
          type: "unpack",
          // path: "/mine/unpack",
          img: require("../../static/images/goodCommon/tool3.png"),
          imgCheck: require("../../static/images/goodCommon/tool3_active.png"),
          title: "游戏记录",
        },
        {
          type: "deal",
          // path: "/mine/deal",
          img: require("../../static/images/goodCommon/tool4.png"),
          imgCheck: require("../../static/images/goodCommon/tool4_active.png"),
          title: "账变记录",
        },
        {
          path: "/navPromotion",
          img: require("../../static/images/goodCommon/tool5.png"),
          imgCheck: require("../../static/images/goodCommon/tool5_active.png"),
          title: "用户推广",
        },
        {
          type: "coupon",
          // path: "/mine/coupon",
          img: require("../../static/images/goodCommon/tool6.png"),
          imgCheck: require("../../static/images/goodCommon/tool6_active.png"),
          title: "优惠卷",
        },
      ],
      showUserTool: false,
      statistics_data: [
        {
          start: 0,
          end: 0,
        },
        { start: 0, end: 0 },
        { start: 0, end: 0 },
        { start: 0, end: 0 },
      ],
      qqinfo: {
        qq: "",
        qq_img: "",
      },
      isCreateAppQRCode: false,
      timer: null,
    };
  },
  computed: {
    ...mapGetters([
      "getIsLogin",
      "getUserinfo",
      "getMyMoney",
      "getIsIdCard",
      "getNewMailCount",
    ]),
  },
  mounted() {
    this.getData();
    // this.updatePeople();
  },
  beforeDestroy() {
    
  },
  methods: {
    checkCdk() {
      this.$claimCdk({ cdk: this.key }).then((res) => {
        this.$store.dispatch("showMessagePopupFn", {
          type: 1,
          text: "兑换成功",
        });
        this.openCDk = false;
        this.CdkInfo = res.data;
        this.clamSuccess = true;
      });
    },
    openServices(type) {
      this.type = type;
      this.openService = true;
    },
    chatOnline() {
      window.open("https://ees-0008.talkvv.com", '_blank');
    },
    updatePeople() {
      this.people_num = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      setInterval(() => {
        this.old_people = this.people_num;
        const randomValue = Math.floor(Math.random() * (200 + 1)) - 100;
        if (this.people_num < 500) {
          this.people_num = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
        } else {
          this.people_num += randomValue;
        }
      }, 10000);
    },
    jump(item) {
      if (item.path) {
        this.$router.push({
          path: item.path,
        });
      } else {
        if (item.type == "unpack") {
          this.openBoxRecord = true;
        } else if (item.type == "deal") {
          this.openMoneyRecord = true;
        } else if (item.type == "coupon") {
          this.openCoupon = true;
        }
      }
    },
    getData() {
      this.$userNum().then((res) => {
        if (res.code == 200) {
          this.statistics_data[0].start = Number(this.statistics_data[0].end);
          this.statistics_data[0].end = Number(res.data.user_num);
          this.statistics_data[1].start = Number(this.statistics_data[1].end);
          this.statistics_data[1].end = Number(res.data.open_box_num);
          this.statistics_data[2].start = Number(this.statistics_data[2].end);
          this.statistics_data[2].end = Number(res.data.pk_num);
          this.statistics_data[3].start = Number(this.statistics_data[3].end);
          this.statistics_data[3].end = Number(res.data.upgrade_num);
        }
      });
    },

    handleVoice() {
      this.$refs.btnVoice.open();
    },
    // 退出登录
    handleExit() {
      this.$store.dispatch("signOut", true);
      this.showUserTool = false;
    },
    openRecharge() {
      this.$refs.btnVoice.open();
      if (!this.getIsIdCard) {
        this.$store.commit("SET_VISIBLE_ID_CARD", true);
      } else {
        this.$store.commit("SET_IS_SHOW_RECHARGE_DIALOG", true);
        // this.$router.push({
        //   path: "/goodRecharge",
        // });
      }
    },
    // 打开等级奖励
    onClickLevelReward() {
      this.$refs.btnVoice.open();
      this.openLevelReward = true;
    },
    // 打开我的背包
    onClickBag() {
      this.$refs.btnVoice.open();
      this.openBag = true;
    },
    onClickMails() {
      this.openMessage = true;
      this.$refs.message.show();
    },

    formatCdnPath(_value) {
      return fromatCDNPath(_value);
    },

    handleLogin(val) {
      // this.$refs.btnVoice.open();
      this.$store.commit("SET_VISIBLE_LOGIN", true);
      this.$store.commit("SET_LOGIN_MODEL", val);
    },
  },
};
