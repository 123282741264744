const Mobile_tw = {
    title: '移動端飾品取回教程',
    content: {
        title1: '操作步驟：',
        span1_1: '1. 在遊戲活動中心的',
        span1_2: '“我的倉庫”點擊飾品取回',
        span2_1: '2.',
        span2_2: ' 打開手機Steam',
        span2_3: '（移動版Steam需要加速方可使用，推薦使用UU加速器移動版，點擊加速即可。注：UU加速器移動版加速Steam也是免費的）',
        span3_1: '3. 打開SteamAPP，點擊左上角“',
        span3_2: '三條杠',
        span3_3: '”的符號，打開側邊菜單',
        span4_1: '4. 點擊左側”通知“按鈕，可以看到有綠色信封式樣的新通知，點擊“',
        span4_2: '新交易報價',
        span4_3: '”按鈕',
        span5_1: '5. 點開新交易報價，可以看到交易詳情，在打開的頁面點擊“',
        span5_2: '回應報價',
        span5_3: '”即可開始進行取回',
        span6_1: '6. 在新交易介面點擊“',
        span6_2: '準備好交易',
        span6_3: '”並檢查對方提供的物品是否一致，檢查無誤後在下方點擊“',
        span6_4: '接受交易',
        span6_5: '”即可',
        span7: '7. 完成以上步驟即可成功取回飾品，你學會了嗎？'
    }
}
export default Mobile_tw